import React from 'react';

import '../../styling.scss';

export interface SelectProps {
	options: {
		label: string;
		id: any;
	}[];
	id?: string;
	inline?: boolean;
	selected?: any;
	onChange: (value: any) => void;
	heading?: string;
}

const Select = (props: SelectProps) => {
	const { options, inline = false, selected, onChange, heading, id } = props;

	const optionContents = options.map((elem: any, i: number) => {
		return (
			<option key={i} value={elem.id}>
				{elem.label}
			</option>
		);
	});

	optionContents.unshift(
		<option key={-1} disabled hidden value="">
			Please select...
		</option>
	);

	const handleChange = (e: any) => {
		onChange(e);
	};

	return (
		<div className={`edfmat_select-holder ${inline ? 'edfmat_select-holder--inline' : ''}`}>
			{heading && <span className="edfmat_select-label">{heading}&nbsp;</span>}
			<span className="edfmat_select">
				<label>
					{/*label - Channel */}
					<select
						className="u-full-width"
						id={id ? id : 'exampleRecipientInput'}
						value={selected}
						onChange={(e) => handleChange(e.target.value)}
					>
						{optionContents}
					</select>
				</label>
			</span>
		</div>
	);
};

export default Select;
