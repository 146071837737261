import React, { useState, useEffect } from 'react';
import { DataTableAsync } from '../index';

import '../styling.scss';

export interface TableProps {
	headings?: Array<{
		sortable?: false;
		reversable?: false;
		current?: false;
		label: string;
	}>;
	body: any[];
	clickable?: boolean;
	sticky?: boolean;
	compressed?: boolean;
	slim?: boolean;
	search?: any;
	loading?: boolean;
	searchDelay?: number;
	pagination?: boolean;
}

const DataTable = (props: TableProps) => {
	const {
		headings,
		body = [],
		search,
		searchDelay,
		clickable,
		slim,
		loading = false,
		pagination: usePagination = false,
		sticky = false,
		compressed = false,
	} = props;

	const [filteredBody, setFilteredBody]: [[], any] = useState([]);
	const [currentBody, setCurrentBody]: [[], any] = useState([]);
	const [displayBody, setDisplayBody]: [[], any] = useState([]);

	const [searchFunc, setSearchFunc]: any = useState();

	const [currentSort, setCurrentSort]: [number | undefined, any] = useState();
	const [triggerSort, setTriggerSort]: [number | undefined, any] = useState();

	const [displayHeadings, setDisplayHeadings]: [[], any] = useState([]);

	const nextPage = () => {
		setPagination((oldData) => {
			const newData = {
				...oldData,
				current: Math.min(Math.floor(oldData.total / oldData.pageSize), oldData.current + 1),
			};

			return newData;
		});
	};

	const prevPage = () => {
		setPagination((oldData) => {
			const newData = {
				...oldData,
				current: Math.max(0, oldData.current - 1),
			};

			return newData;
		});
	};

	const pageSkip = (page: number) => {
		setPagination((oldData) => {
			const newData = {
				...oldData,
				current: page,
			};

			return newData;
		});
	};

	const update = (pageSize: number) => {
		setPagination((oldData) => {
			const newData = {
				...oldData,
				pageSize: pageSize,
				current: 0,
			};

			return newData;
		});
	};

	// as a function passed into a click doesn't reference the current states when fired, trigger function from useEffect after variable change - WIP
	const sortTrigger = (selected: number) => {
		setTriggerSort(selected);
	};

	const sort = (selected?: number, reverse?: boolean) => {
		let returnBody = [...filteredBody];

		if (selected === undefined) {
			setDisplayBody(returnBody);
			return;
		}

		//sort setFilteredBody by selected column - sortVal if present, the raw (hopefully text) value if not
		//save current sort selected var so that sort is retained when searching etc
		if (currentSort === selected && reverse) {
			//and reversable - TODO (note reverse is whether sort triggered by pagination etc and not by manual click - is different to reversable which isn't implemented)
			returnBody = [...displayBody].reverse();
			setCurrentSort(selected);
			setDisplayBody(returnBody);
			return;
		}

		const emptyValues = [undefined, null];
		returnBody.sort((a: any, b: any) => {
			const aSelected = a.columns[selected];
			const bSelected = b.columns[selected];
			if (emptyValues.includes(aSelected) || emptyValues.includes(bSelected)) {
				return 0;
			}

			return getSortDirection(aSelected, bSelected);
		});

		setCurrentSort(selected);
		setDisplayBody(returnBody);
	};

	const getSortDirection = (aSelected: any, bSelected: any) => {
		//either has a raw element or an object {sortVal: num | string | boolean, display: any}
		if (aSelected.sortVal !== undefined && bSelected.sortVal !== undefined) {
			if (aSelected.sortVal < bSelected.sortVal) {
				return -1;
			}
			if (aSelected.sortVal > bSelected.sortVal) {
				return 1;
			}
		} else {
			if (aSelected < bSelected) {
				return -1;
			}
			if (aSelected > bSelected) {
				return 1;
			}
		}

		return 0;
	};

	const [pagination, setPagination] = useState({
		current: 0,
		total: 0,
		pageSize: 10,
		nextPage: nextPage,
		prevPage: prevPage,
		pageSkip: pageSkip,
		update: update,
	});

	useEffect(() => {
		if (headings) {
			setDisplayHeadings(() => {
				const tempHeadings = [...headings];

				if (currentSort !== undefined) {
					const newTemp = tempHeadings.map((item: any, i: number) => {
						const retItem = { ...item, current: currentSort === i ? true : false };

						return retItem;
					});

					return newTemp;
				}

				return tempHeadings;
			});
		}
	}, [headings, currentSort]);

	useEffect(() => {
		if (body) {
			setFilteredBody(body);

			if (search) {
				setSearchFunc(() => {
					return (searchTerm: string) => {
						setFilteredBody(() => {
							const newData = body.filter((item: any) => {
								if (item.searchableText) {
									if (item.searchableText.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
										return true;
									}
								} else {
									for (let i = 0; i < item.columns.length; i++) {
										if (
											item.searchableColumns === undefined ||
											(item.searchableColumns && item.searchableColumns.indexOf(i) > -1)
										) {
											if (item.columns[i] && item.columns[i].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
												return true;
											}
										}
									}
								}
								return false;
							});

							return newData;
						});
					};
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [body]);

	useEffect(() => {
		sort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredBody]);

	useEffect(() => {
		// eslint-disable-next-line eqeqeq
		if (triggerSort != undefined) {
			sort(triggerSort, true);
			setTriggerSort(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [triggerSort]);

	useEffect(() => {
		setPagination((oldData) => {
			const newData = {
				...oldData,
				total: displayBody.length,
				current: 0,
			};

			return newData;
		});
	}, [displayBody]);

	useEffect(() => {
		setCurrentBody(() => {
			if (!usePagination) {
				return displayBody;
			}
			const returnArray: [] = [];
			const startRef = pagination.current * pagination.pageSize;
			const endRef = Math.min(startRef + pagination.pageSize, pagination.total);

			for (var i = startRef; i < endRef; i++) {
				returnArray.push(displayBody[i]);
			}

			return returnArray;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	return (
		<DataTableAsync
			headings={displayHeadings}
			sort={sortTrigger}
			body={currentBody}
			sticky={sticky}
			compressed={compressed}
			search={searchFunc}
			searchDelay={searchDelay}
			pagination={usePagination && pagination}
			slim={slim}
			clickable={clickable}
			loading={loading}
		/>
	);
};

export default DataTable;
