import React, { useState, useEffect } from 'react';
import { pricesDataProps, brokerDataProps } from '../../widgets/types';
import { useSelector } from 'react-redux';
import IsEqual from '../../widgets/isEqual';
import DateFormat from '../../widgets/dateFormat';

export interface ModalBodyProps {
	priceLaunch: pricesDataProps;
}

const ModalBody = (props: ModalBodyProps) => {
	const [modalBody, setModalBody]: [[any] | undefined, any] = useState();

	const { status } = props.priceLaunch;

	const [brokersData, setBrokersData]: [[brokerDataProps] | undefined, any] = useState();

	const dataStore = (input: any) => {
		if (input.brokersData) {
			if (!brokersData || !IsEqual({ ...brokersData }, { ...input.brokersData })) {
				setBrokersData(input.brokersData);
			}
		}
	};

	useSelector(dataStore);

	useEffect(() => {
		if (brokersData && status) {
			const {
				priceLaunchStartDate,
				priceLaunchEndDate,
				sellingWindowStartDays,
				sellingWindowEndDays,
				tpiList,
				sourceName,
			} = props.priceLaunch;
			const brokers = [];

			if (tpiList) {
				for (let i = 0; i < tpiList.length; i++) {
					const theBroker = brokersData.find((broker: brokerDataProps) => {
						return broker.tpiId === tpiList[i];
					});
					if (theBroker) {
						brokers.push(theBroker.description + (i < tpiList.length - 1 ? ', ' : ''));
					}
				}
			} else {
				brokers.push('no brokers selected');
			}

			if (brokers.length === 0) {
				brokers.push('error matching brokers');
			}

			const dataArray = (
				<>
					<p>
						<strong>Status:</strong> {status}
					</p>
					<p>
						<strong>Start date:</strong> <DateFormat date={priceLaunchStartDate} />
						<br />
						<strong>End date:</strong> <DateFormat date={priceLaunchEndDate} />
						<br />
					</p>
					<p>
						<strong>Selling window:</strong> {sellingWindowStartDays} - {sellingWindowEndDays} days
						<br />
					</p>
					<p>
						<strong>Price file:</strong> <span id="priceFile">{sourceName}</span>
					</p>
					<p>
						<strong>Brokers:</strong> <span id="brokerlist">{brokers}</span>
					</p>
				</>
			);

			setModalBody(dataArray);
		} else if (!brokersData) {
			setModalBody(<p>Broker data not loaded</p>);
		}
	}, [props.priceLaunch, status, brokersData]);

	return <>{modalBody && modalBody}</>;
};

export default ModalBody;
