import { Auth } from '@aws-amplify/auth';
/**
 * SSO signin / default provider is RED-AD
 *
 * Props type: FederatedSignInOptions currently does not support
 * provider: "RED-AD",
 * so we will use any instead of the type
 */

export const authFederatedSignIn: (options?: any) => void = (options) => {
	Auth.federatedSignIn({ provider: options?.provider || 'RED-AD' });
};
