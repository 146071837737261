import React from 'react';

import '../../styling.scss';

const InputGroup = (props: { children: any }) => {
	const { children } = props;

	return (
		// add classes / style to block groups of inputs within a form
		<div className="edfmat_input-group">{children}</div>
	);
};

export default InputGroup;
