import React, { FC } from 'react';

export const EDFLogoAnimated: FC = () => (
	<svg width="42" height="42" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 37">
		<path
			d="M21.88 5.52c.14-1.13 0-2.77.86-3.54.26-1.2 2.15-.92 1.85-1.69-.18-.47-1.07-.29-1.43-.16a38.34 38.34 0 0 1-3.85 1L18.05.77c-1.63.22-2.57 1.51-3.47 2.54-.76.86-1.78 3-1 4.23 0 .28-.09.55-.13.83l1.84 2.7c.64.45.72.79 1.41 1.24l.42.64a3.6 3.6 0 0 0 2.5.13c2.65.25 2.37-5.14 3.21-6.27a2.23 2.23 0 0 0-.95-1.29z"
			fill="#ffffff"
		>
			<animate
				attributeName="fill"
				values="#ffffff;#ff6000;#ffffff;#ffffff;#ffffff;#ffffff;#0061bb;#ffffff;#ffffff;#ffffff;#ffffff"
				dur="4s"
				repeatCount="indefinite"
			/>
		</path>

		<path
			d="M29.96 12.69a11.7 11.7 0 0 0-5.11 1.88 3.86 3.86 0 0 0-1 1 1.64 1.64 0 0 0-.17 1.11 1.76 1.76 0 0 0 .66 1.22c3.16-.23 8.63-.43 9.7 2.22a6.91 6.91 0 0 0 .15.68c1-.26.28-.17 1.81.07a6.71 6.71 0 0 0 1.8-.28.74.74 0 0 0 .41-.3 1.55 1.55 0 0 1 0-.39 1.46 1.46 0 0 0 .76-.13c1.46-.23.69-.92 1.38-1.69-.47-5.01-5.55-6.16-10.36-5.42z"
			fill="#ffffff"
		>
			<animate
				attributeName="fill"
				values="#ffffff;#ffffff;#ff6000;#ffffff;#ffffff;#ffffff;#ffffff;#0061bb;#ffffff;#ffffff;#ffffff"
				dur="4s"
				repeatCount="indefinite"
			/>
		</path>

		<path
			d="M24.33 23.43c-.73.56-1 .42-1.47 1.29 0 0 .07.13 0 .13.44.87 1.44 1.22 2.18 1.83.23.2.85 1.27 1.14 1.35.33.39.55 1.49.76 1.49.23.3 0 1 .21 1.31 0 1.29-.17 1.39-.39 1.63 0 .09.12.33.12.42s.58.43.81.38c.7 0 3.59 2.28 3.82 2.72 2.07.15 1.92-.48 3.35-1.09.39-.16.7 0 1-.25s.29-.81.54-1.08c.01-5.1-6.93-9.93-12.07-10.11z"
			fill="#ffffff"
		>
			<animate
				attributeName="fill"
				values="#ffffff;#ffffff;#ffffff;#ff6000;#ffffff;#ffffff;#ffffff;#ffffff;#0061bb;#ffffff;#ffffff"
				dur="4s"
				repeatCount="indefinite"
			/>
		</path>

		<path
			d="M16.17 24.59c-.29.3-1.09.42-1.31.77a14.34 14.34 0 0 1-1.73 2.32c-2 1.86-6.16 2.8-8.23 1-.14 0-.06.09.1.6.15 1 .93 3.23.64 3.81.19.64.38.41.74.75s.34.87.61 1l1-.06c.06.17.12.35.17.53l1.22-.09c.23.05.92.41 1.12.46l1.35-.09a3.4 3.4 0 0 0 2.8-.74c.49-.33 1-.28 1.45-.65A10.13 10.13 0 0 0 17.93 32c1-1.78.65-6-.24-7.08-.34-.4-.75-.41-1.51-.32z"
			fill="#ffffff"
		>
			<animate
				attributeName="fill"
				values="#ffffff;#ffffff;#ffffff;#ffffff;#ff6000;#ffffff;#ffffff;#ffffff;#ffffff;#0061bb;#ffffff"
				dur="4s"
				repeatCount="indefinite"
			/>
		</path>

		<path
			d="M10.42 21.69a13.33 13.33 0 0 0 2-.47c.8-.4 3.56-2.34 2.68-3.91-.27-3.31-4.26-.51-6-1.67-1.66-.22-3.59-3.48-4.1-4.87a2.07 2.07 0 0 0-.4-.8c0-.05-.17.15-.17.1-.5.59-.42 1.1-.49 2.53h-.68a1.63 1.63 0 0 1-.34 1.06l-.15.26c-.43-.14-.21.06-.39-.29-1.59.04-1.48 1.63-2.2 2.54.03 2.97 5.92 6.64 10.24 5.52z"
			fill="#ffffff"
		>
			<animate
				attributeName="fill"
				values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff;#ff6000;#ffffff;#ffffff;#ffffff;#ffffff;#0061bb;"
				dur="4s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);
