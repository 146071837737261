import React, { useState, useEffect, useRef } from 'react';
import { Button, DataTable, Row, Column, Chip, Loader, Input, Modal, PStyle, NoAccess } from '../../edfm';
import { useSelector } from 'react-redux';
import { brokerDataProps, pricesDataProps } from '../../widgets/types';
import IsEqual from '../../widgets/isEqual';

import DatePicker from 'react-datepicker';
import { ApiPath, UploadPrice, LoadPrices, UpdatePrice } from '../../widgets/data';
import { useHistory } from 'react-router-dom';
import DateFormat from '../../widgets/dateFormat';
import useACL, { Feature } from '../../widgets/acl';

export interface IngestProps {
	id?: number | undefined;
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

// May start from start of day tomorrow.
const minStartDate = new Date();
minStartDate.setHours(minStartDate.getHours() + 24);
minStartDate.setHours(0, 0, 0, 0);

// May start up to a month from today.
const maxStartDate = new Date();
maxStartDate.setMonth(maxStartDate.getMonth() + 1);

// Can end price launch earliest today
const minEndDate = (startDate: Date | null): Date => {
	let soonestEnd = todayDate;
	if (startDate !== null) {
		soonestEnd = startDate;
	}
	return soonestEnd;
};

// Can end up to a 2 months after it starts.
const maxEndDate = (minimumStartDate: Date): Date => {
	let furthestEnd = new Date(minimumStartDate);
	furthestEnd.setMonth(furthestEnd.getMonth() + 2);
	return furthestEnd;
};

//Check if start date is valid
const isStartDateValid = (startDate: Date | null, minimumStartDate: Date, maximumStartDate: Date): boolean => {
	return startDate !== null && startDate <= maximumStartDate;
};

//Check if end date is valid
const isEndDateValid = (endDate: Date | null, startDate: Date | null, minimumStartDate: Date): boolean => {
	return (
		endDate !== null &&
		startDate !== null &&
		endDate >= minEndDate(startDate) &&
		endDate <= maxEndDate(minimumStartDate)
	);
};

//Check if date range is valid
const isDateRangeValid = (
	startDate: Date | null,
	endDate: Date | null,
	minimumStartDate: Date,
	maximumStartDate: Date
): boolean => {
	return (
		isStartDateValid(startDate, minimumStartDate, maximumStartDate) &&
		isEndDateValid(endDate, startDate, minimumStartDate)
	);
};

// Should the date modal show
const shouldDateModalShow = (
	status: string | undefined,
	nowDate: Date,
	plEndDate: Date,
	startDate: Date | null,
	endDate: Date | null,
	minimumStartDate: Date,
	maximumStartDate: Date
): boolean => {
	return (
		(isDraftOrIngested(status) && isDateRangeValid(startDate, endDate, minimumStartDate, maximumStartDate)) ||
		(status === 'LIVE' && nowDate.getTime() > plEndDate.getTime())
	);
};

// Check if the price launch can be edited
const canEdit = (status: string | undefined): boolean => {
	return status !== 'VOID' && status !== 'LIVE';
};

// Check if the price launch can not be edited
const canNotEdit = (status: string | undefined): boolean => {
	return status === 'VOID' || status === 'LIVE';
};

const passedLive = (sourceDetails: pricesDataProps | undefined, status: string | undefined): boolean => {
	return sourceDetails !== undefined && status === 'LIVE' && new Date() > new Date(sourceDetails.priceLaunchEndDate);
};

const isDraftOrIngested = (status: string | undefined): boolean => {
	return status === 'DRAFT' || status === 'INGESTED';
};

const endDateIsBeforeStartDate = (startDate: Date | null, endDate: Date | null) => {
	if (startDate === null || endDate === null) {
		return false;
	}

	const startDateUtc = new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());
	const endDateUtc = new Date(endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate());

	return endDateUtc.getTime() < startDateUtc.getTime();
};

const useBrokerList = ({ brokers, disabled = false }: { brokers: brokerDataProps[]; disabled: boolean }) => {
	const selected = useRef<brokerDataProps[]>([]);
	const [lastAction, setLastAction] = useState<string>('');
	const undo = useRef<(...p: any[]) => any>(() => null);

	const removeBroker = (tpiId: number): void => {
		if (disabled) {
			return;
		}
		const broker = selected.current.find((b: brokerDataProps) => b.tpiId === tpiId);
		if (!broker) {
			return;
		}
		setLastAction(`${broker.description} removed`);
		undo.current = () => addBroker(tpiId);
		selected.current = selected.current.filter((b: brokerDataProps) => b.tpiId !== tpiId);
	};

	const addBroker = (tpiId: number): void => {
		if (disabled) {
			return;
		}
		const broker = brokers.find((b: brokerDataProps) => b.tpiId === tpiId);
		if (!broker) {
			return;
		}
		setLastAction(`${broker.description} added`);
		undo.current = () => removeBroker(tpiId);
		selected.current = [...selected.current, broker];
	};

	const toggle = (tpiId: any) => {
		const existingEntry = selected.current.findIndex((b: brokerDataProps) => b.tpiId === tpiId);

		if (existingEntry === -1) {
			addBroker(tpiId);
		} else {
			removeBroker(tpiId);
		}
	};

	const selectAll = (): void => {
		if (disabled) {
			return;
		}
		const original = [...selected.current];
		//only interested in BROKER or TELESALES tpiChannel
		brokers = brokers.filter((broker: brokerDataProps) => {
			return ['BROKER', 'TELESALES'].includes(broker.tpiChannel);
		});
		const added = brokers.length - original.length;
		setLastAction(`${added} brokers added`);
		undo.current = () => {
			setLastAction(`${added} brokers removed`);
			undo.current = selectAll;
			selected.current = [...original];
		};
		selected.current = [...brokers];
	};

	const deselectAll = (): void => {
		if (disabled) {
			return;
		}
		const original = [...selected.current];
		const removed = original.length;
		setLastAction(`${removed} brokers removed`);
		undo.current = () => {
			setLastAction(`${removed} brokers added`);
			undo.current = deselectAll;
			selected.current = [...original];
		};
		selected.current = [];
	};

	const setSelected = (tpiIds: Number[]) => {
		selected.current = tpiIds.flatMap((tpiId) => brokers.find((b) => b.tpiId === tpiId) || []);
	};

	return {
		selectedBrokers: selected.current,
		setSelectedBrokers: setSelected,
		lastAction,
		undo: undo.current,
		toggle,
		selectAll,
		deselectAll,
		addBroker,
		removeBroker,
	};
};

const ChangeOrSetButton = ({ value, disabled, onClick }: any) => (
	<span
		style={{ textDecoration: 'underline', cursor: 'pointer' }}
		onClick={() => {
			if (!disabled) {
				onClick();
			}
		}}
	>
		{value !== null ? 'change' : 'set'}
	</span>
);

const SelectBrokersTable = (props: {
	brokers: brokerDataProps[];
	selected: brokerDataProps[];
	onClick: (tpiId: number) => void;
}) => (
	<DataTable
		body={props.brokers.map((item: brokerDataProps) => ({
			clickAction: (): void => {
				props.onClick(item.tpiId!);
			},
			columns: [props.selected.includes(item) ? 'X' : '_', item.description, item.tpiBusinessKey],
		}))}
		clickable={true}
		slim
	/>
);
const BrokerChips = (props: { brokers: brokerDataProps[]; canDelete: boolean; onClick: (tpiId: number) => void }) => (
	<>
		{props.brokers.map((elem: brokerDataProps) => (
			<Chip
				key={elem.tpiId!}
				label={elem.description}
				showClose={props.canDelete}
				action={() => props.canDelete && props.onClick(elem.tpiId!)}
			/>
		))}
	</>
);

const DateErrorMessage = (props: { live: boolean }) =>
	props.live ? (
		<>
			<p>
				This Price launch is <strong>LIVE</strong>, but its <strong>end date</strong> has passed.
			</p>
			<p>No further editing of this price launch is possible.</p>
			<p>Please close this Price launch and ensure there a valid Price launch in place.</p>
		</>
	) : (
		<>
			<p>Product dates need updating before price can be launched</p>
			<p>
				Price launch <strong>end date</strong> must be on or after the start date.
			</p>
		</>
	);

const IngestPrices = (props: IngestProps) => {
	const { id } = props;

	const history = useHistory();

	const updateSection = (newSection: string) => {
		history.push(newSection);
	};

	const [myFile, setMyFile]: [{ filename: string; date: number; file: any } | undefined, any] = useState();

	const [updating, setUpdating] = useState<boolean>(false);
	const [interaction, setInteraction] = useState<boolean>(true);

	const [pricesData, setPricesData]: [[pricesDataProps] | undefined, any] = useState();

	const [sourceName, setSourceName]: [string | undefined, any] = useState();

	const [sourceDetails, setSourceDetails]: [pricesDataProps | undefined, any] = useState();

	const [showDateModal, setShowDateModal] = useState<boolean>(false);

	const [status, setStatus]: [string | undefined, any] = useState();

	const [brokersData, setBrokersData] = useState<brokerDataProps[]>([]);
	const [path, setPath]: [string | undefined, any] = useState();

	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [sellingWindowStartDays, setSellingWindowStartDays] = useState<number>(0);
	const [sellingWindowEndDays, setSellingWindowEndDays] = useState<number>(184);

	const [description, setDescription] = useState('');

	const [priceLaunchTpis, setPriceLaunchTpis] = useState<brokerDataProps[]>([]);

	type errorType = {
		error: string;
		hide: boolean;
		title: string;
	};

	const [error, setError]: [errorType, any] = useState({ error: '', hide: true, title: '' });

	ApiPath((apiPath: { apiurl: string }) => {
		setPath(apiPath.apiurl);
	});

	LoadPrices();

	const { selectedBrokers, setSelectedBrokers, lastAction, undo, toggle, selectAll, deselectAll, removeBroker } =
		useBrokerList({
			brokers: brokersData,
			disabled: !interaction,
		});

	useEffect(() => {
		//if an existing id - need to have loading animation on first hitting page
		if (id && pricesData && brokersData) {
			const selectedPriceLaunch = pricesData.find((elem: pricesDataProps) => {
				return elem.priceLaunchId === Number(id);
			});
			if (selectedPriceLaunch) {
				const {
					description,
					priceLaunchStartDate,
					priceLaunchEndDate,
					sourceName,
					tpiList,
					sellingWindowStartDays: sellingWindowStart,
					sellingWindowEndDays: sellingWindowEnd,
					status,
				} = selectedPriceLaunch;

				const plStartDate = new Date(priceLaunchStartDate);
				const plEndDate = new Date(priceLaunchEndDate);
				const nowDate = new Date();

				if (shouldDateModalShow(status, nowDate, plEndDate, startDate, endDate, minStartDate, maxStartDate)) {
					setShowDateModal(true);
				}

				setDescription(description);
				setStartDate(plStartDate);
				setEndDate(plEndDate);
				setSellingWindowStartDays(sellingWindowStart);
				setSellingWindowEndDays(sellingWindowEnd);

				if (tpiList) {
					setSelectedBrokers(tpiList);
				}

				setStatus(status);

				//use sourceName to show ingested csv filename - and hide / disable upload file option
				setSourceName(sourceName);
				setSourceDetails(selectedPriceLaunch); //store price launch as loaded to resend details when updating
			} else {
				setError((): errorType => {
					return {
						error: `Unable to find Price launch: ${id}. Please refresh the page to try again.`,
						hide: false,
						title: 'Price launch not found',
					};
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pricesData, brokersData]);

	const dataStore = (input: any) => {
		if (input.brokersData) {
			if (!brokersData || !IsEqual({ ...brokersData }, { ...input.brokersData })) {
				setBrokersData(input.brokersData);
			}
		}

		if (input.pricesData) {
			if (!pricesData || !IsEqual(input.pricesData, pricesData)) {
				setPricesData(input.pricesData);
			}
		}
	};

	useSelector(dataStore);

	const updateForm = (e: any, updateType: string) => {
		e.preventDefault();

		//form submit button already validates fields, but validation here too (temp) to avoid typeScript errors with dates and file below
		if (startDate === null || endDate === null) {
			return;
		}

		setUpdating(true);

		const data = {
			status: updateType === 'close' ? 'VOID' : sourceDetails!.status,
			priceLaunchStartDate: `${formatJsonDate(startDate)} 00:00:00`, //make 1 into 01 etc - TODO - if today's date - use current time - if in future start at beginning of day
			priceLaunchEndDate: `${formatJsonDate(endDate)} 23:59:59`,
			sellingWindowStartDays: Number(sellingWindowStartDays),
			sellingWindowEndDays: Number(sellingWindowEndDays),
			isLocked: false,
			sourceName: sourceName,
			ingestedTs: sourceDetails!.ingestedTs,
			sourceTs: sourceDetails!.sourceTs, // "2020-07-01 00:00:00",
			description: description,
			tpiList: selectedBrokers.map((elem: brokerDataProps) => elem.tpiId), //[1,2,3]
			updatedTs: sourceDetails!.updatedTs,
		};

		//1 - post broker, file and date info to API
		if (path && id) {
			//as apiPath *must* exist to reach this sub page - no current handling / subscription created in case not defined
			//id *must* exist to have update option

			const successFunc = (): void => {
				setUpdating(false);
				setInteraction(false);

				//reload prices and set flag to forced to get list with new updated entry
				updateSection(`/price-launches?reload=true&launch=${id}`);
			};

			const errorFunc = (error: string): void => {
				setUpdating(false);

				setError((): errorType => {
					return { error: error, hide: false, title: 'Error updating Price launch' };
				});
			};

			UpdatePrice(path, data, id, successFunc, errorFunc);
		}
	};

	const submitForm = (e: any) => {
		e.preventDefault();

		//form submit button already validates fields, but validation here too (temp) to avoid typeScript errors with dates and file below
		if (startDate === null || endDate === null) {
			alert('start and end dates must be set');
			return;
		}

		if (!myFile) {
			alert('file must be selected');
			return;
		}

		setUpdating(true);

		const data = {
			status: 'DRAFT',
			priceLaunchStartDate: `${formatJsonDate(startDate)} 00:00:00`, //make 1 into 01 etc - TODO - if today's date - use current time - if in future start at beginning of day
			priceLaunchEndDate: `${formatJsonDate(endDate)} 23:59:59`,
			sellingWindowStartDays: sellingWindowStartDays,
			sellingWindowEndDays: sellingWindowEndDays,
			isLocked: false,
			sourceName: myFile.filename,
			sourceTs: myFile.date, // "2020-07-01 00:00:00",
			description: description,
			tpiList: selectedBrokers.map((elem: brokerDataProps) => elem.tpiId), //[1,2,3]
		};

		//1 - post broker, file and date info to API
		if (path) {
			//as apiPath *must* exist to reach this sub page - no current handling / subscription created in case not defined

			const successFunc = (priceLaunchId: number): void => {
				setUpdating(false);
				setInteraction(false);

				//reload prices and set flag to forced to get list with new updated entry
				updateSection(`/price-launches?reload=true&launch=${priceLaunchId}`);
			};

			const errorFunc = (error: string): void => {
				setUpdating(false);
				setError((): errorType => {
					return { error: error, hide: false, title: 'Error creating Price launch' };
				});
			};

			UploadPrice(path, data, myFile.file, successFunc, errorFunc);
		}
	};

	const validationErrors = [
		!myFile && !id && 'CSV file selection required. ',
		startDate === null && 'Price launch start date not set. ',
		status && isDraftOrIngested(status) && startDate !== null && endDate === null && 'Price launch end date not set. ',
		endDateIsBeforeStartDate(startDate, endDate) &&
			'Price launch end date must be on or after the Price launch start date. ',
		selectedBrokers.length === 0 && 'No brokers selected. ',
		description === '' && 'Description required. ',
	].filter((x) => x);

	const isValid = validationErrors.length === 0;

	const onChangeHandler = (e: any) => {
		const date = new Date(e.target.files[0].lastModified);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const dateFormated = `${formatJsonDate(date)} ${hours}:${minutes}:${seconds}`;

		setMyFile({ filename: e.target.files[0].name, date: dateFormated, file: e.target.files[0] });
	};

	const myRef: any = useRef();

	const handleFileSelect = (e: any) => {
		e.preventDefault();
		myRef.current.click();
	};

	const formatJsonDate = (date: Date): string => {
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);

		return `${date.getFullYear()}-${month}-${day}`;
	};

	const hideModal = (): void => {
		setError((oldVal: errorType) => {
			const newVal = { ...oldVal };
			newVal.hide = !oldVal.hide;
			return newVal;
		});
	};

	useEffect(() => {
		setPriceLaunchTpis(
			brokersData.filter((brokerData: brokerDataProps) => {
				return brokerData.tpiChannel === 'BROKER' || brokerData.tpiChannel === 'TELESALES';
			})
		);
	}, [brokersData]);

	const acl = useACL();
	if (!acl(Feature.PriceLaunch)) return <NoAccess />;

	return (
		<>
			{/* off-screen unstylable file input - dynamically tiggered by second styled button */}
			{/* positioning offscreen and using ref as opposed to shadow DOM so can be trigered in tests */}
			<div style={{ position: 'fixed', top: '-5000px' }}>
				<label htmlFor="hidden-upload">Upload trigger</label>
				<input id="hidden-upload" ref={myRef} type="file" accept=".csv" onChange={onChangeHandler} />
			</div>

			<Modal hide={error.hide} title={error.title} customClose={hideModal} content={error.error} close={true} />

			{showDateModal && (
				<Modal
					data-testid="test-modal"
					level={2}
					size="sm"
					title="Invalid dates"
					customClose={() => setShowDateModal(false)}
					content={<DateErrorMessage live={status === 'LIVE'} />}
					close={true}
				/>
			)}

			{updating && (
				<div className="list-broker--loader">
					<Loader />
				</div>
			)}
			<Row>
				<Column columns={6}>
					{status && (
						<p className={`ingest_status-strap ingest_status-strap--${status}`}>
							<strong>Status:</strong> {status}
						</p>
					)}

					{canNotEdit(status) ? (
						<p>
							<strong>Description:</strong> {description}
						</p>
					) : (
						<Input
							fullWidth
							type="text"
							value={description}
							id="description"
							label="Description"
							onChange={(e: any) => setDescription(e.target.value)}
						/>
					)}

					{sourceName ? (
						<>
							<p>
								Uploaded price file:<em>&nbsp;{sourceName}</em>
							</p>
						</>
					) : myFile ? (
						<>
							<p>
								Selected price file:<em>&nbsp;{myFile.filename}</em>
							</p>
							<Button label="Change file" disabled={!interaction} fullwidth action={handleFileSelect} />
							<br />
						</>
					) : (
						<>
							<p>Select CSV price file</p>
							<Button label="Select file" disabled={!interaction} primary fullwidth action={handleFileSelect} />
							<br />
						</>
					)}
				</Column>
				<Column columns={6}>
					<PStyle>
						<strong>Price launch start date:</strong>
						{startDate ? <DateFormat date={startDate} /> : 'not set'}
						&nbsp;
						{canEdit(status) && (
							<DatePicker
								showPopperArrow={true}
								selected={startDate}
								onChange={(date: Date) => setStartDate(date)}
								customInput={<ChangeOrSetButton value={startDate} disabled={!interaction} />}
								maxDate={maxStartDate}
							/>
						)}
						<br />
						<strong>Price launch end date:</strong>
						{endDate ? <DateFormat date={endDate} /> : 'not set'}
						&nbsp;
						{status !== 'VOID' && !passedLive(sourceDetails, status) && (
							<DatePicker
								showPopperArrow={true}
								selected={endDate}
								onChange={(date: Date) => setEndDate(date)}
								customInput={<ChangeOrSetButton value={endDate} disabled={!interaction} />}
								minDate={minEndDate(startDate)}
								maxDate={maxEndDate(minStartDate)}
							/>
						)}
					</PStyle>

					<PStyle>
						{canEdit(status) && (
							<>
								<Input
									fullWidth
									type="text"
									value={sellingWindowStartDays.toString()}
									id="sellingWindowStartDays"
									label="Selling window start (days from sign date)"
									onChange={(e: any) => setSellingWindowStartDays(parseInt(e.target.value))}
								/>
								<Input
									fullWidth
									type="text"
									value={sellingWindowEndDays.toString()}
									id="sellingWindowEndDays"
									label="Selling window end (days from sign date)"
									onChange={(e: any) => setSellingWindowEndDays(parseInt(e.target.value))}
								/>
							</>
						)}
					</PStyle>
				</Column>
			</Row>

			<hr></hr>

			<Row>
				{canEdit(status) ? (
					<>
						<Column columns={6}>
							<p>
								<strong>Brokers:</strong>
							</p>
							<p>Select brokers for this price launch.</p>
							<div className="ingest--brokerslist">
								{priceLaunchTpis && (
									<SelectBrokersTable brokers={priceLaunchTpis} selected={selectedBrokers} onClick={toggle} />
								)}
							</div>
						</Column>

						<Column columns={6}>
							<div>
								{priceLaunchTpis && (
									<>
										<Button
											label="Select all"
											disabled={selectedBrokers.length >= priceLaunchTpis.length || !interaction}
											size="sm"
											action={selectAll}
										/>
										<Button
											label="Clear selections"
											disabled={selectedBrokers.length === 0 || !interaction}
											size="sm"
											action={deselectAll}
										/>
									</>
								)}
							</div>
							<br />
							{interaction && lastAction !== '' && (
								<PStyle className="additional-details">
									<span>
										{lastAction}
										<strong>
											<span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={undo}>
												undo
											</span>
										</strong>
									</span>
								</PStyle>
							)}
							<BrokerChips brokers={selectedBrokers} canDelete={canEdit(status)} onClick={removeBroker} />
						</Column>
					</>
				) : (
					<>
						<p>
							<strong>Brokers:</strong>
						</p>
						{selectedBrokers.map((b) => b.description).join(', ')}
					</>
				)}
			</Row>
			{status !== 'VOID' && (
				<>
					<Row>
						{/* if file, brokers and date selected, enable button */}
						<p className="u-pull-right">
							{id ? (
								passedLive(sourceDetails, status) ? (
									<Button
										label="Set price launch to void"
										primary
										action={(e: any) => {
											updateForm(e, 'close');
										}}
									/>
								) : (
									<Button
										label="Update price launch"
										primary
										disabled={!isValid || !interaction}
										action={(e: any) => {
											updateForm(e, 'update');
										}}
									/>
								)
							) : (
								<Button label="Create price launch" primary disabled={!isValid || !interaction} action={submitForm} />
							)}
						</p>
					</Row>
					{validationErrors.length && !passedLive(sourceDetails, status) && (
						<Row>
							<span className="u-pull-right additional-details">{validationErrors}</span>
						</Row>
					)}
				</>
			)}
		</>
	);
};

export default IngestPrices;
