import Swal from 'sweetalert2';

/**
 * Start the process to return an energy sale
 * @param id the id of the sale to return
 * @param previousStatus the previous EnergySaleDetail.energySaleStatus of the sale to return (used for logging)
 * @param updatedTs the previous EnergySaleDetail.updatedTs of the sale to return (used for locking)
 */
const showReturnModal =
	(
		returnReasons: { description: string; code: string }[],
		onReturn: (params: {
			id: number;
			previousStatus: string;
			updatedTs: string;
			description: string;
			code: string;
		}) => void
	) =>
	(id: number, previousStatus: string, updatedTs: string) => {
		const options = returnReasons.reduce(
			(acc, current) => `${acc}<option value="${current.code}">${current.description}</option>`,
			''
		);

		const Toast = Swal.mixin({
			toast: true,
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: true,
			customClass: {
				title: 'edfm_popup_font_content',
			},
		});

		// First popup
		Swal.fire({
			title: 'Are you sure you want to return this sale?',
			text: "You won't be able to revert this. An email will be sent to the broker.",
			showCancelButton: true,
			confirmButtonText: `Yes`,
			denyButtonText: `Cancel`,
			icon: 'warning',
			width: '50em',
			customClass: {
				title: 'edfm_popup_font_content',
				actions: 'edfm_popup_font_actions',
			},
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				// Second popup
				Swal.fire({
					title: 'Return reason',
					html:
						'<textarea id="swal-input1" class="swal2-textarea" placeholder="Please provide additional details"></textarea>' +
						`<select id="swal-input2">${options}</select>`,
					focusConfirm: false,
					showCancelButton: true,
					denyButtonText: `Cancel`,
					width: '50em',
					customClass: {
						title: 'edfm_popup_font_content',
						actions: 'edfm_popup_font_actions',
					},
					preConfirm: () => {
						return [
							(document.getElementById('swal-input1') as HTMLInputElement).value,
							(document.getElementById('swal-input2') as HTMLInputElement).value,
						];
					},
				}).then((formValue) => {
					if (formValue.value) {
						// Trigger Toast
						Toast.fire({
							icon: 'success',
							title: 'The sale was returned. An email will be sent shortly.',
						});

						onReturn({
							id,
							previousStatus,
							updatedTs,
							code: formValue.value[1],
							description: formValue.value[0],
						});
					}
				});
			}
		});
	};

export default showReturnModal;
