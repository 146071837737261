import { API } from '@edfenergy/res-agentui-sso-auth';

const SaveBroker = (path: string, method: string, id: string = '', body: any, successFunc: any, failFunc?: any) => {
	const errorHandler = (error: string) => {
		if (failFunc) {
			failFunc(error);
		} else {
			console.warn(error);
		}
	};

	// fetch(`${path}/tpi${id}`, {
	// 	method: method,
	// 	cache: 'no-cache',
	// 	body: body,
	// })
	// 	.then((res) => res.json())
	// 	.then(

	let APICallerFunction: (apiName: any, path: any, init: any) => Promise<any>;

	if (method === 'POST') {
		APICallerFunction = API.post.bind(API);
	} else if (method === 'PUT') {
		APICallerFunction = API.put.bind(API);
	} else {
		throw new Error('Method must be POST or PUT');
	}

	APICallerFunction('api', `/tpi${id}`, {
		body,
	})
		.then((result) => {
			console.log('success result', JSON.stringify(result));
			if (result.result && result.result.error) {
				errorHandler('Unable to update broker details: ' + result.result.error);
			} else if (result.result && result.result.message) {
				errorHandler('Unable to update broker details: ' + result.result.message);
			} else if (result && result.error) {
				errorHandler('Unable to update broker details: ' + result.error);
			} else if (result && result.message) {
				errorHandler('Unable to update broker details: ' + result.message);
			} else if (result.result && result.result.tpiId && result.result.updatedTs) {
				successFunc(result.result.tpiId, result.result.updatedTs);
			} else {
				errorHandler('Unable to update broker details.');
			}
		})
		.catch((error) => {
			if (error.response) {
				console.log(error.response.data.error);
				//handle error
				errorHandler('Unable to update broker details: ' + error.response.data.error);
			} else {
				errorHandler('Unable to update broker details');
			}
		});
};

export default SaveBroker;
