import { API } from '@edfenergy/res-agentui-sso-auth';
import { useDispatch, useSelector } from 'react-redux';
import { ApiPath } from '.';

const LoadPrices = (forceLoad: boolean = false, callback?: any, failFunc?: any) => {
	const dispatch = useDispatch();

	const checkStore = (input: any) => {
		const pricesData = input.pricesData;
		return pricesData;
	};
	const errorHandler = (error: string) => {
		if (failFunc) {
			failFunc(error);
		} else {
			console.warn(error);
		}
	};

	const pricesResult: any = useSelector(checkStore);

	//refactor check for Api data path into new function and cal asynetrically
	const apiLanded = (apiData: any): void => {
		if (forceLoad) {
			fetchPrices(apiData);
		} else if (!pricesResult) {
			//check if currently loaded broker is the requested one
			//or if greater than time threshold for stale data before loading - TODO
			fetchPrices(apiData);
		}
	};

	ApiPath(apiLanded);

	const fetchPrices = (apiData: any) => {
		API.get('api', '/price-launch', {})
			.then((result) => {
				dispatch({ type: 'pricesData', data: result.result });
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response.data.error);
					//handle error
					errorHandler('Unable to load prices: ' + error.response.data.error);
				} else {
					errorHandler('Unable to load prices');
				}
			});
	};
};

export default LoadPrices;
