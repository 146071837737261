import React, { useState, useEffect } from 'react';
import { Loader, Row, Column } from '../../../edfm';
import { useParams } from 'react-router-dom';

import IsEqual from '../../../widgets/isEqual';

import { useSelector } from 'react-redux';

import ViewBrokerContactDetails from './viewBrokerContactDetails';
import EditBrokerContactDetails from './editBrokerContactDetails';
import { LoadBroker } from '../../../widgets/data';
import { brokerDataProps, contactProps, URLParams } from '../../../widgets/types';

const BrokerContact = () => {
	const [loadingContact, setLoadingContact] = useState(true);
	const [brokerData, setBrokerData]: [brokerDataProps | undefined, any] = useState();
	const [contact, setContact]: any = useState();
	const [editMode, setEditMode] = useState(false);

	const { id, contactId } = useParams<URLParams>();

	LoadBroker(Number(id));

	useEffect(() => {
		if (brokerData && brokerData.Contacts) {
			const found = brokerData.Contacts.find((item: contactProps, i: number) => {
				// eslint-disable-next-line eqeqeq
				return item.contactId == Number(contactId);
			});

			if (found) {
				setContact(found);
			}
			setLoadingContact(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brokerData]);

	const dataStore = (input: any): void => {
		if (input.brokerData) {
			if (!IsEqual({ ...input.brokerData }, { ...brokerData })) {
				setBrokerData(input.brokerData);
			}
		}
	};

	useSelector(dataStore);

	return (
		<>
			<Row>
				<Column columns={12}>
					{loadingContact ? (
						<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
							<Loader />
						</div>
					) : (
						<>
							{brokerData && <h4>{brokerData.description}</h4>}

							{brokerData && (editMode || !contact) ? (
								<EditBrokerContactDetails contact={contact} brokerData={brokerData} cancel={() => setEditMode(false)} />
							) : (
								brokerData && (
									<ViewBrokerContactDetails contact={contact} brokerData={brokerData} edit={() => setEditMode(true)} />
								)
							)}
						</>
					)}
				</Column>
			</Row>
		</>
	);
};

export default BrokerContact;
