import React, { useState, useEffect } from 'react';

import { Loader } from '../../edfm';

import ReportTemplate from './reportTemplate';
import DateFormat from '../../widgets/dateFormat';
import { LoadCommissionStatement } from '../../widgets/data';
import { useSelector } from 'react-redux';
import IsEqual from '../../widgets/isEqual';

export interface rowOption {
	column: string;
	condition: string;
	value: string | number;
}

const CommissionStatement = (props: { id: number; statementId: number; statementsData: any }) => {
	const { id, statementId } = props;

	const [currentFilter, setCurrentFilter] = useState('all'); //keep track of any previously selected filters in the side panel
	const [filterLabel, setFilterLabel] = useState('');
	const [selectedRows, setSelectedRows]: [Array<rowOption>, any] = useState([]);
	const [statementData, setStatementData]: [any, any] = useState(); //create interface type - TODO
	const [data, setData]: [any, any] = useState(); //create interface type - TODO

	const dataStore = (input: any) => {
		if (input.statementData) {
			if (!statementData || !IsEqual({ ...statementData }, { ...input.statementData })) {
				setStatementData(input.statementData);
			}
		} else {
			//bust cache when changing statement
			if (data) {
				setData(undefined);
			}
		}
	};

	useSelector(dataStore);

	LoadCommissionStatement(id, statementId);

	useEffect(() => {
		if (statementData) {
			setData(() => {
				const fields = [
					{ label: 'Payment type', sortable: true },
					{ label: 'Status', sortable: true },
					{ label: 'Sales type', sortable: true },
					{ label: 'MPXN', sortable: true },
					{ label: 'EAC', sortable: true },
					{ label: 'Signed date', sortable: true },
					{ label: 'Contract start', sortable: true },
					{ label: 'Contract end', sortable: true },
					{ label: 'Business Name', sortable: true },
					//{label:"Consumption KwH",sortable: true}, -- removed from MVP, can devine from either SalesEAC, initialIndustry or currentIndustryEAC depending on stage ACCEPT, LIVE, LIVED019 etc
					//{label:"Consumption MwH",sortable: true},  -- removed from MVP
					{ label: 'Product code', sortable: true },
					{ label: 'Commission per MWh', sortable: true },
					{ label: 'Commission due', sortable: true },
					{ label: 'Incentive', sortable: true },
					{ label: 'Reconciliation', sortable: true },
					{ label: 'Total due', sortable: true },
					//process once for CSV and interactive data - TODO
					//add column only seen in csv export for total breakdown (accessed as tooltip onscreen)
					{ label: 'Total breakdown', sortable: true, hidden: true },
					//{label:"Comments",sortable: true}, //currently no corresponding field in new SMEC data model
					{ label: 'External ref', sortable: true },
					{ label: 'Payment query ref', sortable: true },
				];

				const mergedData: any = [];
				const refArray: Array<{ MPXN: string; txStage: string }> = [];

				//for each payment for the same sale and stage - merge into 1 row with accumulated total (split for incentive etc)
				for (let i = 0; i < statementData.data.length; i++) {
					let ref = refArray.findIndex((element: { MPXN: string; txStage: string }) => {
						return statementData.data[i].MPXN === element.MPXN && statementData.data[i].txStage === element.txStage;
					});

					if (ref === -1) {
						refArray.push({
							MPXN: statementData.data[i].MPXN,
							txStage: statementData.data[i].txStage,
						});

						const toMerge = {
							...statementData.data[i],
							totalDue: 0,
							paymentStandard: 0,
							paymentPayCapAdjust: 0,
							paymentIncentive: 0,
							commissionDue: 0,
							incentive: 0,
							reconciliation: 0,
							totalComponents: [],
						};

						mergedData.push(toMerge);

						ref = mergedData.length - 1;
					}

					const toMerge = {
						...mergedData[ref],
						totalDue: mergedData[ref].totalDue + statementData.data[i].txValue,
					};

					//categorise payments by current stage to determine total due breakdown info
					//to ratify / confirm with business - TODO
					if (
						statementData.data[i].txType === 'STANDARD' ||
						statementData.data[i].txType === 'D019REC' ||
						statementData.data[i].txType === 'REVERSAL'
					) {
						toMerge.paymentStandard = mergedData[ref].paymentStandard + statementData.data[i].txValue;
					} else if (statementData.data[i].txType === 'PAYCAPADJ') {
						toMerge.paymentPayCapAdjust = mergedData[ref].paymentPayCapAdjust + statementData.data[i].txValue;
					} else if (statementData.data[i].txType === 'INCENTIVE') {
						toMerge.paymentIncentive = mergedData[ref].paymentIncentive + statementData.data[i].txValue;
						toMerge.incentive = mergedData[ref].incentive + statementData.data[i].txValue;
					}

					if (statementData.data[i].txStage !== 'FINAL' && statementData.data[i].txType !== 'INCENTIVE') {
						toMerge.commissionDue = mergedData[ref].commissionDue + statementData.data[i].txValue;
					} else if (statementData.data[i].txStage === 'FINAL' && statementData.data[i].txType !== 'INCENTIVE') {
						toMerge.reconciliation = mergedData[ref].reconciliation + statementData.data[i].txValue;
					}

					toMerge.totalComponents.push({
						label: statementData.data[i].txType,
						amount: statementData.data[i].txValue,
					});

					mergedData[ref] = toMerge;
				}

				const theData = mergedData.map((dataItem: any) => {
					//base payment type on current stage
					const paymentType =
						dataItem.txStage === 'ACCEPT'
							? 'Initial Payment Raised'
							: dataItem.txStage === 'LIVE' || dataItem.txStage === 'LIVED19' || dataItem.txStage === 'ENDED'
							? 'Recon Payment Raised'
							: 'Final Payment Raised';

					const totalBreakdown = dataItem.totalComponents.map(
						(component: { label: string; amount: number }, i: number) => {
							return (
								' ' +
								component.label +
								(component.amount < 0
									? ' = -£' + Number(String(component.amount).slice(1)).toFixed(2) + ','
									: ' = £' + component.amount.toFixed(2) + ',')
							);
						}
					);

					//process once for CSV and interactive data
					//add column only seen in csv export for total breakdown (accessed as tooltip onscreen)

					return {
						searchableText: `${dataItem.business} ${dataItem.saleType} ${dataItem.MPXN} ${paymentType} ${dataItem.externalRef} ${dataItem.paymentQueryRef}`,

						columns: [
							paymentType,
							dataItem.contractStatus,
							dataItem.saleType === 'ACQ' ? 'Acquisition' : 'Retention',
							dataItem.MPXN,
							dataItem.txEAC,
							{
								display: <DateFormat date={dataItem.signDate} />,
								sortVal: new Date(dataItem.signDate).getTime(),
							},
							{
								display: <DateFormat date={dataItem.contractStartDate} />,
								sortVal: new Date(dataItem.contractStartDate).getTime(),
							},
							{
								display:
									dataItem.contractEndDate === '' || !dataItem.contractEndDate ? (
										'-'
									) : (
										<DateFormat date={dataItem.contractEndDate} />
									),
								sortVal:
									dataItem.contractEndDate === '' || !dataItem.contractEndDate
										? 0
										: new Date(dataItem.contractEndDate).getTime(),
							},
							dataItem.business,
							dataItem.product,
							{
								display:
									'£' +
									(dataItem.commissionMWh === '' || !dataItem.commissionMWh
										? '0'
										: Number(dataItem.commissionMWh).toFixed(2)),
								sortVal:
									dataItem.commissionMWh === '' || !dataItem.commissionMWh
										? 0
										: Number(dataItem.commissionMWh).toFixed(2),
							},
							{
								display:
									'£' +
									(dataItem.commissionDue === '' || !dataItem.commissionDue ? '0' : dataItem.commissionDue.toFixed(2)),
								sortVal:
									dataItem.commissionDue === '' || !dataItem.commissionDue
										? 0
										: Number(dataItem.commissionDue).toFixed(2),
							},
							{
								display: '£' + (dataItem.incentive === '' || !dataItem.incentive ? '0' : dataItem.incentive.toFixed(2)),
								sortVal: dataItem.incentive === '' || !dataItem.incentive ? 0 : Number(dataItem.incentive).toFixed(2),
							},
							{
								display:
									'£' +
									(dataItem.reconciliation === '' || !dataItem.reconciliation
										? '0'
										: dataItem.reconciliation.toFixed(2)),
								sortVal:
									isNaN(dataItem.reconciliation) || !dataItem.reconciliation
										? 0
										: Number(dataItem.reconciliation).toFixed(2),
							},
							{
								//currently displaying breakdown of total cost as a default tooltip - to be reviewed to show as a styled tooltip, modal, expanded box contents etc... - TO BE REVIEWED
								display: (
									<span title={`Total breakdown: ${totalBreakdown}`}>
										£{dataItem.totalDue === '' || !dataItem.totalDue ? '0' : dataItem.totalDue.toFixed(2)}
									</span>
								),
								sortVal: Number(dataItem.totalDue).toFixed(2),
							},
							totalBreakdown,
							//dataItem.comments, //currently no corresponding field in new SMEC data model
							dataItem.externalRef,
							dataItem.paymentQueryRef,
						],
					};
				});

				return { fields: fields, data: theData };
			});
		}
	}, [statementData]);

	const filterRows = (rows: Array<rowOption>) => {
		setSelectedRows(rows);
	};

	const recentTimeStamp90 = new Date();
	recentTimeStamp90.setDate(recentTimeStamp90.getDate() - 90);

	const recentTimeStamp30 = new Date();
	recentTimeStamp30.setDate(recentTimeStamp30.getDate() - 30);

	const filterOptionsArray = [
		{
			label: 'All records',
			current: currentFilter === 'all',
			action: () => {
				setCurrentFilter('all');
				filterRows([]);
			},
		},

		//Acquisitions
		{
			label: 'Acquisitions',
			current: currentFilter === 'acq',
			action: () => {
				setCurrentFilter('acq');
				filterRows([{ column: 'Sales type', condition: 'equals', value: 'Acquisition' }]);
			},
		},

		//Renewals
		{
			label: 'Retentions',
			current: currentFilter === 'ret',
			action: () => {
				setCurrentFilter('ret');
				filterRows([{ column: 'Sales type', condition: 'equals', value: 'Retention' }]);
			},
		},

		//negative total due (clawback / reconciliation)
		{
			label: 'Negative total due (clawback / reconciliation)',
			current: currentFilter === 'neg',
			action: () => {
				setCurrentFilter('neg');
				filterRows([{ column: 'Total due', condition: 'less', value: 0 }]);
			},
		},

		//Initial payments
		{
			label: 'Initial payments',
			current: currentFilter === 'initial',
			action: () => {
				setCurrentFilter('initial');
				filterRows([{ column: 'Payment type', condition: 'equals', value: 'Initial Payment Raised' }]);
			},
		},

		//Recon payments
		{
			label: 'Recon payments',
			current: currentFilter === 'recon',
			action: () => {
				setCurrentFilter('recon');
				filterRows([{ column: 'Payment type', condition: 'equals', value: 'Recon Payment Raised' }]);
			},
		},

		//Final payments
		{
			label: 'Final payments',
			current: currentFilter === 'final',
			action: () => {
				setCurrentFilter('final');
				filterRows([{ column: 'Payment type', condition: 'equals', value: 'Final Payment Raised' }]);
			},
		},
	];

	const clearFilters = () => {
		setCurrentFilter('all');
		filterRows([]);
	};

	useEffect(() => {
		if (currentFilter === 'acq') {
			setFilterLabel('Acquisitions');
		} else if (currentFilter === 'ret') {
			setFilterLabel('Retentions');
		} else if (currentFilter === 'neg') {
			setFilterLabel('Negative totals due');
		} else if (currentFilter === 'initial') {
			setFilterLabel('Initial payments');
		} else if (currentFilter === 'recon') {
			setFilterLabel('Recon payments');
		} else if (currentFilter === 'final') {
			setFilterLabel('Final payments');
		}
	}, [currentFilter]);

	return (
		<>
			{data === undefined ? (
				<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
					<Loader />
				</div>
			) : (
				<ReportTemplate
					id={id}
					overviewData={{
						description: statementData.description,
						purchaseOrderReference: statementData.purchaseOrderReference,
						length: data.data.length,
						total:
							statementData.data.length > 0 &&
							statementData.data.reduce((accumulator: any, currentValue: any) => {
								return accumulator + Number(currentValue.txValue.toFixed(2));
							}, 0),
					}}
					report="commission"
					exportTitle={statementData.description}
					data={data}
					filterOptionsArray={filterOptionsArray}
					filterLabel={filterLabel}
					selectedRows={selectedRows}
					currentFilter={currentFilter}
					clearFilters={clearFilters}
				/>
			)}
		</>
	);
};

export default CommissionStatement;
