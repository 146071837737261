import React from 'react';

import '../styling.scss';

export interface ContainerProps {
	wide?: boolean;
	children?: any;
}

const Container = (props: ContainerProps) => {
	const { children, wide } = props;

	return <div className={`edfmat_container ${wide && 'edfmat_container--wide'}`}>{children}</div>;
};

export default Container;
