import { API } from '@edfenergy/res-agentui-sso-auth';

const UploadPrice = (path: string, body: any, id: number, successFunc?: any, failFunc?: any) => {
	const errorHandler = (error: string) => {
		if (failFunc) {
			failFunc(error);
		} else {
			console.warn(error);
		}
	};

	API.put('api', `/price-launch/${id}`, { body })
		.then((result) => {
			if (result.result && result.result.error) {
				errorHandler('Unable to update price details in database: ' + result.result.error);
			} else if (result.result && result.result.message) {
				errorHandler('Unable to update price details in database: ' + result.result.message);
			} else if (result && result.error) {
				errorHandler('Unable to update price details in database: ' + result.error);
			} else if (result && result.message) {
				errorHandler('Unable to update price details in database: ' + result.message);
			} else {
				successFunc();
			}
		})
		.catch((error) => {
			if (error.response) {
				console.log(error.response.data.error);
				//handle error
				errorHandler('Unable to update price details in database: ' + error.response.data.error);
			} else {
				errorHandler('Unable to update price details in database');
			}
		});
};

export default UploadPrice;
