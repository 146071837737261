import { API } from '@edfenergy/res-agentui-sso-auth';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiPath } from './data';
import { refDataProps } from './types';

/**
 * a hook to return a sorted list of reference data
 * @returns an array of reference data entries
 */
export const useRefData = () => {
	RefData();

	const getRefData = (store: { refData: refDataProps[][] | undefined }) => store.refData;
	const refData = useSelector(getRefData);

	return refData || [];
};

/**
 * a hook to return a map of reference data
 * @returns a map of refDataCategory to an array of ref data entries
 */
export const useRefDataMap = () => {
	const refData = useRefData();

	const map = useMemo(() => {
		if (!refData) {
			return {};
		}
		return refData.flat().reduce((rdMap: Record<string, refDataProps[]>, rd) => {
			if (!rdMap[rd.refDataCategory]) {
				rdMap[rd.refDataCategory] = [];
			}
			rdMap[rd.refDataCategory].push(rd);
			return rdMap;
		}, {});
	}, [refData]);

	return map;
};

type refDataType = {
	refDataCategory: string;
	description: string;
	sequence: number;
	isActive: boolean;
};

const RefData = () => {
	const dispatch = useDispatch();

	const refData = useSelector((store: { refData: refDataProps[][] | undefined }) => store.refData);
	const needFetch = refData === undefined;

	const loadRefData = (path: { apiurl: string }): void => {
		if (!needFetch) {
			return;
		}
		API.get('api', '/reference-data', {}).then(
			({ result }: { result: refDataType[] }) => {
				if (!result) {
					console.warn('Unable to load reference data: unexpected data format');
					return;
				}

				//sort all refData into categories, and order by sequence - filtering out any inactive ones
				const categories = result.reduce<string[]>(
					(cat, item) => (cat.includes(item.refDataCategory) ? cat : [...cat, item.refDataCategory]),
					[]
				);
				const sortedData: refDataType[][] = categories.map((cat) =>
					result
						.filter((item) => item.refDataCategory === cat)
						.filter((item) => item.isActive)
						.sort((a, b) => a.sequence - b.sequence)
				);

				dispatch({ type: 'refData', data: sortedData });
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				console.warn('Unable to load reference data: ' + error);
			}
		);
	};

	ApiPath(loadRefData);
};

export default RefData;
