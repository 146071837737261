//Renders all data but assumes place in any pagination from passed in params
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Input, Loader, Select } from '../index';

import '../styling.scss';

export interface TableProps {
	headings?: {
		sortable?: boolean;
		reversable?: boolean;
		current?: boolean;
		label: string;
	}[];
	sort?: any;
	body: [];
	clickable?: boolean;
	slim?: boolean;
	search?: any;
	sticky?: boolean;
	compressed?: boolean;
	loading?: boolean;
	searchDelay?: number;
	pagination?:
		| {
				current: number;
				total: number;
				pageSize: number;
				nextPage: any;
				prevPage: any;
				pageSkip: any;
				update: any;
		  }
		| false;
}

const DataTable = (props: TableProps) => {
	const {
		headings,
		body,
		search,
		searchDelay,
		pagination,
		clickable,
		slim,
		loading = false,
		sticky = false,
		compressed = false,
		sort,
	} = props;
	const [searchTerm, setSearchTerm] = useState('');
	const [lastSearch, setLastSearch] = useState('');
	const [isSticky, setSticky] = useState(false);

	const [offsetScroll, setOffsetScroll] = useState(0);

	const myRef = React.useRef<HTMLTableElement>(null);
	const myRefScroll = React.useRef<HTMLTableElement>(null);
	const myRefSizing = React.useRef<HTMLTableSectionElement>(null);

	const [spacerHeading, setSpacerHeading]: any = useState([]);

	const [headingLables, setHeadingLables]: any = useState([]);
	const [headingLablesSticky, setHeadingLablesSticky]: any = useState([]);

	let bodyContent: any = [];
	let searchTimer: any = useRef();

	const handleScroll = () => {
		if (myRef && myRef.current && sticky) {
			setSticky(myRef.current.getBoundingClientRect().top <= 60);
		}
	};

	const handleScroll2 = () => {
		if (myRefScroll && myRefScroll.current && sticky) {
			setOffsetScroll(myRefScroll.current.scrollLeft);
		}
	};

	const handleResize = useCallback(() => {
		if (sticky) {
			if (myRefSizing?.current?.children[0].children) {
				let tempSpacerHeading = [];
				for (let i: number = 0; i < myRefSizing.current.children[0].children.length; i++) {
					tempSpacerHeading[i] = { width: `${myRefSizing?.current?.children[0].children[i].clientWidth - 14}px` }; //remove 30px for padding
				}

				setSpacerHeading(tempSpacerHeading);
			} else {
				//if header children not found - assume not yet rendered - retry in 250 ms
				//refactor with tick or the like - TODO
				setTimeout(() => {
					handleResize();
				}, 250);
			}
		}
	}, [sticky]);

	useEffect(() => {
		const ref = myRefScroll;
		window.addEventListener('scroll', handleScroll);

		//on resize - set fixed tablerow columns to width of hidden table row columns - TODO
		window.addEventListener('resize', handleResize);

		if (ref?.current) {
			ref.current.addEventListener('scroll', handleScroll2);
		} else {
			//if header children not found - assume not yet rendered - retry in 250 ms
			//refactor with tick or the like - TODO

			setTimeout(() => {
				if (ref?.current) {
					ref.current.addEventListener('scroll', handleScroll2);
				}
			}, 2050);
		}

		handleResize();
		handleScroll();

		return () => {
			window.removeEventListener('scroll', () => handleScroll);
			if (ref?.current) {
				ref.current.removeEventListener('scroll', handleScroll2);
			}
		};
		// Disabling this here cause it causes everything to break if we add the deps here
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const searchTimeout = searchDelay ? searchDelay : 1000;

	const updateSearch = (e: any) => {
		clearTimeout(searchTimer.current);

		setSearchTerm(e.target.value);
	};

	useEffect(() => {
		if (lastSearch !== searchTerm) {
			setLastSearch(searchTerm);
			clearTimeout(searchTimer.current);
			if (searchTerm === '') {
				search(searchTerm);
			} else {
				searchTimer.current = setTimeout(() => {
					search(searchTerm);
				}, searchTimeout);
			}
		}
	}, [searchTerm, lastSearch, search, searchTimeout]);

	useEffect(() => {
		if (headings) {
			if (sticky) {
				setHeadingLablesSticky(
					headings.map((elem, i) => {
						if (elem.sortable) {
							return (
								<th
									key={i}
									style={{ ...spacerHeading[i], cursor: 'pointer' }}
									className={elem.current ? 'current' : ''}
									onClick={() => {
										sort(i);
										setTimeout(() => handleResize(), 100);
									}}
								>
									{elem.label}
								</th>
							);
						} else {
							return (
								<th key={i} style={spacerHeading[i]}>
									{elem.label}
								</th>
							);
						}
					})
				);
			}
		}
		// Adding dep here causes table headers to be rendered 2 times.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [spacerHeading, sticky]);

	useEffect(() => {
		if (headings) {
			setHeadingLables(
				headings.map((elem, i) => {
					//if elem.sortable add sort function - passed in from parent (as could need an asynch call to BE)
					//for non-asynch - still passed in from parent, but determined by parent datatable function
					if (elem.sortable) {
						return (
							<th
								key={i}
								style={{ cursor: 'pointer' }}
								className={elem.current ? 'current' : ''}
								onClick={() => {
									sort(i);
									setTimeout(() => handleResize(), 100);
								}}
							>
								{elem.label}
							</th>
						);
					}
					return <th key={i}>{elem.label}</th>;
				})
			);
		}
	}, [headings, handleResize, sort]);

	useEffect(() => {
		setTimeout(handleResize, 100); //needs to be tick, but needs rewrite generally - TODO
	}, [headingLables, handleResize]);

	bodyContent = body.map((elem: any, i) => {
		if (elem !== undefined) {
			const columnOutput = elem.columns.map((elem: any, ii: number) => {
				return <td key={ii}>{elem?.display ? elem.display : elem}</td>;
			});

			return (
				<tr key={i} onClick={elem.clickAction}>
					{columnOutput}
				</tr>
			);
		}
		return <></>;
	});

	const prev = (): void => {
		pagination && pagination.prevPage();
	};

	const next = (): void => {
		pagination && pagination.nextPage();
	};

	const pageButtons: any = [];
	if (pagination) {
		const totalPages: number = Math.ceil(pagination.total / pagination.pageSize);
		const minPage = pagination.current - 2 - (2 - Math.min(totalPages - pagination.current, 2));
		const maxPage = pagination.current + 2 + (2 - Math.min(pagination.current, 2));
		for (let i = 0; i < totalPages; i++) {
			if (i > minPage && i < maxPage) {
				pageButtons.push(
					<input
						key={i}
						className={`edfmat_button edfmat_button--sm ${i === pagination.current && 'edfmat_button--secondary'}`}
						disabled={i === pagination.current}
						type="button"
						onClick={() => {
							pagination.pageSkip(i);
						}}
						value={i + 1}
					/>
				);
			}
		}
	}

	const changePageSize = (i: number) => {
		if (pagination) {
			pagination.update(i);
		}
	};

	return (
		<>
			<div>
				{/* search */}
				{search && (
					<>
						<Input value={searchTerm} label="search" onChange={updateSearch} />
						&nbsp;
						{searchTerm.length > 0 && (
							<input
								className="edfmat_button edfmat_button--sm edfmat_button--abs"
								type="button"
								onClick={() => setSearchTerm('')}
								value="clear search"
							/>
						)}
					</>
				)}

				{pagination && (
					<>
						<Select
							heading="results"
							onChange={(i: number) => {
								changePageSize(i);
							}}
							selected={pagination.pageSize}
							inline={true}
							options={[
								{ label: '10', id: 10 },
								{ label: '25', id: 25 },
								{ label: '50', id: 50 },
								{ label: '100', id: 100 },
							]}
						/>
					</>
				)}
			</div>
			<div className={`u-full-width  edfmat_datatable }`} ref={myRefScroll}>
				{loading && (
					<div className="edfmat_datatable--loader">
						<Loader />
					</div>
				)}
				{body && body.length === 0 ? (
					<div className="edfmat_datatable--empty">no results found</div>
				) : (
					<>
						{headings && (
							<>
								{/* alternative to using sticky within "thead tr td" as not possible due to the horizontal scroll (overflow-x) */}
								<div
									className={`${
										isSticky ? 'edfmat_table_sticky' : 'edfmat_table_sticky--container--none'
									} edfmat_table_sticky--container `}
								>
									<table
										className={`u-full-width edfmat_table ${slim && 'edfmat_table--slim'} ${
											compressed && 'edfmat_table--compressed'
										}`}
									>
										<thead
											className={isSticky ? 'edfmat_table_sticky' : 'edfmat_table_none'}
											style={{ left: `${-(offsetScroll - 27)}px` }}
										>
											<tr>{headingLablesSticky}</tr>
										</thead>
									</table>
								</div>
							</>
						)}
						<table
							className={`u-full-width edfmat_table ${slim && 'edfmat_table--slim'} ${
								compressed && 'edfmat_table--compressed'
							}`}
							ref={myRef}
						>
							{/* table head */}
							{headings && (
								<>
									<thead className={isSticky ? 'edfmat_table_hidden' : ''} ref={myRefSizing}>
										<tr>{headingLables}</tr>
									</thead>
								</>
							)}

							{/* table body */}
							<tbody className={clickable ? 'edfmat_datatable--link' : ''}>{bodyContent}</tbody>
						</table>
					</>
				)}
			</div>
			<div>
				{/* pagination */}
				{pagination && pagination.total > pagination.pageSize && (
					<div className="edfmat_datatable--pagination edfmat_datatable--withpagination">
						<div className="u-pull-right">
							Showing {pagination.current * pagination.pageSize + 1} to{' '}
							{Math.min(
								pagination.total,
								Number(pagination.current * pagination.pageSize) + Number(pagination.pageSize)
							)}{' '}
							of {pagination.total} entries&nbsp;
							<input className="edfmat_button edfmat_button--sm" type="button" onClick={prev} value="Previous" />
							{pageButtons}
							<input className="edfmat_button edfmat_button--sm" type="button" onClick={next} value="Next" />
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default DataTable;
