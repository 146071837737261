import React from 'react';

import '../styling.scss';

import { Button } from '../index';

export interface NavProps {
	options: {
		label: string;
		action: Function;
		active?: boolean;
		href?: string;
		hover?: string;
	}[];
}

const TabBar = ({ options }: NavProps) => {
	const menuOptions = options.map((item, i) => {
		let action = (e: any) => {
			e.preventDefault();
			item.action();
		};

		//return <a key={i} className={"edfmat_button" + (item.active ? " edfmat_button--active" : "")} onClick={action} href={item.href}>{item.label} </a>
		return <Button key={i} label={item.label} action={action} active={item.active} />;
	});

	return (
		<div className="edfmat_tabbar">
			{/* flex */}
			<div className="edfmat_tabbar--container">{menuOptions}</div>
		</div>
	);
};

export default TabBar;
