import moment from 'moment';
import { EnergySale, EnergySaleUpdate, EnergySaleGSPCodeUpdate } from '../../widgets/types';
import UpdateSale from '../../widgets/data/updateSale';
import { SuccessFunc, FailFunc } from '../../widgets/data/saleCallBackFunctions';

function changeDateFormat(date: string) {
	if (date) {
		const momentDate = moment(date, 'YYYY-MM-DD');
		return momentDate.format('DDMMYYYY');
	}
	return date;
}

export const updateGSPCode = (
	energySaleGSPCodeUpdate: EnergySaleGSPCodeUpdate,
	id: number,
	successFunc: SuccessFunc,
	failFunc: FailFunc
) => {
	const energySaleUpdate = updateEnergySale(energySaleGSPCodeUpdate.derivedGSP, energySaleGSPCodeUpdate.energySale);
	UpdateSale(energySaleUpdate, id, successFunc, failFunc);
};

export const updateEnergySale = (derivedGSP: string, energySale: EnergySale): EnergySaleUpdate => ({
	energySaleId: energySale.energySaleId,
	multiSiteRef: energySale.multiSiteRef,
	businessPartnerId: energySale.businessPartnerId,
	organizationName1: energySale.organizationName1,
	organizationName2: energySale.organizationName2,
	addressFreeText: energySale.addressFreeText,
	addressBuildingNumber: energySale.addressBuildingNumber,
	addressDPS: energySale.addressDPS,
	addressSubBuilding: energySale.addressSubBuilding,
	addressBuildingName: energySale.addressBuildingName,
	addressStreet: energySale.addressStreet,
	addressThoroughfare: energySale.addressThoroughfare,
	addressLocality: energySale.addressLocality,
	addressDistrict: energySale.addressDistrict,
	addressTown: energySale.addressTown,
	addressCounty: energySale.addressCounty,
	addressPostcode: energySale.addressPostcode,
	addressPOBox: energySale.addressPOBox,
	addressCountry: energySale.addressCountry,
	bpType: energySale.bpType,
	dpaInternalComms: energySale.dpaInternalComms,
	dpaExternalComms: energySale.dpaExternalComms,
	dpaMail: energySale.dpaMail,
	dpaTelephone: energySale.dpaTelephone,
	dpaEmailSMS: energySale.dpaEmailSMS,
	dpaFaceToFace: energySale.dpaFaceToFace,
	preferredCallTimeFrom: energySale.preferredCallTimeFrom,
	preferredCallTimeTo: energySale.preferredCallTimeTo,
	billType: energySale.billType,
	bankAccountNumber: energySale.bankAccountNumber,
	bankAccountHolderName: energySale.bankAccountHolderName,
	bankSortCode: energySale.bankSortCode,
	telephoneNumber: energySale.telephoneNumber,
	faxNumber: energySale.faxNumber,
	mobileNumber: energySale.mobileNumber,
	email: energySale.email,
	soleTrader: energySale.soleTrader ? '1' : '0',
	partnership: energySale.partnership ? '1' : '0',
	plc: energySale.plc ? '1' : '0',
	ltd: energySale.ltd ? '1' : '0',
	other: energySale.other,
	businessRegNumber: energySale.businessRegNumber,
	tradingAs: energySale.tradingAs,
	vatNumber: energySale.vatNumber,
	sicCode: energySale.sicCode,
	contactTitle: energySale.contactTitle,
	contactFirstName: energySale.contactFirstName,
	contactLastName: energySale.contactLastName,
	contactDepartment: energySale.contactDepartment,
	contactFunction: energySale.contactFunction,
	contactTelephone: energySale.contactTelephone,
	contactMobile: energySale.contactMobile,
	contactFax: energySale.contactFax,
	contactEmail: energySale.contactEmail,
	brand: energySale.brand,
	paymentType: energySale.paymentType,
	billNotification: energySale.billNotification,
	readReminder: energySale.readReminder,
	supplyAddressFreeText: energySale.supplyAddressFreeText,
	supplyAddressPOBox: energySale.supplyAddressPOBox,
	supplyAddressBuildingNumber: energySale.supplyAddressBuildingNumber,
	supplyAddressSubBuilding: energySale.supplyAddressSubBuilding,
	supplyAddressBuildingName: energySale.supplyAddressBuildingName,
	supplyAddressStreet: energySale.supplyAddressStreet,
	supplyAddressThoroughfare: energySale.supplyAddressThoroughfare,
	supplyAddressLocality: energySale.supplyAddressLocality,
	supplyAddressDistrict: energySale.supplyAddressDistrict,
	supplyAddressTown: energySale.supplyAddressTown,
	supplyAddressCounty: energySale.supplyAddressCounty,
	supplyAddressPostcode: energySale.supplyAddressPostcode,
	supplyAddressDPS: energySale.supplyAddressDPS,
	supplyAddressCountry: energySale.supplyAddressCountry,
	mpxn: energySale.mpxn,
	product: energySale.product,
	tariff: energySale.tariff,
	contractReason: energySale.contractReason,
	signDate: changeDateFormat(energySale.signDate),
	channel: energySale.channel,
	paymentAmount: energySale.paymentAmount,
	externalReferenceNumber: energySale.externalReferenceNumber,
	salesRepresentative: energySale.salesRepresentative,
	campaignId: energySale.campaignId,
	preferredPaymentDate: changeDateFormat(energySale.preferredPaymentDate),
	ped: energySale.ped,
	promotionalCode1: energySale.promotionalCode1,
	promotionalCode2: energySale.promotionalCode2,
	promotionalCode3: energySale.promotionalCode3,
	promotionalCode4: energySale.promotionalCode4,
	promotionalCode5: energySale.promotionalCode5,
	creditScore: energySale.creditScore,
	svNumber: energySale.svNumber,
	urn: energySale.urn,
	inContract: energySale.inContract ? '1' : '0',
	oldContractEndDate: changeDateFormat(energySale.oldContractEndDate),
	existingSuplierId: energySale.existingSuplierId,
	annualConsumption: energySale.annualConsumption,
	profileClass: energySale.profileClass,
	meterTimeSwitchCode: energySale.meterTimeSwitchCode,
	lineLossFactor: energySale.lineLossFactor,
	letterOfAuthority: energySale.letterOfAuthority,
	commissionCost: Number(energySale.commissionCost).toFixed(2).toString(),
	salesChannelContractType: energySale.salesChannelContractType,
	adrNumber: energySale.adrNumber,
	derivedGSP: derivedGSP,
	updatedTs: energySale.updatedTs,
});
