import React, { useState } from 'react';

import { Loader } from '../../edfm';

import ReportTemplate from './reportTemplate';
import DateFormat from '../../widgets/dateFormat';
import { useStatusReport } from '../../widgets/data/loadStatusReport';

export interface rowOption {
	column: string;
	condition: string;
	value: string | number;
}

const FIELDS = [
	{ label: 'Date of sale', sortable: true },
	{ label: 'Date received', sortable: true },
	{ label: 'Sales EAC', sortable: true },
	{ label: 'SAP EAC', sortable: true },
	{ label: 'MPXN', sortable: true },
	{ label: 'Product code', sortable: true },
	{ label: 'Sales type', sortable: true },
	{ label: 'External ref', sortable: true },
	{ label: 'Business Name', sortable: true },
	{ label: 'Proposed SAP start date', sortable: true },
	{ label: 'Contract sold end date', sortable: true },
	{ label: 'Current status', sortable: true },
	{ label: 'Return Reason', sortable: true },
	{ label: 'Return Reason Detail', sortable: true },
	{ label: 'Objections', sortable: true },
	{ label: 'Latest objection date', sortable: true },
	{ label: 'COT date', sortable: true },
	{ label: 'Payment status', sortable: true },
	{ label: 'Status change date', sortable: true },
	{ label: 'Payment query ref', sortable: true },
	{ label: 'OK to chase', sortable: true },
	{ label: 'Last changed', sortable: true },
];

const FILTER_LABELS: { [key: string]: string } = {
	changed: 'Recently updated',
	objections: 'Recent objections',
	'in-flight': 'In-flight contracts',
	cots: 'COTs',
};

const processRow = (item: any) => {
	//Registration Final Status for > 32 days (based on EnergySaleStatusTs)
	const recentTimeStamp32 = new Date();
	recentTimeStamp32.setDate(recentTimeStamp32.getDate() - 32);
	const changeDate = new Date(item.EnergySaleStatusTs).getTime();
	const okToChase =
		['Registration Final', 'Switch Secured'].includes(item.contractStatus) && changeDate < recentTimeStamp32.getTime()
			? 'Y'
			: '';

	return {
		searchableText: `${item.business} ${item.MPXN} ${item.productCode} ${item.externalRef} ${item.paymentQueryRef}`,
		columns: [
			{
				display: <DateFormat date={item.signDate} />,
				sortVal: new Date(item.signDate).getTime(),
			},
			{
				display: <DateFormat date={item.EnergySaleCreatedTs} />,
				sortVal: new Date(item.signDate).getTime(),
			},
			item.salesEAC,
			item.currentIndustryEAC,
			item.mpxn,
			item.product,
			getSaleType(item),
			item.externalRef,
			item.business,
			{
				display: <DateFormat date={item.contractStartDate} />,
				sortVal: new Date(item.contractStartDate).getTime(),
			},
			{
				display:
					item.contractEndDate === '' || !item.contractEndDate ? '-' : <DateFormat date={item.contractEndDate} />,
				sortVal: item.contractEndDate === '' || !item.contractEndDate ? 0 : new Date(item.contractEndDate).getTime(),
			},
			item.contractStatus,
			getReturnReason(item),
			getReturnReasonDetail(item),
			item.objectionCount,
			{
				display: item.objectionDate === '' || !item.objectionDate ? '-' : <DateFormat date={item.objectionDate} />,
				sortVal:
					item.objectionDate === null || item.objectionDate === '' || !item.objectionDate
						? 0
						: new Date(item.objectionDate).getTime(),
			},
			{
				display: item.COTDate === '' || !item.COTDate ? '-' : <DateFormat date={item.COTDate} />,
				sortVal: item.COTDate === '' || !item.COTDate ? 0 : new Date(item.COTDate).getTime(),
			},
			item.paymentStatus,
			{
				display: <DateFormat date={item.EnergySaleStatusTs} />,
				sortVal: new Date(item.EnergySaleStatusTs).getTime(),
			},
			item.paymentQueryRef,
			okToChase,
			{
				display: <DateFormat date={item.EnergySaleDetailUpdatedTs} />,
				sortVal: new Date(item.EnergySaleDetailUpdatedTs).getTime(),
			},
		],
	};
};

const DailyReport = (props: { id: number }) => {
	const { id } = props;

	const [currentFilter, setCurrentFilter] = useState('all'); //keep track of any previously selected filters in the side panel
	const [selectedRows, setSelectedRows] = useState<rowOption[]>([]);

	const { report, error, loading } = useStatusReport(id);

	if (error) {
		return (
			<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
				An error occurred:
				<br />
				{error}
			</div>
		);
	}

	if (!report) {
		return (
			<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
				<Loader />
			</div>
		);
	}

	const currentDate = new Date();
	const exportTitle = `status-report_${currentDate.toISOString()}`;

	const tableData = {
		fields: FIELDS,
		data: report.map(processRow),
	};

	const overviewData = {
		date: currentDate,
		length: report.length,
		objections: report.filter((item: any) => item.objectionCount && item.objectionCount > 0).length,
		COTs: report.filter((item: any) => item.COTDate).length,
	};

	const filterOptionsArray = [
		{
			label: 'All records',
			current: currentFilter === 'all',
			action: () => {
				setCurrentFilter('all');
				setSelectedRows([]);
			},
		},

		{
			label: 'In-flight contracts (all contracts not finally reconciled)',
			current: currentFilter === 'in-flight',
			action: () => {
				setCurrentFilter('in-flight');
				setSelectedRows([{ column: 'Current status', condition: 'not', value: 'Final Payment Raised' }]);
			},
		},

		{
			label: 'Recently updated (last 90 days)',
			current: currentFilter === 'changed',
			action: () => {
				setCurrentFilter('changed');
				setSelectedRows([
					{ column: 'Last changed', condition: 'greater', value: currentDate.getTime() - 90 * 86400e3 },
				]);
			},
		},
		{
			label: 'Recent objections (last 30 days)',
			current: currentFilter === 'objections',
			action: () => {
				setCurrentFilter('objections');
				setSelectedRows([
					{ column: 'Objections', condition: 'greater', value: 0 },
					{ column: 'Latest objection date', condition: 'greater', value: currentDate.getTime() - 30 * 86400e3 },
				]);
			},
		},
		{
			label: 'COTs',
			current: currentFilter === 'cots',
			action: () => {
				setCurrentFilter('cots');
				setSelectedRows([{ column: 'COT date', condition: 'not', value: 0 }]);
			},
		},
	];

	const clearFilters = () => {
		setCurrentFilter('all');
		setSelectedRows([]);
	};

	return (
		<ReportTemplate
			id={id}
			overviewData={overviewData}
			report="status"
			data={tableData}
			exportTitle={exportTitle}
			filterOptionsArray={filterOptionsArray}
			filterLabel={FILTER_LABELS[currentFilter] || ''}
			selectedRows={selectedRows}
			currentFilter={currentFilter}
			clearFilters={clearFilters}
			loading={loading}
		/>
	);
};

export default DailyReport;

const getReturnReason = (report: any) => {
	return report.returnReason ? report.returnReason : '-';
};

const getReturnReasonDetail = (report: any) => {
	return report.returnReasonDetail ? report.returnReasonDetail : '-';
};

const getSaleType = (report: any) => {
	return report.saleType === 'ACQ' ? 'Acquisition' : 'Retention';
};
