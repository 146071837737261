import React from 'react';

import '../styling.scss';

export interface ButtonProps {
	label: string;
	action: any;
	active?: boolean;
	href?: string;
	hover?: string;
	size?: string;
	primary?: any;
	secondary?: any;
	fullwidth?: boolean;
	disabled?: boolean;
	datatestid?: string;
}

const Button = (props: ButtonProps) => {
	const {
		active,
		action,
		href,
		label,
		hover,
		size,
		primary,
		/*secondary,*/ fullwidth = false,
		disabled = false,
		datatestid,
	} = props; //currently no secondary button styling is in place, but leaving as a valid prop for now

	type sizeOptions = {
		[key: string]: string;
	};

	const sizeList: sizeOptions = {
		sm: 'edfmat_button--sm',
		md: '',
		lg: 'edfmat_button--lg',
	};
	const displayDatatestid = datatestid;

	return (
		<>
			{href === undefined ? (
				<div
					className={`edfmat_button ${primary ? 'edfmat_button--primary' : ''} ${
						disabled ? 'edfmat_button--disabled' : ''
					} ${fullwidth ? 'u-full-width' : ''} ${size && sizeList[size] ? sizeList[size] : ''} ${
						active ? ' edfmat_button--active' : ''
					}`}
					title={hover}
					onClick={action}
					data-testid={displayDatatestid}
				>
					{label}{' '}
				</div>
			) : (
				<a
					className={`edfmat_button" ${primary ? 'edfmat_button--primary' : ''} ${fullwidth ? 'u-full-width' : ''} ${
						size && sizeList[size] ? sizeList[size] : ''
					} ${active ? ' edfmat_button--active' : ''}`}
					title={hover}
					onClick={action}
					href={href}
				>
					{label}{' '}
				</a>
			)}
		</>
	);
};

export default Button;
