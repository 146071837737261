import React from 'react';
import Moment from 'react-moment';
//import 'moment-timezone';

export interface dateProps {
	date: Date | string;
}

const DateFormat = (props: dateProps) => {
	const { date } = props;

	return <Moment format="DD/MM/YYYY">{date}</Moment>;
};

export default DateFormat;
