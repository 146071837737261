import React from 'react';
import './SalesListItem.scss';

const SalesListHeader = () => (
	<div className="sales-list-item">
		<div className="col-medium">Organisation Name</div>
		<div className="col-medium">Status</div>
		<div className="col-short">Sign Date</div>
		<div className="col-short">Submitted Date</div>
		<div className="flex-column col-long">
			Product Name <br /> MPXN
		</div>
		<div className="flex-column col-short">
			Broker ID <br /> Postcode
		</div>
		<div className="buttons col-medium">Actions</div>
	</div>
);

export default SalesListHeader;
