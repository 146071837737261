import React from 'react';
import { WorkflowTransactions } from '../../../../../widgets/data/transactions/workflowTransactions.type';
import moment from 'moment';

const TransactionsTab = (props: { transactions: WorkflowTransactions }) => {
	console.log(props.transactions);
	if (props.transactions.error !== undefined) {
		return (
			<div className="flex row">
				<div className="flex-one">
					<p>Transactions could not be loaded. Refresh the page and try again.</p>
				</div>
			</div>
		);
	}

	if (props.transactions.transactions.length === 0) {
		return (
			<div className="flex row">
				<div className="flex-one">
					<p>This energy sale does not have any transactions yet.</p>
				</div>
			</div>
		);
	}

	return (
		<div className="flex row">
			<div className="flex-one">
				<table className="u-full-width edfmat_table">
					<thead>
						<tr>
							<th>Date</th>
							<th>Stage</th>
							<th>Type</th>
							<th>Amount</th>
							<th>EAC</th>
							<th>Description</th>
							<th>Statement Reference Number</th>
						</tr>
					</thead>
					<tbody>
						{props.transactions.transactions.map((tx: any) => {
							const commissionStatementLink = `/reports/${tx.tpiid}/commission-statement/${tx.commissionstatementid}`;
							return (
								<tr key={tx.financialtxid}>
									<td>{moment(tx.txdate).format('DD/MM/YYYY')}</td>
									<td>{tx.txstage}</td>
									<td>{tx.txtype}</td>
									<td>{tx.txvalue.toFixed(2)}</td>
									<td>{tx.txeac}</td>
									<td>{tx.txshortdescription}</td>
									<td>
										{tx.statementreferencenumber && <a href={commissionStatementLink}>{tx.statementreferencenumber}</a>}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<h5>Total: {props.transactions.total.toFixed(2)}</h5>
			</div>
		</div>
	);
};

export default TransactionsTab;
