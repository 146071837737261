import React from 'react';

import '../styling.scss';

import { Button } from '../index';
import useACL, { Feature } from '../../widgets/acl';

export interface NavProps {
	options: {
		label: string;
		action: Function;
		active?: boolean;
		href?: string;
		hover?: string;
		feature?: Feature;
	}[];
	fixed?: boolean;
}

const NavBar = ({ options, fixed = false }: NavProps) => {
	const acl = useACL();

	const menuOptions = options.map((item, i) => {
		if (!item.href) {
			item.href = '';
		}

		let action = (e: any) => {
			if (item.action) {
				e.preventDefault();
				item.action();
			}
		};

		if (item.feature && !acl(item.feature)) {
			return false;
		}
		//return <a key={i} className={"edfmat_button" + (item.active ? " edfmat_button--active" : "")} onClick={action} href={item.href}>{item.label} </a>
		return <Button key={i} label={item.label} size="lg" action={action} active={item.active} />;
	});

	const styles: string = fixed ? ' edfmat_navbar--fixed' : '';

	return (
		<div className={'edfmat_navbar' + styles}>
			<div className="TellerName">
				<strong>Teller |</strong>
			</div>
			<div className="menu">{menuOptions}</div>
			<div className="edf-logo">
				<img src="/edf-logo.png" alt="edf logo" />
				<span>Helping Britain Achieve Net Zero</span>
			</div>
		</div>
	);
};

export default NavBar;
