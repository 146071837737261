import React from 'react';
import { brokerDataProps } from '../../../widgets/types';
import { Row } from '../../../edfm';
import { LoadBroker } from '../../../widgets/data';
import useACL from '../../../widgets/acl';
import { Feature } from '../../../widgets/acl';

const NoTermsFound = (props: { brokerData: brokerDataProps; acquisition?: boolean; id: number; action: any }) => {
	//stub component to house "no terms found" message and also reload brokerData - in-case terms just created and brokerData cahe busted

	const { brokerData, acquisition, id, action } = props;
	const acl = useACL();

	LoadBroker(id);

	return (
		<Row>
			<p>
				No <strong>{acquisition ? 'Acquisition' : 'Retention'}</strong> Commission terms set for{' '}
				{brokerData.description}.{' '}
				{!acl(Feature.ReadOnly) && (
					<span className="inline_linktext" onClick={action}>
						Create commission terms
					</span>
				)}
			</p>
		</Row>
	);
};

export default NoTermsFound;
