import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import IsEqual from '../../widgets/isEqual';
import { Modal, Button, DataTable, PStyle } from '../../edfm';
import { URLParams } from '../../widgets/types';
import { LoadBroker } from '../../widgets/data';
import DateFormat from '../../widgets/dateFormat';
import { CommissionTerm } from '../../widgets/data/commissionTerms/commissionTerm.type';

const CommissionStatementOverview = (props: {
	overviewData: { description: string; length: number; total: number; purchaseOrderReference: string | null };
}) => {
	const [statementsData, setStatementsData]: [any, any] = useState(); //create interface type - TODO
	const [modalBody, setModalBody]: [any, any] = useState();
	const [hideModal, setHideModal] = useState(true);
	const [paymentTerms, setPaymentTerms]: [CommissionTerm[] | undefined, any] = useState();

	const { overviewData } = props;

	const history = useHistory();
	const dispatch = useDispatch();

	const { id } = useParams<URLParams>();

	LoadBroker(Number(id)); //to get commission term object and show current payment terms and effective dates

	const dataStore = (input: any) => {
		if (input.statementsData) {
			if (!statementsData || !IsEqual({ ...statementsData }, { ...input.statementsData })) {
				setStatementsData(input.statementsData);
			}
		}

		if (input.brokerData && (!paymentTerms || !IsEqual({ ...input.brokerData.CommissionTerms }, { ...paymentTerms }))) {
			if (input.brokerData.tpiId === Number(id)) {
				setPaymentTerms(input.brokerData.CommissionTerms);
			}
		}
	};

	useSelector(dataStore);

	useEffect(() => {
		if (statementsData) {
			//select a different report - update url and clear existing report data
			const selectReportHandler = (statementId: number) => {
				//clear existing data to get fresh component with uncached data
				dispatch({ type: 'statementData', data: undefined });

				history.push(`/reports/${id}/commission-statement/${statementId}`);
			};

			const body = statementsData.map((item: any) => {
				const { tpiCommissionStatementId } = item;

				return {
					searchableText: item.description,
					clickAction: (): void => {
						selectReportHandler(tpiCommissionStatementId);
						setHideModal(true);
					},
					columns: [<span>{item.description}</span>],
				};
			});

			setModalBody(<DataTable body={body} clickable={true} search={true} pagination={true} />);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statementsData]);

	return (
		<>
			<Modal
				hide={hideModal}
				title="Select Commission statement report"
				customClose={() => setHideModal(true)}
				content={modalBody}
				close={true}
			/>

			<PStyle>
				<strong>{overviewData.description}</strong>{' '}
				<Button label="Change report" secondary size="sm" action={() => setHideModal(false)} />
				<br />
				<strong>Number of records:</strong> {overviewData.length}
				<br />
				<strong>Total for invoicing:</strong> £{overviewData.total ? overviewData.total.toFixed(2) : 0.0}
				<br />
				{overviewData.purchaseOrderReference !== null && overviewData.purchaseOrderReference !== '' && (
					<>
						<strong>PO for raising invoice:</strong> {overviewData.purchaseOrderReference}
					</>
				)}
			</PStyle>

			{/* display payment terms based on commission terms for broker */}
			{paymentTerms && (
				<PStyle>
					<strong>Payment terms:</strong>
					<br />

					{(() => {
						const acq = paymentTerms.find((item: CommissionTerm) => item.saleType === 'ACQ');

						if (acq) {
							let retVar = [
								<span key="Acquisitions">
									Acquisitions ({<em>Effective date {<DateFormat date={acq.effectiveDate} />}</em>}):
									<br />
								</span>,
							];

							const acceptPC = payablePercentageOrZero(acq.acceptPc);
							if (acceptPC > 0) {
								retVar.push(
									<span key="acceptPC">
										{`Receive ${acceptPC}% of commission due based on the Sales EAC once the contract has been accepted.`}
										<br />
									</span>
								);
							}

							const livePc = payablePercentageOrZero(acq.livePc);
							if (livePc > 0) {
								retVar.push(
									<span key="livePc">
										{`Receive ${livePc}% of commission due based on the Industry EAC once the account status is Live.`}
										<br />
									</span>
								);
							}

							const finalPc = payablePercentageOrZero(acq.finalPc);
							if (finalPc > 0) {
								retVar.push(
									<span key="finalPc">
										{`Receive ${finalPc}% of commission due based on the Account EAC once the contract Final percentage date is reached.`}
										<br />
									</span>
								);
							}

							return <p>{retVar}</p>;
						}
					})()}

					{(() => {
						const ret = paymentTerms.find((item: CommissionTerm) => item.saleType === 'RET');

						if (ret) {
							let retVar = [
								<span key="Retentions">
									Retentions ({<em>Effective date {<DateFormat date={ret.effectiveDate} />}</em>}):
									<br />
								</span>,
							];

							const acceptPC = payablePercentageOrZero(ret.acceptPc);
							if (acceptPC > 0) {
								retVar.push(
									<span key="acceptPC">
										{`Receive ${acceptPC}% of commission due based on the Account EAC once the contract has been accepted.`}
										<br />
									</span>
								);
							}

							const livePc = payablePercentageOrZero(ret.livePc);
							if (livePc > 0) {
								retVar.push(
									<span key="livePc">
										{`Receive ${livePc}% of commission due based on the Account EAC once the account status is Live.`}
										<br />
									</span>
								);
							}

							const finalPc = payablePercentageOrZero(ret.finalPc);
							if (finalPc > 0) {
								retVar.push(
									<span key="finalPc">
										{`Receive ${finalPc}% of commission due based on the Account EAC once the contract Final percentage date is reached.`}
										<br />
									</span>
								);
							}

							return retVar;
						}
					})()}
				</PStyle>
			)}
		</>
	);
};

export default CommissionStatementOverview;

const payablePercentageOrZero = (payablePercentage: number | null | undefined) => {
	return payablePercentage ? payablePercentage : 0;
};
