import React, { useState, useEffect } from 'react';

import { Loader } from '../../edfm';

import { LoadCommissionStatements } from '../../widgets/data';
import { useSelector } from 'react-redux';
import CommissionStatement from './commissionStatement';
import IsEqual from '../../widgets/isEqual';

const CommissionStatementHolder = (props: { id: number; statementId?: number }) => {
	const { id, statementId } = props;

	const [statementsData, setStatementsData]: [any, any] = useState(); //create interface type - TODO
	const [currentStatement, setCurrentStatement]: [number | undefined, any] = useState();
	const [nextStatement, setNextStatement]: [number | undefined, any] = useState();
	const [noStatements, setNoStatements] = useState(false);

	LoadCommissionStatements(id);

	const dataStore = (input: any) => {
		if (isStatementDataEqualToExisting(input.statementsData, statementsData)) {
			setStatementsData(input.statementsData);
		}
	};

	useSelector(dataStore);

	useEffect(() => {
		if (!statementsData) {
			return;
		}

		setStatementId(statementsData, statementId, id, setCurrentStatement, setNoStatements, setNextStatement);
	}, [statementsData, statementId, id]);

	useEffect(() => {
		if (noStatements) {
			setCurrentStatement(0);
		}
	}, [noStatements]);

	useEffect(() => {
		if (nextStatement && !currentStatement) {
			setCurrentStatement(nextStatement);
		}
	}, [nextStatement, currentStatement]);

	return (
		<>
			{currentStatement === undefined ? (
				<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
					<Loader />
				</div>
			) : noStatements ? (
				<p>No Commission statements available</p>
			) : (
				<CommissionStatement id={id} statementId={currentStatement} statementsData={statementsData} />
			)}
		</>
	);
};

export default CommissionStatementHolder;

const doCommissionStatementsBelongToBroker = (statements: any, brokerId: number): boolean => {
	return statements.length > 0 && statements[0].tpiId === brokerId;
};

const doesStatementIdExist = (statementId: number | undefined): boolean => {
	return statementId !== undefined && !isNaN(statementId);
};

const isStatementDataEqualToExisting = (input: any, existing: any): boolean => {
	return input && (!existing || !IsEqual({ ...existing }, { ...input }));
};

const setStatementId = (
	statementsData: any,
	statementId: number | undefined,
	brokerId: any,
	setCurrentStatement: any,
	setNoStatements: any,
	setNextStatement: any
): void => {
	if (doesStatementIdExist(statementId)) {
		setCurrentStatement(undefined);
		setNoStatements(false);
		setNextStatement(statementId);
	} else if (doCommissionStatementsBelongToBroker(statementsData, brokerId)) {
		setNoStatements(false);
		setCurrentStatement(statementsData[0].tpiCommissionStatementId);
	} else {
		setNoStatements(true);
	}
};
