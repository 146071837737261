import React from 'react';

import '../styling.scss';

export interface ColumnProps {
	styles?: string;
	children?: any;
	columns: number;
}

const Column = (props: ColumnProps) => {
	const { styles, children, columns } = props;

	type columnOptions = {
		[key: string]: string;
	};

	const columnRef: columnOptions = {
		c1: 'one',
		c2: 'two',
		c3: 'three',
		c4: 'four',
		c5: 'five',
		c6: 'six',
		c7: 'seven',
		c8: 'eight',
		c9: 'nine',
		c10: 'ten',
		c11: 'eleven',
		c12: 'twelve',
	};

	return <div className={`edfmat_columns ${columnRef[`c${columns}`]} ${styles}`}>{children}</div>;
};

export default Column;
