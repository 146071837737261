import React from 'react';
import { EnergySale } from '../../../widgets/types';
import BillingTab from './BillingTab';
import LogTab from './LogTab';
import SupplyTab from './SupplyTab';
import TransactionsTab from './workflow-tabs/transactions/TransactionsTab';
import { WorkflowTransactions } from '../../../widgets/data/transactions/workflowTransactions.type';

const WorkflowDetailTab = (props: {
	tabName: string;
	sale: EnergySale;
	returnSale: (id: number, previousStatus: string, updatedTs: string) => void;
	unreturnSale: (id: number) => void;
	gsp: (id: number, sale: EnergySale) => void;
	transactions: WorkflowTransactions;
}) => (
	<div className="edfm_tab_item" id={props.tabName}>
		{(() => {
			let component;
			switch (props.tabName) {
				case 'supply':
					component = <SupplyTab sale={props.sale} />;
					break;
				case 'billing':
					component = <BillingTab sale={props.sale} />;
					break;
				case 'log':
					component = (
						<LogTab sale={props.sale} returnSale={props.returnSale} unreturnSale={props.unreturnSale} gsp={props.gsp} />
					);
					break;
				case 'transactions':
					component = <TransactionsTab transactions={props.transactions} />;
					break;
			}
			return component;
		})()}
	</div>
);

export default WorkflowDetailTab;
