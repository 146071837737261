import { useDispatch, useSelector } from 'react-redux';
import { ApiPath } from '../index';
import { API } from '@edfenergy/res-agentui-sso-auth';

export const LoadCommissionTerms = (brokerId: number, forceLoad: boolean = false) => {
	const dispatch = useDispatch();

	const checkStore = (input: any) => {
		return input.commissionTermsData;
	};

	const existingCommissionTerms: any = useSelector(checkStore);

	const apiLanded = (): void => {
		if (forceLoad) {
			fetchCommissionTerms();
		} else if (!existingCommissionTerms || Number(existingCommissionTerms.brokerId) !== Number(brokerId)) {
			fetchCommissionTerms();
		}
	};

	ApiPath(apiLanded);

	const fetchCommissionTerms = () => {
		dispatch({ type: 'commissionTermsData', data: undefined });

		API.get('api', `/v1/tpi/${brokerId}/commission-terms`, {}).then(
			(result) => {
				dispatch({ type: 'commissionTermsData', data: { ...result, brokerId } });
			},
			(error) => {
				dispatch({ type: 'commissionTermsData', data: { error, commissionTerms: [], brokerId } });
			}
		);
	};
};
