import { API } from '@edfenergy/res-agentui-sso-auth';
import { SuccessFunc, FailFunc } from './saleCallBackFunctions';

const UpdateSale = (body: any, id: number, successFunc: SuccessFunc, failFunc: FailFunc) => {
	const errorHandler = (error: string) => {
		failFunc(error);
		console.warn(error);
	};

	API.put('api', `/energy-sale/${id}`, { body })
		.then((result) => {
			if (result.result && result.result.error) {
				errorHandler('We could not update the GSP code due to an issue: ' + result.result.error);
			} else if (result.result && result.result.message) {
				errorHandler('We could not update the GSP code due to an issue: ' + result.result.message);
			} else if (result && result.error) {
				errorHandler('We could not update the GSP code due to an issue: ' + result.error);
			} else if (result && result.message) {
				errorHandler('We could not update the GSP code due to an issue: ' + result.message);
			} else {
				successFunc();
			}
		})
		.catch((error) => {
			if (error.response) {
				//handle error
				errorHandler('We could not update the GSP code due to an issue: ' + error.response.data.error);
			} else {
				errorHandler('We could not update the GSP code due to an issue.');
			}
		});
};

export default UpdateSale;
