import React, { useState, useEffect } from 'react';
import { Loader, DataTable, NoAccess } from '../../../edfm';
import { useHistory } from 'react-router-dom';
import { LoadBrokers } from '../../../widgets/data';
import { useSelector } from 'react-redux';
import IsEqual from '../../../widgets/isEqual';
import { brokerDataProps } from '../../../widgets/types';
import useACL, { Feature } from '../../../widgets/acl';

const BrokerList = () => {
	const history = useHistory();

	const [brokersData, setBrokersData]: [Array<brokerDataProps> | undefined, any] = useState();
	const [brokerDataMapped, setBrokerDataMapped]: [Array<any> | undefined, any] = useState();
	const [refData, setRefData]: [Array<any> | undefined, any] = useState();

	const clickHandler = (id: number) => {
		history.push(`/edit-broker/${id}`);
	};

	const addNew = () => {
		history.push(`/add-broker`);
	};

	const dataStore = (input: any) => {
		if (input.brokersData && (!brokersData || !IsEqual(input.brokersData, brokersData))) {
			setBrokersData(input.brokersData);
		}

		if (input.refData && (!refData || !IsEqual(input.refData, refData))) {
			setRefData(input.refData);
		}
	};

	useEffect(() => {
		if (refData && brokersData) {
			//cross reference status, statusRepFreq and commissionStmtFreq with reference data to get the display values - TODO
			let repFreq: Array<{ id: string; label: string }> = [];
			let stateFreq: Array<{ id: string; label: string }> = [];
			let statuses: Array<{ id: string; label: string }> = [];

			for (let i = 0; i < refData.length; i++) {
				const dataMap = refData[i].map((item: any) => {
					const { code, description } = item;
					return { id: code, label: description };
				});

				if (refData[i][0].refDataCategory === 'CommissionStmtFreq') {
					stateFreq = dataMap;
				} else if (refData[i][0].refDataCategory === 'StatusRepFreq') {
					repFreq = dataMap;
				} else if (refData[i][0].refDataCategory === 'TPIStatus') {
					statuses = dataMap;
				}
			}

			const mapped = brokersData.map((item: brokerDataProps, i: number) => {
				const { description, tpiBusinessKey, status, statusRepFreq, commissionStmtFreq, tpiId } = item;

				const stat = statuses.find((elem: { id: string; label: string }) => elem.id === status)?.label;
				const rep = repFreq.find((elem: { id: string; label: string }) => elem.id === statusRepFreq)?.label;
				const state = stateFreq.find((elem: { id: string; label: string }) => elem.id === commissionStmtFreq)?.label;

				return {
					clickAction: (): void => {
						tpiId !== undefined && clickHandler(tpiId);
					},
					columns: [description, tpiBusinessKey, stat, rep, state],
				};
			});

			setBrokerDataMapped(mapped);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refData, brokersData]);

	useSelector(dataStore);

	LoadBrokers();

	const acl = useACL();

	if (!acl(Feature.ViewBroker)) return <NoAccess />;

	return (
		<div className="container">
			<div className="row">
				<div className="twelve column">
					{!brokerDataMapped ? (
						<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
							<Loader />
						</div>
					) : (
						<div className="list-broker--container">
							<DataTable
								headings={[
									{ label: 'Name' },
									{ label: 'Ref' },
									{ label: 'Status' },
									{ label: 'Status reports' },
									{ label: 'Statement reports' },
								]}
								body={brokerDataMapped}
								clickable={true}
								search={true}
								pagination={true}
							/>
							{acl(Feature.EditBroker) && (
								<input
									className={`edfmat_button edfmat_button--sm list-broker--addnew`}
									type="button"
									onClick={addNew}
									value="+ new broker"
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default BrokerList;
