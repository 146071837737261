import React from 'react';
import { Row, Column, Button, PStyle } from '../../../edfm';
import { brokerDataProps, URLParams } from '../../../widgets/types';
import { useParams } from 'react-router-dom';
import { LoadBroker } from '../../../widgets/data';
import useACL, { Feature } from '../../../widgets/acl';
import moment from 'moment';
import { CommissionTerm } from '../../../widgets/data/commissionTerms/commissionTerm.type';

const ViewCommissionTermsDetails = (props: {
	brokerData: brokerDataProps;
	commissionData: CommissionTerm;
	edit: any;
	effectiveDates: string[];
	changeCommissionTerms: Function;
	currentCommissionTerms: CommissionTerm;
}) => {
	const { brokerData, commissionData, edit, effectiveDates, changeCommissionTerms, currentCommissionTerms } = props;

	const { id } = useParams<URLParams>();
	LoadBroker(Number(id));

	const acl = useACL();

	const viewDetail = brokerData?.commissionType === undefined || brokerData.commissionType !== 'TIERED';

	return (
		<>
			<Row>
				<Column columns={6}>
					<p>
						<strong>Commission type:</strong> {brokerData.commissionType}
					</p>

					{viewDetail && (
						<>
							<p>
								<strong>Effective date:</strong>
								<select
									defaultValue={commissionData.effectiveDate}
									onChange={(e) => changeCommissionTerms(commissionData.saleType, e.target.value)}
								>
									{effectiveDates.map((ed: string) => (
										<option key={ed} value={ed}>
											{moment(ed).format('DD/MM/YYYY')}{' '}
											{ed === currentCommissionTerms.effectiveDate && ' (Current Terms)'}
										</option>
									))}
								</select>
							</p>

							<p>
								<strong>Commission payment on acceptance:</strong>{' '}
								{commissionData.acceptPc !== undefined && commissionData.acceptPc !== null
									? commissionData.acceptPc + '%'
									: 'not set'}
								<br />
								<strong>Commission payment on go live:</strong>{' '}
								{commissionData.livePc !== undefined && commissionData.livePc !== null
									? commissionData.livePc + '%'
									: 'not set'}
								<br />
								<strong>Commission payment on final:</strong>{' '}
								{commissionData.finalPc !== undefined && commissionData.finalPc !== null
									? commissionData.finalPc + '%'
									: 'not set'}
								<br />
								<strong>Commission finalisation period:</strong>{' '}
								{commissionData.finalPcPeriodDays ? commissionData.finalPcPeriodDays + ' days' : 'not set'}
							</p>

							<p>
								<strong>Incentive fee (gas):</strong>{' '}
								{commissionData.incentiveGasFee !== undefined && commissionData.incentiveGasFee !== null
									? '£' + commissionData.incentiveGasFee
									: 'not set'}
								<br />
								<strong>Incentive fee (electricity):</strong>{' '}
								{commissionData.incentiveElecFee !== undefined && commissionData.incentiveElecFee !== null
									? '£' + commissionData.incentiveElecFee
									: 'not set'}
								{(Number(commissionData.incentiveGasFee) > 0 || Number(commissionData.incentiveElecFee) > 0) && (
									<>
										<br />
										<strong>Incentive payment on acceptance:</strong>{' '}
										{commissionData.acceptIncentPc !== undefined && commissionData.acceptIncentPc !== null
											? commissionData.acceptIncentPc + '%'
											: 'not set'}
										<br />
										<strong>Incentive payment on go live:</strong>{' '}
										{commissionData.liveIncentPc !== undefined && commissionData.liveIncentPc !== null
											? commissionData.liveIncentPc + '%'
											: 'not set'}
										<br />
										<strong>Incentive payment on final:</strong>{' '}
										{commissionData.finalIncentPc !== undefined && commissionData.finalIncentPc !== null
											? commissionData.finalIncentPc + '%'
											: 'not set'}
										<br />
									</>
								)}
							</p>

							<p>
								<strong>Payment cap:</strong>{' '}
								{commissionData.paymentCap !== undefined && commissionData.paymentCap !== null
									? '£' + commissionData.paymentCap
									: 'not set'}
								<br />
								<strong>Percentage payable:</strong>{' '}
								{commissionData.payablePc !== undefined && commissionData.payablePc !== null
									? commissionData.payablePc + '%'
									: 'not set'}
								<br />
								<strong>Clawback structure:</strong> {commissionData.clawbackStructure}
								<br />
								<strong>Payment term:</strong> {commissionData.termDays} days
							</p>
							{commissionData.isLegacy && (
								<p>
									<strong>Legacy payment terms in place</strong>
								</p>
							)}
						</>
					)}
				</Column>

				<Column columns={6}></Column>
			</Row>

			{viewDetail && acl(Feature.EditBroker) && (
				<Row>
					<PStyle className="u-pull-right">
						<Button action={edit} label="Edit commission term" />
					</PStyle>
				</Row>
			)}
		</>
	);
};

export default ViewCommissionTermsDetails;
