import { API } from '@edfenergy/res-agentui-sso-auth';
import { EnergySale } from '../types';

interface loadSalesProps {
	sort?: string | null;
	statusFilter?: string[] | null;
	brokerFilter?: number | null;
	signDateEnd?: number | null;
	mpxnFilter?: string | null;
	page?: number;
	pageSize?: number | null;
}

interface loadSalesReturn {
	sales: EnergySale[];
	pages?: number;
	error?: any;
}

/**
 * load a page of energy sales from the API
 * @param sort a sort order (e.g. 'DateASC')
 * @param statusFilter an array of energy sale statuses (e.g. 'LIVED19')
 * @param brokerFilter a broker id number
 * @param signDateEnd number of days old the contract must be
 * @param mpxnFilter an mpxn to search for
 * @param page what page of data to get
 * @param pageSze amount of rows to get
 * @returns an array of energy sales and the number of pages
 */
const loadSales = async ({
	sort = null,
	statusFilter = null,
	brokerFilter = null,
	signDateEnd = null,
	mpxnFilter = null,
	page = 0,
	pageSize = null,
}: loadSalesProps): Promise<loadSalesReturn> => {
	const params = new URLSearchParams({ page: page.toString() });
	if (sort !== null) {
		params.set('sort', sort);
	}
	if (brokerFilter !== null) {
		params.set('broker', brokerFilter.toString());
	}
	if (signDateEnd !== null) {
		params.set('signDateEnd', signDateEnd.toString());
	}
	if (statusFilter && statusFilter.length) {
		params.set('status', statusFilter.join(','));
	}
	if (mpxnFilter !== null) {
		params.set('mpxn', mpxnFilter);
	}
	if (pageSize !== null) {
		params.set('pageSize', pageSize.toString());
	}

	try {
		const result = await API.get('api', `/energy-sale?${params.toString()}`, {});
		return {
			sales: result.result,
			pages: result.pages,
		};
	} catch (err) {
		console.warn(err);
		return {
			sales: [],
			error: err,
		};
	}
};

export default loadSales;
