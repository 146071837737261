import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { NoAccess, TabBar } from '../../edfm';
import EditBroker from './brokerDetails/editBroker';
import EditCommissionTerms from './commissionTerms/editCommissionTerms';
import BrokerContacts from './contacts/listBrokerContacts';
import EditBrokerContact from './contacts/editBrokerContact';
import AddBrokerContact from './contacts/addBrokerContact';
import BrokerReports from './reports/brokerReports';

import { LoadBroker, LoadAgents, LoadContracts } from '../../widgets/data/';
import useACL, { Feature } from '../../widgets/acl';

const Broker = () => {
	const { id, section, mode, contactId } = useParams() as any; // TODO: change any with the expected types.

	const [error, setError]: [boolean | string, any] = useState(false);

	const history = useHistory();

	const updateSection = (newSection: string): void => {
		history.push(newSection);
	};

	function displayError(error: any) {
		setError(error);
	}

	LoadBroker(Number(id), false, displayError);

	LoadAgents();
	LoadContracts();

	const acl = useACL();
	if (!acl(Feature.ViewBroker)) return <NoAccess />;

	return (
		<>
			{error === false ? (
				<>
					<TabBar
						options={[
							{
								label: 'Broker',
								action: () => {
									updateSection(`/edit-broker/${id}/broker`);
								},
								active: !section || section === 'broker',
							},
							{
								label: 'Commission Terms',
								action: () => {
									updateSection(`/edit-broker/${id}/commission`);
								},
								active: section === 'commission',
							},
							{
								label: 'Contacts',
								action: () => {
									updateSection(`/edit-broker/${id}/contacts`);
								},
								active: section === 'contacts',
							},
							{
								label: 'Reports',
								action: () => {
									updateSection(`/edit-broker/${id}/reports`);
								},
								active: section === 'reports',
							},
						]}
					/>

					{!section || section === 'broker' ? (
						<EditBroker />
					) : section === 'commission' ? (
						<EditCommissionTerms />
					) : section === 'contacts' ? (
						<>
							{mode === '' ? (
								<BrokerContacts />
							) : mode === 'edit-contact' && contactId ? (
								<EditBrokerContact />
							) : mode === 'add-contact' ? (
								<AddBrokerContact />
							) : (
								<BrokerContacts />
							)}
						</>
					) : section === 'reports' ? (
						<BrokerReports />
					) : (
						<p>not found</p>
					)}
				</>
			) : (
				<p>{error}</p>
			)}
		</>
	);
};

export default Broker;
