import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { NoAccess, Loader, Input, Button } from '../../edfm';
import useACL, { Feature } from '../../widgets/acl';
import LoadSale from '../../widgets/data/loadSale';
import ReturnEnergySale from '../../widgets/data/returnEnergySale';
import UnreturnEnergySale from '../../widgets/data/unreturnEnergySale';
import IsEqual from '../../widgets/isEqual';
import { useRefDataBackTranslate } from '../../widgets/refDataBackTranslate';
import { useRefDataTranslate } from '../../widgets/refDataTranslate';
import { useRefDataMap } from '../../widgets/referenceData';
import { EnergySale } from '../../widgets/types';
import WorkflowDetailTab from './ui-components/WorkflowDetailTab';

import './workflowDetail.scss';
import showGSPModal from './workflowGSPModal';
import showReturnModal from './workflowReturnModal';
import showUnreturnModal from './workflowUnreturnModal';
import { LoadTransactions } from '../../widgets/data/transactions/loadTransactions';
import { WorkflowTransactions } from '../../widgets/data/transactions/workflowTransactions.type';

/**
 * Disply the type of business depending on sale's data
 * @param sale the EnergySale
 * @returns a string displaying the type of Business
 */
export function businessTypeTransform(sale: EnergySale) {
	if (sale.soleTrader) return 'Sole Trader';
	if (sale.partnership) return 'Partnership';
	if (sale.plc) return 'Plc';
	if (sale.ltd) return 'Ltd';
	return sale.other;
}

/**
 * Display a phone number from the sale's data
 * @param sale sale the EnergySale
 * @returns a phone number, as a string
 */
export function telephoneTransform(sale: EnergySale) {
	if (sale.contactTelephone !== '') return sale.contactTelephone;
	if (sale.contactMobile !== '') return sale.contactMobile;
	if (sale.telephoneNumber !== '') return sale.telephoneNumber;
	if (sale.mobileNumber !== '') return sale.mobileNumber;
	else return '';
}

const WorkflowDetail = () => {
	// get sale ID from URL
	const { id } = useParams() as any;

	// local replica of sale data
	const [rawSaleData, setRawSaleData]: [EnergySale | undefined, any] = useState();
	const [transactions, setTransactions]: [WorkflowTransactions, any] = useState({
		transactions: [],
		total: 0,
		energySaleId: id,
	});

	// ui state, tab selector
	const [activeTabID, setActiveTabID] = useState('log');

	// location manipulation
	const history = useHistory();

	useSelector((state: { saleData: EnergySale; transactionData: WorkflowTransactions }) => {
		if (state.saleData && (!rawSaleData || !IsEqual(state.saleData, rawSaleData))) {
			setRawSaleData(state.saleData);
		}
		if (state.transactionData && (!transactions || !IsEqual(state.transactionData, transactions))) {
			setTransactions(state.transactionData);
		}
	});

	LoadSale(id);
	LoadTransactions(id);

	const acl = useACL();
	const refDataTranslate = useRefDataTranslate(); // ref deta translator (from code to description)
	const refData = useRefDataMap();
	const returnReasons = refData['ReturnReason'];

	const refDataBackTranslate = useRefDataBackTranslate();

	const returnSale = showReturnModal(returnReasons, ({ code, description, id, updatedTs, previousStatus }) => {
		ReturnEnergySale(
			{
				updatedTs,
				previousStatus,
				toStatus: 'FORRETURN',
				returnReason: refDataBackTranslate('ReturnReason', code),
				returnReasonDetail: refDataTranslate('ReturnReason', code) + ': ' + description,
			},
			id
		);
	});

	const Toast = Swal.mixin({
		toast: true,
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		width: '50em',
		customClass: {
			title: 'edfm_popup_font_content',
		},
	});

	const unreturnSuccessFunc = (): void => {
		Toast.fire({
			icon: 'success',
			title: 'Unreturn request submitted. Sale should be updated shortly.',
		}).then(function () {
			window.location.reload();
		});
	};
	const unreturnFailFunc = (error: string): void => {
		Toast.fire({
			icon: 'warning',
			title: 'Unreturn failed: ' + error,
			timer: 5000,
		});
	};

	const unreturnSale = showUnreturnModal(({ id }) => {
		UnreturnEnergySale(id, unreturnSuccessFunc, unreturnFailFunc);
	});

	const gspCodeUpdated = (body: any, id: number) => {
		console.log('energy id: ', id, ' is being updated with GSP code ', body.derivedGSP);
	};

	const gsp = showGSPModal(({ id, derivedGSP, rawSaleData }) => {
		gspCodeUpdated(
			{
				rawSaleData,
				derivedGSP,
			},
			id
		);
	});

	if (!acl(Feature.Workflow)) return <NoAccess />;

	// only used for UI/UX purposes, avoid labels to be displayed in place of an empty field
	function showBlankIfEmpty(aString: string) {
		return aString && aString !== 'NaN' ? aString : ' ';
	}

	return (
		<div>
			<h2>Sales Details (as received)</h2>
			<hr />
			<div className="workflow-detail row">
				<div className="flex-four">
					{!rawSaleData ? (
						<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
							<Loader />
						</div>
					) : (
						<div className="flex row">
							<div className="flex-one">
								<h5>Customer Details</h5>
								<ul>
									<li>
										<Input
											label="Organisation Name"
											fullWidth
											value={showBlankIfEmpty(rawSaleData.organizationName1)}
											disabled
										/>
									</li>
									<li>
										<Input label="Trading As" fullWidth value={showBlankIfEmpty(rawSaleData.tradingAs)} disabled />
									</li>
									<li>
										<Input
											label="Customer Title"
											fullWidth
											value={showBlankIfEmpty(refDataTranslate('Contact Person: Title', rawSaleData.contactTitle))}
											disabled
										/>
									</li>
									<li>
										<Input
											label="Customer First Name"
											fullWidth
											value={showBlankIfEmpty(rawSaleData.contactFirstName)}
											disabled
										/>
									</li>
									<li>
										<Input
											label="Customer Last Name"
											fullWidth
											value={showBlankIfEmpty(rawSaleData.contactLastName)}
											disabled
										/>
									</li>
									<li>
										<Input
											label="Department"
											fullWidth
											value={showBlankIfEmpty(refDataTranslate('Contact Person: Dpmt', rawSaleData.contactDepartment))}
											disabled
										/>
									</li>
									<li>
										<Input
											label="Function"
											fullWidth
											value={showBlankIfEmpty(
												refDataTranslate('Contact Person: Function', rawSaleData.contactFunction)
											)}
											disabled
										/>
									</li>
									<li>
										<Input
											label="Phone Number"
											fullWidth
											value={showBlankIfEmpty(telephoneTransform(rawSaleData))}
											disabled
										/>
									</li>
									<li>
										<Input
											label="Email"
											fullWidth
											value={showBlankIfEmpty(
												rawSaleData.contactEmail !== '' ? rawSaleData.contactEmail : rawSaleData.email
											)}
											disabled
										/>
									</li>
									<li>
										<Input label="Business Type" fullWidth value={businessTypeTransform(rawSaleData)} disabled />
									</li>
									<li>
										<Input
											label="Business Reg No."
											fullWidth
											value={showBlankIfEmpty(rawSaleData.businessRegNumber)}
											disabled
										/>
									</li>
								</ul>
							</div>

							<div className="flex-one">
								<h5>Contract Details</h5>
								<ul>
									<li>
										<Input label="Product" fullWidth value={showBlankIfEmpty(rawSaleData.product)} disabled />
									</li>
									<li>
										<Input
											label="Sign Date"
											fullWidth
											value={
												showBlankIfEmpty(rawSaleData.signDate) === ' '
													? ' '
													: moment(rawSaleData.signDate).format('DD/MM/YYYY')
											}
											disabled
										/>
									</li>
									<li>
										<Input
											label="Sign Type"
											fullWidth
											value={showBlankIfEmpty(refDataTranslate('Contract Reason', rawSaleData.contractReason))}
											disabled
										/>
									</li>
									<li>
										<Input
											label="Contract End Date"
											fullWidth
											value={
												showBlankIfEmpty(rawSaleData.oldContractEndDate) === ' '
													? ' '
													: moment(rawSaleData.oldContractEndDate).format('DD/MM/YYYY')
											}
											disabled
										/>
									</li>
									<li>
										<Input label="Credit Score" fullWidth value={showBlankIfEmpty(rawSaleData.creditScore)} disabled />
									</li>
									<li>
										<Input label="SIC code" fullWidth value={showBlankIfEmpty(rawSaleData.sicCode)} disabled />
									</li>
									<li>
										<Input
											label="Letter Of Authority"
											fullWidth
											value={showBlankIfEmpty(rawSaleData.letterOfAuthority)}
											disabled
										/>
									</li>
									<li>
										<Input
											label="Sales Channel Contract Type"
											fullWidth
											value={showBlankIfEmpty(rawSaleData.salesChannelContractType)}
											disabled
										/>
									</li>
								</ul>
								<h5>Broker</h5>
								<ul>
									<li>
										<Input label="Name" fullWidth value={showBlankIfEmpty(rawSaleData.salesRepresentative)} disabled />
									</li>
									<li>
										<Input
											label="Commission Cost"
											fullWidth
											value={showBlankIfEmpty(parseFloat(rawSaleData.commissionCost).toFixed(2).toString())}
											disabled
										/>
									</li>
									<li>
										<Input label="ADR Number" fullWidth value={showBlankIfEmpty(rawSaleData.adrNumber)} disabled />
									</li>
								</ul>
							</div>
						</div>
					)}
				</div>
				<div className="flex-eight">
					<div className="edfm_tab_container">
						<div className="edfm_tab_bar">
							<ul>
								<li onClick={() => setActiveTabID('log')} className={activeTabID === 'log' ? 'active' : ''}>
									<span>log</span>
								</li>
								<li onClick={() => setActiveTabID('supply')} className={activeTabID === 'supply' ? 'active' : ''}>
									<span>supply</span>
								</li>
								<li onClick={() => setActiveTabID('billing')} className={activeTabID === 'billing' ? 'active' : ''}>
									<span>billing</span>
								</li>
								<li
									onClick={() => setActiveTabID('transactions')}
									className={activeTabID === 'transactions' ? 'active' : ''}
								>
									<span>transactions</span>
								</li>
							</ul>
						</div>
						<div className="edfm_tabs">
							{rawSaleData && (
								<WorkflowDetailTab
									tabName={activeTabID}
									sale={rawSaleData}
									returnSale={returnSale}
									unreturnSale={unreturnSale}
									gsp={gsp}
									transactions={transactions}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<div>
				<Button label={'Back'} action={() => history.goBack()} />
			</div>
		</div>
	);
};

export default WorkflowDetail;
