import { API } from '@edfenergy/res-agentui-sso-auth';
import { useDispatch, useSelector } from 'react-redux';
import { ApiPath } from '.';
import { brokerDataProps } from '../types';

/**
 * a hook to return a list of brokers
 * @returns an array of brokers
 */
export const useBrokers = () => {
	LoadBrokers();

	const getBrokers = (state: { brokersData: brokerDataProps[] | undefined }) => state.brokersData;
	const brokers = useSelector(getBrokers);

	return brokers || [];
};

const LoadBrokers = (forceLoad: boolean = false) => {
	const dispatch = useDispatch();

	const getBrokers = (input: { brokersData: brokerDataProps[] }) => input.brokersData;
	const brokers = useSelector(getBrokers);

	const apiLanded = (apiData: any): void => {
		if (forceLoad || !brokers) {
			fetchBroker(apiData);
		}
	};

	ApiPath(apiLanded);

	const fetchBroker = (apiData: any) => {
		API.get('api', '/tpi', {}).then(
			(result) => dispatch({ type: 'brokersData', data: result.result }),
			(error) => console.warn(error)
		);
	};
};

export default LoadBrokers;
