/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export enum Feature {
	Base,
	ViewBroker,
	EditBroker,
	PriceLaunch,
	Workflow,
	Admin,
	Unreturn,
	ReadOnly,
}

const GROUPS = {
	'GBM-R-CUS-Teller-Base': [Feature.Workflow],
	'GBM-R-CUS-Teller-Broker': [
		Feature.Base,
		Feature.ViewBroker,
		Feature.EditBroker,
		Feature.Workflow,
		Feature.Admin,
		Feature.Unreturn,
	],
	'GBM-R-CUS-Teller-PriceLaunch': [Feature.Base, Feature.ViewBroker, Feature.PriceLaunch],
	'GBM-R-CUS-Teller-Admin': [
		Feature.Base,
		Feature.ViewBroker,
		Feature.EditBroker,
		Feature.PriceLaunch,
		Feature.Admin,
		Feature.Workflow,
		Feature.Unreturn,
	],
	'GBM-R-CUS-Teller-Developer': [
		Feature.Base,
		Feature.ViewBroker,
		Feature.EditBroker,
		Feature.PriceLaunch,
		Feature.Admin,
		Feature.Workflow,
		Feature.Unreturn,
	],
	'GBM-R-CUS-Teller-Registrations': [Feature.Base, Feature.ViewBroker, Feature.Workflow, Feature.Admin],
	'GBM-R-CUS-smeportal-dev-admin': [
		Feature.Base,
		Feature.ViewBroker,
		Feature.EditBroker,
		Feature.PriceLaunch,
		Feature.Admin,
		Feature.Workflow,
		Feature.Unreturn,
	],
	'GBM-R-CUS-Teller-ReadOnly': [
		Feature.Base,
		Feature.ViewBroker,
		Feature.PriceLaunch,
		Feature.Workflow,
		Feature.ReadOnly,
	],
};

/**
 * React hooks that can be used to restrict part of the frontend based on user profile
 *
 * Uses the `userProfile` object of the main reducer.
 * Returns a function that takes a Feature as parameter and returns a boolean.
 */
const useACL = () => {
	const [userProfile, setUserProfile] = useState({ groups: [], loggedIn: false });

	useSelector((store: any) => {
		if (store.userProfile && store.userProfile.loggedIn && !userProfile.loggedIn) {
			setUserProfile(store.userProfile);
		}
	});

	useEffect(() => {
		console.log('user profile changed to:', userProfile);
	}, [userProfile]);

	return (feature: Feature) => {
		const userGroupsList = userProfile.groups;

		const userGroupsFeatures = userGroupsList.map((aGroup) => GROUPS[aGroup]);

		let jointArray: any[] = [];

		userGroupsFeatures.forEach((array) => {
			jointArray = [...jointArray, ...array];
		});

		const userFeatures = Array.from(new Set([...jointArray]));
		return userFeatures.includes(feature);
	};
};

export default useACL;
