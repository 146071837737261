import { IAuthSession } from '../IAuthSession';
// import { CognitoUserSession } from 'amazon-cognito-identity-js';

//import Amplify from '@aws-amplify/core';
import { getCurrentSesssion } from './getCurrentSession';
// import { getSTSCredentials, ISTSCredentialsProps } from './getSTSCredentials';

export const getSTSCurrentUser = (isVerbose?: boolean /* stsProps: ISTSCredentialsProps */): Promise<IAuthSession> => {
	return new Promise((resolve, reject) => {
		getCurrentSesssion(isVerbose)
			.then((session) => {
				resolve({ session });
				/*
				getSTSCredentials(session as CognitoUserSession, stsProps).then((stsCredentials) => {
					Amplify.Credentials._credentials = { ...Amplify.Credentials._credentials, ...stsCredentials };
					resolve({ session, stsCredentials });
				});
				*/
			})
			.catch((err) => {
				reject(err);
			});
	});
};
