import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';

import { DataTable, Button, Chip } from '../../edfm';
import DailyStatusOverview from './dailyStatusOverview';
import CommissionStatmentOverview from './commissionStatementOverview';
import SidePanel from './sidePanel';
import FilterBody from './filterBody';
import exportCSVFile from '../../widgets/exportToCSV';
import QueryParams from '../../widgets/queryParams';
import { useHistory } from 'react-router-dom';

export interface rowOption {
	column: string;
	condition: string;
	value: string | number;
}

export interface TemplateProps {
	id: number;
	data: {
		fields: Array<{
			label: string;
			sortable: boolean;
		}>;
		data: Array<{
			searchableText?: string;
			columns: Array<any>;
		}>;
	};
	filterOptionsArray: Array<{
		label: string;
		current: boolean;
		action: any;
	}>;
	overviewData?: any;
	currentFilter: string;
	selectedRows: Array<rowOption>;
	filterLabel: string;
	clearFilters: any;
	report: string;
	exportTitle?: string;
	loading?: boolean;
}

const ReportTemplate = (props: TemplateProps) => {
	const {
		data,
		filterOptionsArray,
		currentFilter,
		filterLabel,
		selectedRows,
		clearFilters,
		report,
		overviewData,
		exportTitle,
		id,
		loading = false,
	} = props;

	const [displayColumns, setDisplayColumns]: [Array<boolean>, any] = useState([]);
	const [showFilterOptions, setShowFilterOptions] = useState(true);
	const [displayReport, setDisplayReport]: [Array<any>, any] = useState([]); //table body for screen after any columns / rows have been filtered out
	const [displayHeader, setDisplayHeader]: [Array<any>, any] = useState([]); //table header for screen after columns have been filtered
	const [panelOn, setPanelOn] = useState(false);
	const [detailsBody, setDetailsBody]: [Array<any>, any] = useState([]);

	const [statusReport, setStatusReport] = useState({
		fields: data.fields,
		data: data.data.map((item: any, i: number) => {
			return {
				...item,
				clickAction: (): void => {
					clickHandler(i);
				},
			};
		}),
	});

	const [selectedStatusReport, setSelectedStatusReport]: [Array<any>, any] = useState([...statusReport.data]);

	const history = useHistory();

	const updateSection = (newSection: string) => {
		history.push(newSection);
	};

	const download = QueryParams('download') === 'true';

	const clickHandler = (i: number) => {
		setDetailsBody(() => {
			let returnBody = [];
			for (let ii = 0; ii < statusReport.data[i].columns.length; ii++) {
				returnBody.push(
					<>
						<strong>{statusReport.fields[ii].label}:</strong>{' '}
						{!statusReport.data[i].columns[ii]
							? '-'
							: statusReport.data[i].columns[ii].display !== undefined
							? statusReport.data[i].columns[ii].display
							: statusReport.data[i].columns[ii]}{' '}
						<br />
					</>
				);
			}
			return returnBody;
		});

		setShowFilterOptions(false);
		setPanelOn(true);
	};

	//launch filter options side panel
	const filterOptions = () => {
		setShowFilterOptions(true);
		setPanelOn(true);
	};

	//show / hide a column in the data table - triggered from the filter options side panel
	const toggleColumn = (colNum: number) => {
		setDisplayColumns((oldVal: Array<boolean>) => {
			const newVal = [...oldVal];
			newVal[colNum] = newVal[colNum] ? false : true;
			return newVal;
		});
	};

	//export csv - purely front-end option - can be replaced with BE solution (which would also generate the same report to automatically e-mail)
	const downloadReport = (): void => {
		var fileTitle = exportTitle;

		const headers = data.fields.map((item: { label: string }, i: number) => {
			return item.label;
		});

		const rows = data.data.map((item: { columns: Array<any> }, i: number) => {
			return item.columns.map((innerItem: any) => {
				let displayValue = !innerItem ? '-' : innerItem.display ? innerItem.display : innerItem;

				const parseHTML = displayValue.props ? true : false;

				//as column content can be a string / number OR jsx (with buttons / hover state etc) - strip out inner html
				//note - this is not univerally functional and works because the only values set will match here - NOT ENFORCED via typescript!
				if (parseHTML) {
					const jsx = ReactDOMServer.renderToStaticMarkup(displayValue);
					displayValue = jsx.substring(jsx.indexOf('>') + 1, jsx.lastIndexOf('<'));
				}

				displayValue = displayValue.toString();

				//trim preceeding pound symbol if remaining value is a number to ease sorting / presentation in excel
				if (displayValue.substring(0, 1) === '£' && !isNaN(displayValue.substring(1))) {
					displayValue = displayValue.substring(1);
				}

				return displayValue;
			});
		});

		exportCSVFile(rows, fileTitle, headers);
	};

	useEffect(() => {
		//allow for columns to exist in the data and for csv export, but not be passed through to the datatable or column selection in the sidepanel - TODO

		setDisplayReport(() => {
			return selectedStatusReport.map((entry) => {
				return {
					clickAction: entry.clickAction,
					searchableText: entry.searchableText,
					columns: entry.columns.filter((item: any, i: number) => {
						return displayColumns[i] === true;
					}),
				};
			});
		});

		setDisplayHeader(() => {
			return statusReport.fields.filter((item: any, i) => {
				return displayColumns[i] === true;
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayColumns, selectedStatusReport]);

	useEffect(() => {
		setDisplayColumns(
			statusReport.fields.map((item: any) => {
				return item.hidden ? false : true;
			})
		);
	}, [statusReport]);

	useEffect(() => {
		setStatusReport({
			fields: data.fields,
			data: data.data.map((item: any, i: number) => {
				return {
					...item,
					clickAction: (): void => {
						clickHandler(i);
					},
				};
			}),
		});
		// adding `clickHandler`  here causes tests to hang forever.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);
	//remove any rows that do not match the specified filter
	//(ie to only show rows with a specific payment state or with greater than a certain amount of something)
	//array of filters is cumulative - final results must match *all* specified filters
	const filterRows = (filterRules: Array<rowOption>) => {
		let currentRows = [...statusReport.data];

		for (let i = 0; i < filterRules.length; i++) {
			const columnNum = statusReport.fields.findIndex((col: any) => {
				return col.label === filterRules[i].column;
			});

			currentRows = currentRows.filter((elem: any) => {
				//sortValue is used for example with dates or when the column content is an html element and not a string or number
				//it is therefore a better candidate for evaluating against
				const testValue =
					elem.columns[columnNum] === null || elem.columns[columnNum] === undefined
						? undefined
						: elem.columns[columnNum].sortVal !== undefined
						? elem.columns[columnNum].sortVal
						: elem.columns[columnNum];

				if (filterRules[i].condition === 'greater') {
					return testValue > filterRules[i].value;
				} else if (filterRules[i].condition === 'less') {
					return testValue < filterRules[i].value;
				} else if (filterRules[i].condition === 'equals') {
					// eslint-disable-next-line eqeqeq
					return testValue == filterRules[i].value;
				} else if (filterRules[i].condition === 'not') {
					// eslint-disable-next-line eqeqeq
					return testValue != filterRules[i].value;
				}
				return true;
			});
		}
		setSelectedStatusReport([...currentRows]);
	};

	useEffect(() => {
		filterRows(selectedRows);
		// adding `filterRows`  here causes tests to hang forever.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRows, statusReport]);

	//check for download request in query string and autodownload if there
	useEffect(() => {
		if (download) {
			downloadReport();
			updateSection(`/reports/${id}/status-report`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{report === 'commission' ? (
				<CommissionStatmentOverview overviewData={overviewData} />
			) : (
				report === 'status' && <DailyStatusOverview overviewData={overviewData} />
			)}

			<Button label="Download" secondary size="sm" action={downloadReport} />
			<Button label="Filter report" primary size="sm" action={filterOptions} />
			{currentFilter !== 'all' && <Chip showClose action={clearFilters} label={`Current filter: ${filterLabel}`} />}
			<br />
			<hr></hr>

			<DataTable
				headings={displayHeader}
				body={displayReport}
				clickable
				compressed
				search={true}
				sticky={true}
				slim={true}
				pagination={true}
				loading={loading}
			/>

			<SidePanel
				panelOn={panelOn}
				hide={() => {
					setPanelOn(false);
				}}
				body={
					showFilterOptions ? (
						<FilterBody
							allColumns={() =>
								setDisplayColumns(
									statusReport.fields.map((item: any) => {
										return item.hidden ? false : true;
									})
								)
							}
							filterOptions={filterOptionsArray}
							columnOptions={statusReport.fields.map((item: any, i: number) => {
								return {
									label: item.label,
									action: () => {
										toggleColumn(i);
									},
									value: displayColumns[i],
									hidden: item.hidden,
								};
							})}
						/>
					) : (
						[detailsBody]
					)
				}
			/>
		</>
	);
};

export default ReportTemplate;
