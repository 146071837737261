import React from 'react';
import DateFormat from '../../widgets/dateFormat';
import { Column, Row } from '../../edfm';

const DailyStatusOverview = (props: {
	overviewData: { date: any; length: number; objections: number; COTs: number };
}) => {
	const { overviewData } = props;

	return (
		<>
			<p>
				<strong>Report generated:</strong> {<DateFormat date={overviewData.date} />} -{' '}
				{`${overviewData.date.getHours()}:${('0' + overviewData.date.getMinutes()).slice(-2)}`}
				<br />
			</p>

			<p>
				<strong>Number of records:</strong> {overviewData.length}
				<br />
			</p>

			<p>
				<strong>Accounts with objections:</strong> {overviewData.objections}
			</p>

			<p>
				<strong>COTs:</strong> {overviewData.COTs}
			</p>

			{/* FAQ info */}
			<Row>
				<Column columns={6}>
					<span className="additional-details">
						<p>
							A supply is considered live with EDF once the account is live i.e. all industry flows have been received
							and accepted.
						</p>
						<p>
							Sometimes there's a delay in the MOP providing the metering information, we are unable to request this
							until 32 days after the registration start date. However the majority of MOP information is received
							within 10 days. Please diarise and only request escalation after the 32 days has been breached.
						</p>
					</span>
				</Column>
			</Row>
		</>
	);
};

export default DailyStatusOverview;
