import { API } from '@edfenergy/res-agentui-sso-auth';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiPath } from './apiPath';

export const useStatusReport = (id: number) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const dispatch = useDispatch();
	const setData = (data: any[]) => dispatch({ type: 'statusData', data });

	const report = useSelector((store: any) => store.statusData) || [];
	const apiPath = useApiPath();

	useEffect(() => {
		if (!apiPath) return;
		setLoading(true);
		setError(null);
		loadStatusReport(id, setData)
			.catch((err) => setError(String(err)))
			.then(() => setLoading(false));
		// adding `setData` here causes tests to hang forever.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, apiPath]);

	return {
		report,
		loading,
		error,
	};
};

const loadStatusReport = async (id: number, onData: (data: any[]) => void) => {
	let page = 0;
	let fetchNextPage = true;
	let data: any[] = [];
	onData(data);

	while (fetchNextPage) {
		const result = await API.get('api', `/tpi/${id}/status-report?page=${page}`, {});
		data = [...data, ...result.result];
		onData(data);

		page++;
		fetchNextPage = result.pages > page;
	}
	return data;
};
export default loadStatusReport;
