import React, { useState, useEffect } from 'react';
import { brokerDataProps, URLParams } from '../../../widgets/types';
import IsEqual from '../../../widgets/isEqual';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Button, Loader, Row, PStyle } from '../../../edfm';

import EditCommissionTermsDetail from './editCommissionTermsDetails';
import ViewCommissionTermsDetail from './viewCommissionTermsDetails';
import NoTermsFound from './noTermsFound';
import { LoadCommissionTerms } from '../../../widgets/data/commissionTerms/loadCommissionTerms';
import { useSelector } from 'react-redux';
import { LoadBroker } from '../../../widgets/data';
import moment from 'moment';
import { CommissionTerm } from '../../../widgets/data/commissionTerms/commissionTerm.type';

const EditCommissionTerms = () => {
	const { id, mode } = useParams<URLParams>();
	const history = useHistory();
	const searchParameters = new URLSearchParams(useLocation().search);

	const effectiveDate: string | null =
		searchParameters.get('effectiveDate') !== null
			? searchParameters.get('effectiveDate')
			: moment(new Date()).format('YYYY-MM-DD');

	const [termType, setTermType] = useState(mode === 'retention' ? 'ret' : 'acq');
	const [brokerData, setBrokerData]: [brokerDataProps | undefined, any] = useState();
	const [commissionTermsData, setCommissionTermsData] = useState({ commissionTerms: [] });
	const [acqTerms, setAcqTerms]: [CommissionTerm | undefined, any] = useState();
	const [retTerms, setRetTerms]: [CommissionTerm | undefined, any] = useState();
	const [acqEffectiveDates, setAcqEffectiveDates]: [string[], Function] = useState([]);
	const [retEffectiveDates, setRetEffectiveDates]: [string[], Function] = useState([]);
	const [acqCurrentCommissionTerm, setAcqCurrentCommissionTerm]: [CommissionTerm, Function] = useState(
		{} as CommissionTerm
	);
	const [retCurrentCommissionTerm, setRetCurrentCommissionTerm]: [CommissionTerm, Function] = useState(
		{} as CommissionTerm
	);
	const [editRet, setEditRet] = useState(false);
	const [editAcq, setEditAcq] = useState(false);

	const commissionDataEmpty = {
		saleType: '',
		effectiveDate: '',
		acceptPc: undefined,
		livePc: undefined,
		finalPc: undefined,
		finalPcPeriodDays: 365,
		isLegacy: false,
		incentiveGasFee: undefined,
		incentiveElecFee: undefined,
		acceptIncentPc: undefined,
		liveIncentPc: undefined,
		finalIncentPc: undefined,
		finalIncentPeriodDays: undefined,
		payablePc: undefined,
		upliftAdjustment: 0,
		paymentCap: undefined,
		clawbackStructure: 'STD',
		termDays: 28,
		paymentDays: '1,14', //"7,21"
	};

	//trigger load broker in case latest update has changed due to action in another component (if broker not cleared during update - no reload performed)
	LoadBroker(Number(id));
	LoadCommissionTerms(Number(id));
	const findCommissionTermsByTypeAndDate = (saleType: string, effectiveDate: string) => {
		return commissionTermsData.commissionTerms
			.filter((ct: CommissionTerm) => ct.saleType === saleType && ct.effectiveDate <= effectiveDate)
			.pop();
	};
	useEffect((): void => {
		if (commissionTermsData && brokerData) {
			if (commissionTermsData.commissionTerms) {
				setSelectedCommissionTerms('ACQ', effectiveDate);
				setSelectedCommissionTerms('RET', effectiveDate);

				setAcqEffectiveDates(
					commissionTermsData.commissionTerms
						.filter((ct: CommissionTerm) => ct.saleType === 'ACQ')
						.map((ct: CommissionTerm) => ct.effectiveDate)
				);

				setRetEffectiveDates(
					commissionTermsData.commissionTerms
						.filter((ct: CommissionTerm) => ct.saleType === 'RET')
						.map((ct: CommissionTerm) => ct.effectiveDate)
				);

				setAcqCurrentCommissionTerm(findCommissionTermsByTypeAndDate('ACQ', moment(new Date()).format('YYYY-MM-DD')));

				setRetCurrentCommissionTerm(findCommissionTermsByTypeAndDate('RET', moment(new Date()).format('YYYY-MM-DD')));
			}
		}
		// adding `findCommissionTermsByTypeAndDate` here causes tests to hang forever.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brokerData, commissionTermsData, effectiveDate]);

	const dataStore = (input: any) => {
		if (
			input.commissionTermsData &&
			(!commissionTermsData || !IsEqual(input.commissionTermsData, commissionTermsData))
		) {
			setCommissionTermsData(input.commissionTermsData);
		}

		if (input.brokerData && (!brokerData || !IsEqual(input.brokerData, brokerData))) {
			setBrokerData(input.brokerData);
		}
	};

	useSelector(dataStore);

	const switchRet = (): void => {
		setTermType('ret');
		setEditRet(false);
		history.push(`/edit-broker/${id}/commission/retention`);
		setSelectedCommissionTerms('RET', effectiveDate);
	};

	const switchAcq = (): void => {
		setTermType('acq');
		setEditAcq(false);
		history.push(`/edit-broker/${id}/commission/acquisition`);
		setSelectedCommissionTerms('ACQ', effectiveDate);
	};

	const setSelectedCommissionTerms = (saleType: string, effectiveDate: string | null) => {
		if (commissionTermsData && brokerData) {
			if (saleType === 'ACQ') {
				setAcqTerms(
					brokerData.commissionType === 'TIERED' ||
						(effectiveDate !== null && findCommissionTermsByTypeAndDate('ACQ', effectiveDate))
				);
			}

			if (saleType === 'RET') {
				setRetTerms(
					brokerData.commissionType === 'TIERED' ||
						(effectiveDate !== null && findCommissionTermsByTypeAndDate('RET', effectiveDate))
				);
			}
		}
	};

	return (
		<>
			{!brokerData ? (
				<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
					<Loader />
				</div>
			) : (
				<>
					<h4>{brokerData.description}</h4>

					{termType === 'acq' ? (
						<>
							<Row>
								<PStyle>
									<div className="commission-terms_strap">
										<span className="u-pull-right">
											<Button label="Switch to Retentions" size="sm" action={switchRet} />
										</span>
										<span className="commission-terms_strap--label">
											<strong>Acquisition terms </strong>{' '}
										</span>
									</div>
								</PStyle>
							</Row>
							{acqTerms ? (
								// pre-populated view component that can be toggled to edit mode
								<>
									{editAcq ? (
										<EditCommissionTermsDetail
											id={Number(id)}
											cancel={() => {
												setEditAcq(false);
											}}
											finish={() => {
												setEditAcq(false);
											}}
											commissionData={{ ...acqTerms, updatedTs: brokerData.updatedTs }}
										/>
									) : (
										<ViewCommissionTermsDetail
											edit={() => {
												setEditAcq(true);
											}}
											commissionData={acqTerms}
											brokerData={brokerData}
											effectiveDates={acqEffectiveDates}
											changeCommissionTerms={setSelectedCommissionTerms}
											currentCommissionTerms={acqCurrentCommissionTerm}
										/>
									)}
								</>
							) : (
								// empty edit component
								<>
									{editAcq ? (
										<EditCommissionTermsDetail
											id={Number(id)}
											isNew
											commissionData={{
												...commissionDataEmpty,
												saleType: 'ACQ',
												updatedTs: brokerData.updatedTs,
											}}
											finish={() => {
												setEditAcq(false);
											}}
										/>
									) : (
										<NoTermsFound brokerData={brokerData} id={Number(id)} action={() => setEditAcq(true)} acquisition />
									)}
								</>
							)}
						</>
					) : (
						termType === 'ret' && (
							<>
								<Row>
									<PStyle>
										<div className="commission-terms_strap">
											<span className="u-pull-right">
												<Button label="Switch to Acquisitions" size="sm" action={switchAcq} />
											</span>
											<span className="commission-terms_strap--label">
												<strong>Retention terms </strong>
											</span>
										</div>
									</PStyle>
								</Row>
								{retTerms ? (
									// pre-populated view component that can be toggled to edit mode
									<>
										{editRet ? (
											<EditCommissionTermsDetail
												id={Number(id)}
												cancel={() => {
													setEditRet(false);
												}}
												finish={() => {
													setEditRet(false);
												}}
												commissionData={{ ...retTerms, updatedTs: brokerData.updatedTs }}
											/>
										) : (
											<ViewCommissionTermsDetail
												edit={() => {
													setEditRet(true);
												}}
												commissionData={retTerms}
												brokerData={brokerData}
												effectiveDates={retEffectiveDates}
												changeCommissionTerms={setSelectedCommissionTerms}
												currentCommissionTerms={retCurrentCommissionTerm}
											/>
										)}
									</>
								) : (
									// empty edit component
									<>
										{editRet ? (
											<EditCommissionTermsDetail
												id={Number(id)}
												isNew
												commissionData={{
													...commissionDataEmpty,
													saleType: 'RET',
													updatedTs: brokerData.updatedTs,
												}}
												finish={() => {
													setEditRet(false);
												}}
											/>
										) : (
											<NoTermsFound brokerData={brokerData} id={Number(id)} action={() => setEditRet(true)} />
										)}
									</>
								)}
							</>
						)
					)}
				</>
			)}
		</>
	);
};

export default EditCommissionTerms;
