import React, { FC } from 'react';
import { authFederatedSignIn } from '../AuthFunctions';

export const AuthButton: FC = ({ children = 'SSO' }) => (
	<button
		style={{
			borderRadius: '8px',
			textAlign: 'center',
			minWidth: '200px',
			height: '48px',
			border: 'solid 1px',
			fontSize: '20px',
			fontFamily: 'monospace',
			lineHeight: '24px',
			display: 'flex',
			padding: '0 16px',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			outline: 'none',
			backgroundColor: '#c82c00',
			borderColor: '#c82c00',
			color: '#FFFFFF',
		}}
		onClick={authFederatedSignIn}
	>
		{children}
	</button>
);
