import { API } from '@edfenergy/res-agentui-sso-auth';
import { useDispatch, useSelector } from 'react-redux';
import { ApiPath } from '.';

const LoadContracts = (forceLoad: boolean = false, callback?: any) => {
	const dispatch = useDispatch();

	const checkStore = (input: any) => {
		const contractsData = input.contractsData;
		return contractsData;
	};

	const contractsResult: any = useSelector(checkStore);

	//refactor check for Api data path into new function and cal asynetrically
	const apiLanded = (apiData: any): void => {
		if (forceLoad) {
			fetchContracts(apiData);
		} else if (!contractsResult) {
			//check if currently loaded broker is the requested one
			//or if greater than time threshold for stale data before loading - TODO
			fetchContracts(apiData);
		}
	};

	ApiPath(apiLanded);

	const fetchContracts = (apiData: any) => {
		//when getting fresh data, remove old data from store to prevent flash of obselete data
		dispatch({ type: 'contractsData', data: undefined });

		API.get('api', '/contract-version', {}).then(
			(result) => {
				dispatch({ type: 'contractsData', data: result.result });
			},
			(error) => {
				//handle error
				console.warn(error);
			}
		);
	};
};
export default LoadContracts;
