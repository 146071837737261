/* eslint-disable no-unused-vars */
import React, { ChangeEvent, useState } from 'react';
import { useBrokers } from '../../widgets/data/loadBrokers';
import { DisplayStatus } from './Status';
import { InputNumberDebounce } from '../../edfm/form/inputNumberDebounce/inputNumberDebounce';
import { InputTextDebounce } from '../../edfm/inputTextDebounce/inputTextDebounce';
import StatusBadge from './ui-components/StatusBadge';
import { Button, Select } from '../../edfm';

const SortingMethod = {
	StatusDESC: 'Status - Not Found first (default)',
	StatusASC: 'Status - Processing first',
	DateASC: 'Sign Date - Oldest first',
	DateDESC: 'Sign Date - Newest first',
	BrokerASC: 'Broker name - A-Z',
	BrokerDESC: 'Broker name - Z-A',
};

const DEFAULT_SORT = 'StatusDESC';

function SortingMethodEnumToOptionsParams(): { label: string; id: any }[] {
	return Object.entries(SortingMethod).map(([key, value]) => ({
		label: value,
		id: key,
	}));
}

const StatusBadgeList = (props: {
	list: DisplayStatus[];
	active: DisplayStatus[];
	onClick: (s: DisplayStatus) => void;
}) => (
	<>
		{Object.values(props.list).map((status) => {
			// eslint-disable-next-line array-callback-return
			if (typeof status === 'string') return;
			return (
				<StatusBadge
					key={status}
					style={{ margin: '4px' }}
					displayStatus={status}
					selected={props.active.includes(status)}
					onClick={() => props.onClick(status)}
				/>
			);
		})}
	</>
);

const SalesListControls = ({
	defaultSort = null,
	defaultBrokerFilter = null,
	defaultSignDateEnd = null,
	defaultMpxnFilter = null,
	defaultStatusFilter = [],
	onExport,
	onSearch,
	loading,
}: {
	defaultSort?: string | null;
	defaultBrokerFilter?: number | null;
	defaultSignDateEnd?: number | null;
	defaultMpxnFilter?: string | null;
	defaultStatusFilter?: DisplayStatus[];
	onExport: (params: {
		sort: string;
		brokerFilter: number | null;
		signDateEnd: number | null;
		mpxnFilter: string | null;
		statusFilter: DisplayStatus[];
	}) => void;
	onSearch: (params: {
		sort: string;
		brokerFilter: number | null;
		signDateEnd: number | null;
		mpxnFilter: string | null;
		statusFilter: DisplayStatus[];
	}) => void;
	loading: boolean;
}) => {
	const brokers = useBrokers();

	// Values in UI: committed to URL on button press. Defaults to values from URL.
	const [sort, setSort] = useState<string>(defaultSort || DEFAULT_SORT);
	const [brokerFilter, setBrokerFilter] = useState<number | null>(defaultBrokerFilter);
	const [signDateEnd, setsignDateEnd] = useState<number | null>(defaultSignDateEnd);
	const [mpxnFilter, setMpxnFilter] = useState<string | null>(defaultMpxnFilter);
	const [statusFilter, setStatusFilter] = useState<DisplayStatus[]>(defaultStatusFilter);

	const doSearch = () => {
		onSearch({
			sort,
			brokerFilter,
			signDateEnd,
			mpxnFilter,
			statusFilter,
		});
	};

	const doExport = () => {
		onExport({
			sort,
			brokerFilter,
			signDateEnd,
			mpxnFilter,
			statusFilter,
		});
	};

	const hasInvalidExportStatus = () =>
		statusFilter.length === 0 ||
		statusFilter.some((i) => ![DisplayStatus.Unprocessed, DisplayStatus.Suspended].includes(i));

	const toggleStatus = (status: DisplayStatus) => {
		if (statusFilter.includes(status)) {
			setStatusFilter(statusFilter.filter((s) => s !== status));
		} else {
			setStatusFilter([...statusFilter, status]);
		}
	};

	return (
		<>
			<h5>Filters: </h5>
			<div className="flex-column">
				<div className="flex-row">
					<div>
						<label htmlFor="mpxn">MPXN</label>
						<InputTextDebounce
							onChange={(event: ChangeEvent<HTMLInputElement>) => {
								setMpxnFilter(event.target.value);
							}}
							value={mpxnFilter ?? ''}
							className="signDate"
							id="mpxn"
						/>
					</div>
					<div>
						<label htmlFor="broker">Broker</label>
						<Select
							options={[{ id: -1, label: 'All' }, ...brokers.map((b) => ({ id: b.tpiId, label: b.description }))]}
							selected={brokerFilter || ''}
							onChange={(value) => (value === '-1' ? setBrokerFilter(null) : setBrokerFilter(value))}
							id="broker"
						/>
					</div>
					<div>
						<label htmlFor="sign-date">Sign Date (&gt; days ago)</label>
						<InputNumberDebounce
							onChange={(event) => {
								if (isNaN(event.target.valueAsNumber)) {
									setsignDateEnd(null);
								} else {
									setsignDateEnd(event.target.valueAsNumber);
								}
							}}
							value={signDateEnd ?? 0}
							className="signDate"
							id="sign-date"
							disabled={(mpxnFilter !== null && mpxnFilter !== '') || statusFilter.length > 0}
						/>
					</div>
					<div>
						<label htmlFor="sort">Sort by</label>
						<Select
							options={SortingMethodEnumToOptionsParams()}
							selected={sort || ''}
							onChange={(e: string) => {
								setSort(e);
							}}
							id="sort"
						/>
					</div>
				</div>
				<div className="flex-row">
					<div className="slightly-right">
						<p>Status </p>
						<div id="statusFilterSection">
							<StatusBadgeList
								list={[
									DisplayStatus.Processing,
									DisplayStatus.Live,
									DisplayStatus.Finalised,
									DisplayStatus.Returned,
									DisplayStatus.ContractEnded,
								]}
								active={statusFilter}
								onClick={toggleStatus}
							/>
							<br />
							<StatusBadgeList
								list={[
									DisplayStatus.SAPProcessing,
									DisplayStatus.Suspended,
									DisplayStatus.Unprocessed,
									DisplayStatus.Error,
								]}
								active={statusFilter}
								onClick={toggleStatus}
							/>
						</div>
					</div>
					<div className="flex-column">
						<Button primary label="Search" action={doSearch} disabled={loading} />

						<Button
							primary
							label="Export"
							action={doExport}
							disabled={loading || hasInvalidExportStatus()}
							hover="You can only export unprocessed or suspended energy sales"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default SalesListControls;
