import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import IsEqual from '../isEqual';

export const useApiPath = () => useSelector((store: any) => store.apiData);

const ApiPath = (callback: any) => {
	const [apiData, setApiData]: [any, any] = useState();

	//listen for store updates and call callback once API path is set, avoid calling again when other store updates occur
	//check if a route API path exists in store, if not do nothing and await next store update
	const dataStore = (input: any) => {
		if ((input.apiData && !apiData) || (input.apiData && !IsEqual(input.apiData, apiData))) {
			//if stored API path exists and isn't a match for local apiData value, update local value
			setApiData(input.apiData);
		}
	};

	useSelector(dataStore);

	useEffect(() => {
		if (apiData) {
			callback(apiData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiData]);
};

export default ApiPath;
