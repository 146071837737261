/* eslint-disable no-unused-vars */
import React from 'react';
import { EnergySale } from '../../../widgets/types';

import './StatusBadge.scss';
import { DisplayStatus, DisplayStatusToString, statusToDisplayStatus } from '../Status';

function DisplayStatusToClassName(status: DisplayStatus) {
	if (status === DisplayStatus.Processing) return 'processing';
	if (status === DisplayStatus.Suspended) return 'suspended';
	if (status === DisplayStatus.Unprocessed) return 'not-found';
	if (status === DisplayStatus.Returned) return 'returned';
	if (status === DisplayStatus.SAPProcessing) return 'sap-processing';
	if (status === DisplayStatus.Live) return 'live';
	if (status === DisplayStatus.Finalised) return 'finalised';
	if (status === DisplayStatus.ContractEnded) return 'contract-ended';
	if (status === DisplayStatus.Error) return 'error';
}

function DisplayStatusToIcon(status: DisplayStatus) {
	if (status === DisplayStatus.Processing) return <i className="fa fa-cog" />;
	if (status === DisplayStatus.Suspended) return <i className="fa fa-exclamation-circle" />;
	if (status === DisplayStatus.Unprocessed) return <i className="fa fa-exclamation" />;
	if (status === DisplayStatus.Returned) return <i className="fa fa-times" />;
	if (status === DisplayStatus.SAPProcessing) return <i className="fa fa-hourglass-end" />;
	if (status === DisplayStatus.Live) return <i className="fa fa-check" />;
	if (status === DisplayStatus.Finalised) return <i className="fa fa-check" />;
	if (status === DisplayStatus.ContractEnded) return <i className="fa fa-archive" />;
	if (status === DisplayStatus.Error) return <i className="fa fa-times" />;
}

const StatusBadge = (props: {
	sale?: EnergySale;
	displayStatus?: DisplayStatus;
	selected?: boolean;
	onClick?: () => void;
	style?: any;
}) => {
	let selected = props.selected ?? false;
	let displayStatus: DisplayStatus;

	if (props.sale) {
		displayStatus = statusToDisplayStatus(props.sale);
	} else if (props.displayStatus !== undefined) {
		displayStatus = props.displayStatus;
	} else {
		throw new Error('DisplayStatus or Sale props need to be set');
	}

	return (
		<span
			onClick={props.onClick}
			style={props.style}
			className={`status-badge ${DisplayStatusToClassName(displayStatus)} ${selected ? 'selected' : ''}`}
		>
			{DisplayStatusToIcon(displayStatus)}
			{DisplayStatusToString(displayStatus)}
		</span>
	);
};

export default StatusBadge;
