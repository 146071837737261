import { API } from '@edfenergy/res-agentui-sso-auth';

const UploadPrice = (path: string, body: any, file: any, successFunc?: any, failFunc?: any) => {
	let priceLaunchId: number;

	const errorHandler = (error: string) => {
		if (failFunc) {
			failFunc(error);
		} else {
			console.warn(error);
		}
	};

	API.post('api', `/price-launch`, { body }).then(
		(result) => {
			if (result.signedUrl) {
				if (result.result && result.result.priceLaunchId) {
					priceLaunchId = result.result.priceLaunchId;
				}
				uploadFile(result.signedUrl);
			} else if (result.result && result.result.error) {
				errorHandler('Unable to save price details in database: ' + result.result.error);
			} else if (result.result && result.result.message) {
				errorHandler('Unable to save price details in database: ' + result.result.message);
			} else {
				errorHandler('Unable to save price details in database');
			}
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
			//handle error
			errorHandler('Unable to save price details in database: ' + error);
		}
	);

	const uploadFile = (signedURL: string) => {
		fetch(signedURL, {
			method: 'PUT',
			headers: {
				'Content-Type': 'text/csv',
			},
			body: file,
		})
			.then(
				(result) => {
					if (result.ok) {
						//return response
						if (successFunc) {
							successFunc(priceLaunchId);
							return;
						}
					} else {
						errorHandler('Uploading CSV failed: ' + result.statusText);
					}
				}
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
			)
			.catch((error) => {
				if (error.response) {
					console.log(error.response.data.error);
					//handle error
					errorHandler('Uploading CSV failed: ' + error.response.data.error);
				} else {
					errorHandler('Uploading CSV failed');
				}
			});
	};
};
export default UploadPrice;
