import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Card, Container } from './components/edfm';

import ListBrokers from './components/pages/broker/brokerDetails/listBrokers';
import AddBroker from './components/pages/broker/brokerDetails/addBroker';
import Broker from './components/pages/broker/broker';
import Report from './components/pages/reports/report';
import Reports from './components/pages/reports/reports';

import Navigation from './components/pages/navigation';

import { Prices } from './components/pages/prices';

import { AuthContainer, Auth } from '@edfenergy/res-agentui-sso-auth';
import { ApiPath } from './components/widgets/data';
import { useDispatch } from 'react-redux';

import RefData from './components/widgets/referenceData';
import CaptureEnergySale from './components/pages/admin/captureEnergySale';
import Workflow from './components/pages/workflow/workflow';
import WorkflowDetail from './components/pages/workflow/workflowDetail';
import { useLocationTracker } from './components/widgets/locationTracker';

const isDev = process.env.REACT_APP_COGNITO_ENV === 'dev';

function App() {
	const [amplifyConfig, setAmplifyConfig] = useState({});
	const [loading, setLoading] = useState(true);

	const [seeAsProfile, setSeeAsProfile] = useState('GBM-R-CUS-Teller-Admin');

	const dispatch = useDispatch();

	function handleProfileChange(event: React.ChangeEvent<HTMLSelectElement>) {
		const newProfile = event.currentTarget.value;
		setSeeAsProfile(newProfile);
		dispatch({
			type: 'userProfile',
			data: { groups: [newProfile], loggedIn: true },
		});
	}

	//refactor check for Api data path into new function and cal asynetrically
	const apiLanded = (apiData: any): void => {
		setAmplifyConfig({
			Auth: {
				mandatorySignIn: true,
				region: apiData.AMPLIFY_AUTH_REGION,
				identityPoolRegion: apiData.AMPLIFY_AUTH_IDENTITY_REGION,
				userPoolId: apiData.AMPLIFY_AUTH_USERPOOL_ID,
				identityPoolId: apiData.AMPLIFY_AUTH_IDENTITY_ID,
				userPoolWebClientId: apiData.AMPLIFY_AUTH_USERPOOL_CLIENT_ID,
				provider: 'RED-AD',
				oauth: {
					domain: apiData.AMPLIFY_AUTH_OAUTH_DOMAIN,
					scope: ['email', 'profile', 'openid'],
					redirectSignIn: apiData.AMPLIFY_AUTH_OAUTH_REDIRECT_SIGNIN,
					redirectSignOut: apiData.AMPLIFY_AUTH_OAUTH_REDIRECT_SIGNOUT,
					responseType: 'code',
				},
			},
			API: {
				endpoints: [
					{
						name: 'api',
						endpoint: apiData.AMPLIFY_API_ENDPOINT,
						region: apiData.AMPLIFY_API_REGION,
					},
				],
			},
		});
		setLoading(false);
	};

	ApiPath(apiLanded);

	fetch(window.location.origin + '/config.json')
		.then((res) => {
			return res.json();
		})
		.then(
			(result) => {
				dispatch({ type: 'apiData', data: result });
			},
			(error) => {
				console.warn('Unable to load API path: ' + error);
			}
		);

	const Content = (props: { active: string; search?: boolean; children?: any; wide?: boolean }) => {
		const { active, children, wide } = props;

		RefData();
		useLocationTracker();

		const bodyContentStyle: Object = {
			marginTop: '110px',
			marginBottom: '10px',
		};

		return (
			<>
				<Navigation active={active} />
				<div style={bodyContentStyle}>
					<Container wide={wide}>
						<Card>{children}</Card>
					</Container>
				</div>
			</>
		);
	};

	return (
		<div>
			{!loading ? (
				<AuthContainer
					autoLogin={true}
					amplifyConfig={amplifyConfig}
					onSignIn={({ session }) => {
						Auth.currentUserCredentials()
							.then((userInfo) => {
								return Auth.currentSession();
							})
							.then((session) => {
								const groups = session.getIdToken().payload['cognito:groups'];
								dispatch({
									type: 'userProfile',
									data: { groups, loggedIn: true },
								});
							});
					}}
				>
					<Router>
						<div className="App">
							<Switch>
								{/* prices */}
								<Route path="/price-launches/:section?/:id?">
									<Content active="price-launches">
										<Prices />
									</Content>
								</Route>

								{/* prices */}
								<Route path="/admin/capture-energy-sale">
									<Content active="admin-capture-energy-sale">
										<CaptureEnergySale />
									</Content>
								</Route>

								{/* brokers */}
								<Route path="/list-brokers">
									<Content active="list-brokers">
										<ListBrokers />
									</Content>
								</Route>
								<Route path="/add-broker/">
									<Content active="add-broker">
										<AddBroker />
									</Content>
									<Navigation active="add-broker" />
								</Route>
								<Route path="/edit-broker/:id/:section?/:mode?/:contactId?">
									<Content active="list-brokers">
										<Broker />
									</Content>
								</Route>

								{/* reports */}
								<Route path="/reports/:id/:report?/:statementId?">
									<Content active="reports" wide={true}>
										<Report />
									</Content>
								</Route>
								<Route path="/reports">
									<Content active="reports">
										<Reports />
									</Content>
								</Route>

								{/* reports */}
								<Route path="/workflow/:id">
									<Content active="workflow" wide={true}>
										<WorkflowDetail />
									</Content>
								</Route>
								<Route path="/workflow">
									<Content active="workflow" wide={true}>
										<Workflow />
									</Content>
								</Route>

								{/* default */}
								<Route path="/">
									<Content active="list-brokers">
										<ListBrokers />
									</Content>
									<Navigation />
								</Route>
							</Switch>
						</div>
					</Router>
				</AuthContainer>
			) : (
				<div>Loading...</div>
			)}
			{isDev ? (
				<div id="teller-dev-tools">
					<div>Dev tools, see app as:</div>
					<select value={seeAsProfile} onChange={handleProfileChange}>
						<option value="GBM-R-CUS-Teller-Base">Offshore team</option>
						<option value="GBM-R-CUS-Teller-Broker">Broker team</option>
						<option value="GBM-R-CUS-Teller-PriceLaunch">Price team</option>
						<option value="GBM-R-CUS-Teller-Admin">Admin/Dev</option>
						<option value="GBM-R-CUS-Teller-Base">Offshore</option>
						<option value="GBM-R-CUS-Teller-Registrations">Support team</option>
						<option value="GBM-R-CUS-Teller-ReadOnly">ReadOnly</option>
					</select>
				</div>
			) : null}
		</div>
	);
}

export default App;
