import Swal from 'sweetalert2';

/**
 * Start the process to return an energy sale
 * @param id the id of the sale to return
 */
const showUnreturnModal = (onReturn: (params: { id: number }) => void) => (id: number) => {
	// First popup
	Swal.fire({
		title: 'Are you sure you want to unreturn this sale?',
		//text: "You won't be able to revert this. An email will be sent to the broker.",
		showCancelButton: true,
		confirmButtonText: `Yes`,
		denyButtonText: `Cancel`,
		icon: 'warning',
		width: '50em',
		customClass: {
			title: 'edfm_popup_font_content',
			actions: 'edfm_popup_font_actions',
		},
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
			onReturn({
				id,
			});
		}
	});
};

export default showUnreturnModal;
