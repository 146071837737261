import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Input, Loader, TextArea, Select, Row, Column, Checkbox, Modal } from '../../../edfm';

import { brokerDataProps, refDataProps, contractsDataProps } from '../../../widgets/types';
import { SaveBroker, ApiPath } from '../../../widgets/data';
import DateFormat from '../../../widgets/dateFormat';
import { useDispatch } from 'react-redux';

export interface BrokerProps {
	initialBrokerData: brokerDataProps;
	contractsData: Array<contractsDataProps>;
	refData: Array<Array<refDataProps>>;
	agents: Array<{
		id: number;
		label: string;
	}>;
	id?: number | boolean;
	cancel?: any;
}

const EditBroker = (props: BrokerProps) => {
	const { initialBrokerData, id = false, agents, cancel, contractsData, refData } = props;
	const [path, setPath]: [string | undefined, any] = useState();
	const [brokerData, setBrokerData] = useState(initialBrokerData);
	const [mappedBrokerDataSource, setMappedBrokerDataSource]: [string | undefined, any] = useState();
	const [error]: [boolean | string, any] = useState(false); //prob remove as not implemented
	const [updating, setUpdating] = useState(false);
	const [contractDataMap, setContractDataMap]: any = useState([]);

	const [dataSourchData, setDataSourchData]: [[{ label: string; id: any }] | undefined, any] = useState();
	const [statusRepFreqData, setStatusRepFreqData]: [[{ label: string; id: any }] | undefined, any] = useState();
	const [commissionStmtFreq, setCommissionStmtFreq]: [[{ label: string; id: any }] | undefined, any] = useState();
	const [tpiChannelData, setTpiChannelData]: [[{ label: string; id: any }] | undefined, any] = useState();
	const [tpiStatusData, setTpiStatusData]: [[{ label: string; id: any }] | undefined, any] = useState();

	const [hideErrorModal, setHideErrorModal] = useState(true);
	const [errorModalBody, setErrorModalBody] = useState('');
	const [errorTitle, setErrorTitle] = useState('');

	const [editingPurchaseOrder, setEditingPurchaseOrder] = useState(false);
	const [editingVendorId, setEditingVendorId] = useState(initialBrokerData.vendorId ? false : true);

	const handleError = (errorMessage: string) => {
		setErrorModalBody(errorMessage);
		setErrorTitle('Error');
		setHideErrorModal(false);
	};

	const history = useHistory();

	const dispatch = useDispatch();

	ApiPath((apiPath: { apiurl: string }) => {
		setPath(apiPath.apiurl);
	});

	const submitForm = (e: any) => {
		e.preventDefault();

		const submissionData = { ...brokerData };

		//if purchase order present - set effective date to now, else if empty, ensure is null
		if (
			submissionData.purchaseOrderReference &&
			submissionData.purchaseOrderReference !== '' &&
			!submissionData.isSelfBilling
		) {
			submissionData.purchaseOrderEffectiveDate = formatJsonDate(new Date());
		} else {
			submissionData.purchaseOrderReference = undefined;
			submissionData.PurchaseOrder = undefined;
		}

		const stringEnd = id === false ? '' : '/' + id;
		const body = submissionData;
		const method = id === false ? 'POST' : 'PUT';

		setUpdating(true);

		const successFunc = (newId: string, newTS: string): void => {
			setUpdating(false);

			if (id === false) {
				//bust cache for broker list
				dispatch({ type: 'brokersData', data: undefined });

				history.push('/edit-broker/' + newId);
			} else {
				setErrorModalBody('Broker details updated');
				setErrorTitle('Update successful');
				setHideErrorModal(false);

				//bust cache for broker list
				dispatch({ type: 'brokersData', data: undefined });
				dispatch({ type: 'brokerData', data: undefined });

				setBrokerData((oldData) => {
					const newData = {
						...oldData,
						updatedTs: newTS,
					};
					return newData;
				});
			}
		};

		const errorFunc = (error: string): void => {
			//handle error
			handleError(error);
			setUpdating(false);
		};

		path &&
			//as apiPath *must* exist to reach this sub page - no current handling / subscription created in case not defined
			SaveBroker(path, method, stringEnd, body, successFunc, errorFunc);
	};

	useEffect(() => {
		let dataSources: Array<{ id: string; label: string }> = [];

		for (let i = 0; i < refData.length; i++) {
			const dataMap = refData[i].map((item: any) => {
				const { code, description } = item;
				return { id: code, label: description };
			});

			if (refData[i][0].refDataCategory === 'DataSource') {
				setDataSourchData(dataMap);
				dataSources = dataMap;
			} else if (refData[i][0].refDataCategory === 'CommissionStmtFreq') {
				setCommissionStmtFreq(dataMap);
			} else if (refData[i][0].refDataCategory === 'StatusRepFreq') {
				setStatusRepFreqData(dataMap);
			} else if (refData[i][0].refDataCategory === 'TPIChannel') {
				setTpiChannelData(dataMap);
			} else if (refData[i][0].refDataCategory === 'TPIStatus') {
				setTpiStatusData(dataMap);
			}
		}

		const dataSource = dataSources.find(
			(elem: { id: string; label: string }) => elem.id === brokerData.dataSource
		)?.label;
		setMappedBrokerDataSource(dataSource);
	}, [refData, brokerData]);

	useEffect(() => {
		const dataMap = contractsData.map((item: any) => {
			const { contractVersionId, description } = item;

			return { id: contractVersionId, label: description };
		});
		setContractDataMap(dataMap);
	}, [contractsData]);

	const textChange = (e: any): void => {
		const value = e.target.value;
		const field = e.target.id;
		setBrokerData((oldData) => {
			const newData = {
				...oldData,
				[field]: value,
			};
			return newData;
		});
	};

	const handleSelectChange = (e: string | number, field: string): void => {
		setBrokerData((oldData) => {
			const newData = {
				...oldData,
				[field]: e,
			};
			return newData;
		});
	};

	const toggleTpi = (): void => {
		setBrokerData((oldData) => {
			const isSelfBilling = !oldData.isSelfBilling;
			const newData = {
				...oldData,
				isSelfBilling: isSelfBilling,
			};
			return newData;
		});
	};

	const cancelEdit = () => {
		cancel();
	};

	const validForm = (): boolean => {
		if (
			brokerData.description === '' ||
			brokerData.tpiBusinessKey === '' ||
			brokerData.dataSource === '' ||
			brokerData.relationshipManagerId === null ||
			brokerData.tpiChannel === '' ||
			brokerData.statusRepFreq === '' ||
			brokerData.commissionStmtFreq === '' ||
			brokerData.contractVersionId === null ||
			brokerData.status === ''
		) {
			return false;
		}

		return true;
	};

	const formatJsonDate = (date: Date): string => {
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);

		return `${date.getFullYear()}-${month}-${day}`;
	};

	return (
		<>
			{error === false ? (
				<>
					<Modal
						hide={hideErrorModal}
						title={errorTitle}
						size="sm"
						customClose={() => setHideErrorModal(true)}
						content={errorModalBody}
						close={true}
					/>

					{updating && (
						<div className="list-broker--loader">
							<Loader />
						</div>
					)}

					<form onSubmit={submitForm}>
						<Row>
							<Column columns={6}>
								<Input
									fullWidth
									type="text"
									value={brokerData.description}
									id="description"
									label="Broker name"
									onChange={textChange}
								/>
								<Select
									selected={brokerData.relationshipManagerId ? brokerData.relationshipManagerId : ''}
									heading="Relationship manager"
									options={agents}
									onChange={(e: any) => {
										handleSelectChange(e, 'relationshipManagerId');
									}}
								/>

								{/* select options to be pulled from RefData once available */}
								{dataSourchData && statusRepFreqData && commissionStmtFreq && tpiChannelData && tpiStatusData && (
									<>
										<Select
											heading="Channel"
											selected={brokerData.tpiChannel}
											options={tpiChannelData}
											onChange={(e: string) => {
												handleSelectChange(e, 'tpiChannel');
											}}
										/>
										<Select
											heading="Status"
											selected={brokerData.status}
											options={tpiStatusData}
											onChange={(e: string) => {
												handleSelectChange(e, 'status');
											}}
										/>
										<Select
											heading="Status report frequency"
											selected={brokerData.statusRepFreq}
											options={statusRepFreqData}
											onChange={(e: string) => {
												handleSelectChange(e, 'statusRepFreq');
											}}
										/>
										<Select
											heading="Commission statement frequency"
											selected={brokerData.commissionStmtFreq}
											options={commissionStmtFreq}
											onChange={(e: string) => {
												handleSelectChange(e, 'commissionStmtFreq');
											}}
										/>
										<Input
											fullWidth
											type="password"
											value={brokerData.statementPassword || ''}
											id="statementPassword"
											label="statement/report password"
											onChange={textChange}
										/>
									</>
								)}
							</Column>

							<Column columns={6}>
								{id === false ? (
									<>
										<Input
											type="text"
											fullWidth
											value={brokerData.tpiBusinessKey}
											id="tpiBusinessKey"
											label="Broker code"
											onChange={textChange}
										/>
										{dataSourchData && (
											<Select
												heading="Data source"
												selected={brokerData.dataSource}
												options={dataSourchData}
												onChange={(e: string) => {
													handleSelectChange(e, 'dataSource');
												}}
											/>
										)}
										{!brokerData.isSelfBilling && (
											<Input
												type="text"
												fullWidth
												value={brokerData.purchaseOrderReference || ''}
												id="purchaseOrderReference"
												label="Purchase order"
												onChange={textChange}
											/>
										)}
										<Select
											heading="Contract version"
											selected={brokerData.contractVersionId === null ? '' : brokerData.contractVersionId}
											options={contractDataMap}
											onChange={(e: number) => {
												handleSelectChange(e, 'contractVersionId');
											}}
										/>
									</>
								) : (
									<>
										<p>
											<strong>Broker code:</strong> {brokerData.tpiBusinessKey}
											<br />
											<strong>Data source:</strong> {mappedBrokerDataSource}
											<br />
											{brokerData.PurchaseOrder &&
												brokerData.PurchaseOrder.purchaseOrderReference &&
												!editingPurchaseOrder &&
												!brokerData.isSelfBilling && (
													<>
														<strong>Purchase order:</strong> {brokerData.PurchaseOrder.purchaseOrderReference} -{' '}
														<span
															style={{ textDecoration: 'underline', cursor: 'pointer' }}
															onClick={() => {
																setEditingPurchaseOrder(true);
															}}
														>
															change
														</span>
														<br />
													</>
												)}
											{brokerData.vendorId && !editingVendorId && (
												<>
													<strong>Vendor Id:</strong> {brokerData.vendorId} -{' '}
													<span
														style={{ textDecoration: 'underline', cursor: 'pointer' }}
														onClick={() => {
															setEditingVendorId(true);
														}}
													>
														change
													</span>
													<br />
												</>
											)}
										</p>

										{(!brokerData.PurchaseOrder ||
											!brokerData.PurchaseOrder.purchaseOrderReference ||
											editingPurchaseOrder) &&
											!brokerData.isSelfBilling && (
												<Input
													type="text"
													fullWidth
													value={brokerData.purchaseOrderReference || ''}
													id="purchaseOrderReference"
													label="Purchase order"
													onChange={textChange}
												/>
											)}

										{(!brokerData.vendorId || editingVendorId) && (
											<Input
												type="text"
												fullWidth
												value={brokerData.vendorId || ''}
												id="vendorId"
												label="Vendor Id"
												onChange={textChange}
											/>
										)}

										<Select
											heading="Contract version"
											selected={brokerData.contractVersionId ? brokerData.contractVersionId : ''}
											options={contractDataMap}
											onChange={(e: number) => {
												handleSelectChange(e, 'contractVersionId');
											}}
										/>
									</>
								)}

								<Checkbox value={brokerData.isSelfBilling} label="Self billing" onChange={toggleTpi} />

								<TextArea value={brokerData.notes} label="Notes" id="notes" fullWidth onChange={textChange} />
							</Column>
						</Row>

						<Row>
							<p className="u-pull-right">
								{id !== false && cancel && (
									<input
										className="edfmat_button button button-secondary"
										type="button"
										onClick={cancelEdit}
										value="Cancel"
									/>
								)}
								<input
									className="edfmat_button edfmat_button--primary"
									disabled={!validForm()}
									type="submit"
									value={id === false ? 'Add broker' : 'Update broker'}
								/>
							</p>
							<p className="additional-details">
								{id !== false && (
									<>
										{brokerData.updatedTs !== brokerData.createdTs && (
											<>
												<strong>Last updated:</strong> <DateFormat date={brokerData.updatedTs} /> by{' '}
												{brokerData.updatedId}
												<br />
											</>
										)}
										<strong>Created:</strong> <DateFormat date={brokerData.createdTs} /> by {brokerData.createdId}
										<br />
									</>
								)}
							</p>
						</Row>
						{!validForm() && (
							<Row>
								<span className="u-pull-right additional-details">
									* Missing fields:&nbsp;
									{brokerData.description === '' && 'Broker name. '}
									{brokerData.tpiBusinessKey === '' && 'Broker code. '}
									{brokerData.dataSource === '' && 'Data source. '}
									{!brokerData.relationshipManagerId && 'Relationship manager. '}
									{!brokerData.status && 'Status. '}
									{brokerData.tpiChannel === '' && 'Channel. '}
									{(brokerData.statusRepFreq === '' || brokerData.commissionStmtFreq === '') && 'Report frequencies. '}
									{!brokerData.contractVersionId && 'Contract version. '}
								</span>
							</Row>
						)}
					</form>
				</>
			) : (
				<>
					<p>{error}</p>
				</>
			)}
		</>
	);
};

export default EditBroker;
