import React from 'react';
import { useHistory } from 'react-router-dom';
import { NavBar } from '../edfm/index';
import useACL, { Feature } from '../widgets/acl';

export interface NavProps {
	active?: string;
	search?: boolean;

	//array of nav elements, {image, label, hover, action(which will fire routing in the component above the navbar)}
	//left and right items - logo and logout - that aren't within the main container block and need to go to a new line if the gutter becomes too small
}

const Navigation = ({ active, search = true }: NavProps) => {
	const history = useHistory();

	const clickAction = (path: string) => {
		history.push(path);
	};

	const acl = useACL();

	const options: { label: string; action: Function; active: boolean; href: string; feature?: Feature }[] = [
		{
			label: 'View brokers',
			action: function () {
				if (this.feature && acl(this.feature)) clickAction('/list-brokers');
			},
			active: active === 'list-brokers' ? true : false,
			href: '/list-brokers',
			feature: Feature.ViewBroker,
		},
		{
			label: 'Add brokers',
			action: function () {
				if (this.feature && acl(this.feature)) clickAction('/add-broker');
			},
			active: active === 'add-broker' ? true : false,
			href: '/add-broker',
			feature: Feature.EditBroker,
		},
		{
			label: 'Price launches',
			action: function () {
				if (this.feature && acl(this.feature)) clickAction('/price-launches');
			},
			active: active === 'price-launches' ? true : false,
			href: '/price-launches',
			feature: Feature.PriceLaunch,
		},
		{
			label: 'Reports',
			action: function () {
				if (this.feature && acl(this.feature)) clickAction('/reports');
			},
			active: active === 'reports' ? true : false,
			href: '/reports',
			feature: Feature.ViewBroker,
		},
		{
			label: 'Workflow',
			action: function () {
				if (this.feature && acl(this.feature)) clickAction('/workflow');
			},
			active: active === 'workflow' ? true : false,
			href: '/workflow',
			feature: Feature.Workflow,
		},
		{
			label: 'Manual Sale',
			action: function () {
				if (this.feature && acl(this.feature)) clickAction('/admin/capture-energy-sale');
			},
			active: active === 'admin-capture-energy-sale' ? true : false,
			href: '/admin/capture-energy-sale',
			feature: Feature.Admin,
		},
	];

	return <NavBar options={options} fixed={true} />;
};

export default Navigation;
