import React, { useState } from 'react';
import { Loader, NoAccess } from '../../../edfm';

import EditBrokerDetails from './editBrokerDetails';

import IsEqual from '../../../widgets/isEqual';

import { useStore, useSelector } from 'react-redux';
import { LoadAgents, LoadBrokers, LoadContracts } from '../../../widgets/data';

import { brokerDataProps } from '../../../widgets/types';
import useACL, { Feature } from '../../../widgets/acl';

const EditBroker = () => {
	useStore();

	const [loadingAgents, setLoadingAgents] = useState(true);
	const [brokersData, setBrokersData]: [[brokerDataProps] | undefined, any] = useState();
	const [brokersDataMap, setBrokersDataMap]: [[{ label: string; id: any }] | undefined, any] = useState();
	const brokerData: brokerDataProps = {
		description: '',
		tpiChannel: '',
		status: '',
		notes: '',
		relationshipManagerId: undefined,
		dataSource: '',
		tpiBusinessKey: '',
		statusRepFreq: '',
		commissionStmtFreq: '',
		contractVersionId: null,
		createdTs: '',
		createdId: '',
		updatedTs: '',
		updatedId: '',
		vendorId: '',
		isSelfBilling: false,
	};
	const [contractsData, setContractsData]: [[any] | undefined, any] = useState();

	const [agents, setAgents] = useState([{ id: 0, label: '' }]);
	const [error]: [boolean | string, any] = useState(false); //prob remove as not implemented
	const [refData, setRefData]: [[any] | undefined, any] = useState();

	LoadAgents();
	LoadBrokers();
	LoadContracts();

	const dataStore = (input: any) => {
		if (input.agentsData) {
			const agentData = input.agentsData.map((elem: any, i: number) => {
				return { id: elem.relationshipManagerId, label: elem.description };
			});

			if (!IsEqual({ ...agentData }, { ...agents })) {
				setAgents(agentData);
				setLoadingAgents(false);
			}
		}

		if (input.contractsData && (!contractsData || !IsEqual(input.contractsData, contractsData))) {
			setContractsData(input.contractsData);
		}

		if (input.brokersData && (!brokersData || !IsEqual(input.brokersData, brokersData))) {
			setBrokersData(input.brokersData);

			const filtered = input.brokersData.filter(
				(elem: any, i: number) => elem.isLeadTpi && elem.tpiId !== brokerData?.tpiId
			);

			let mapped = filtered.map((elem: any, i: number) => {
				return { id: elem.tpiId, label: elem.description };
			});

			mapped.unshift({ label: 'None', id: null });
			setBrokersDataMap(mapped);
		}

		if (input.refData && input.refData && (!refData || !IsEqual(input.refData, refData))) {
			setRefData(input.refData);
		}
	};

	useSelector(dataStore);

	const acl = useACL();
	if (!acl(Feature.EditBroker)) return <NoAccess />;

	return (
		<div className="container">
			{loadingAgents ? (
				<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
					<Loader />
				</div>
			) : error === false ? (
				<>
					{brokersDataMap && contractsData && refData && (
						<EditBrokerDetails
							initialBrokerData={brokerData}
							refData={refData}
							contractsData={contractsData}
							agents={agents}
						/>
					)}
				</>
			) : (
				<p>{error}</p>
			)}
		</div>
	);
};

export default EditBroker;
