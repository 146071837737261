/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './workflow.scss';
import { Button, Loader, NoAccess } from '../../edfm';
import useACL, { Feature } from '../../widgets/acl';
import SalesList from './ui-components/SalesList';
import SalesListHeader from './ui-components/SalesListHeader';
import useSales from './useSales';
import SalesListControls from './workflowControls';
import { DisplayStatus, DisplayStatusToString, statusToDisplayStatus } from './Status';
import exportCSVFile from '../../widgets/exportToCSV';

// builds on useLocation, parses the query string.
function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Workflow = () => {
	const query = useQuery();
	const history = useHistory();
	const acl = useACL();

	// Values from URL: used for api query.
	const queryData = {
		sort: query.get('sort') ?? null,
		brokerFilter: query.get('broker') ? Number(query.get('broker')) : null,
		signDateEnd: query.get('signDateEnd') ? Number(query.get('signDateEnd')) : null,
		mpxnFilter: query.get('mpxn') ?? null,
		statusFilter:
			query
				.get('status')
				?.split(',')
				?.map((n) => Number(n)) ?? [],
	};

	const { sales, loading, moreAvailable, onLoadMore, exportSales } = useSales(queryData);

	// Commit UI values to url, triggering search.
	const onSearch = async ({
		sort,
		brokerFilter,
		signDateEnd,
		mpxnFilter,
		statusFilter,
	}: {
		sort: string;
		brokerFilter: number | null;
		signDateEnd: number | null;
		mpxnFilter: string | null;
		statusFilter: DisplayStatus[];
	}) => {
		const params = new URLSearchParams({ sort });
		if (brokerFilter !== null) {
			params.set('broker', brokerFilter.toString().trim());
		}
		if (signDateEnd !== null) {
			params.set('signDateEnd', signDateEnd.toString().trim());
		}
		if (statusFilter.length) {
			params.set('status', statusFilter.join(','));
		}
		if (mpxnFilter !== null) {
			params.set('mpxn', mpxnFilter.trim());
		}
		history.push({
			search: params.toString(),
		});
	};

	const onExport = async ({
		sort,
		brokerFilter,
		signDateEnd,
		mpxnFilter,
		statusFilter,
	}: {
		sort: string;
		brokerFilter: number | null;
		signDateEnd: number | null;
		mpxnFilter: string | null;
		statusFilter: DisplayStatus[];
	}) => {
		const data = await exportSales({ sort, brokerFilter, signDateEnd, mpxnFilter, statusFilter });

		const exportData = data.map((sale) => {
			const displayStatus: string | undefined = DisplayStatusToString(statusToDisplayStatus(sale));
			return [
				displayStatus ? displayStatus : '',
				sale.createdTs as string,
				sale.signDate as string,
				sale.product as string,
				sale.mpxn as string,
			];
		});
		await exportCSVFile(exportData, `Sales ${new Date().toUTCString()}`, [
			'status',
			'createdTs',
			'signDate',
			'product',
			'mpxn',
		]);
	};

	const goToSale = (id: number) => {
		history.push(`/workflow/${id}`);
	};

	if (!acl(Feature.Workflow)) return <NoAccess />;

	return (
		<div id="workflow" className="container">
			<h2>Sales Workflow</h2>
			<div className="row">
				<div className="twelve column">
					<form className="list-broker--container">
						<SalesListControls
							defaultSort={queryData.sort}
							defaultBrokerFilter={queryData.brokerFilter}
							defaultMpxnFilter={queryData.mpxnFilter}
							defaultSignDateEnd={queryData.signDateEnd}
							defaultStatusFilter={queryData.statusFilter}
							onExport={onExport}
							onSearch={onSearch}
							loading={loading}
						/>
						<hr />
						<SalesListHeader />
						<SalesList sales={sales} viewAction={goToSale} />
						{loading && (
							<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
								<Loader />
							</div>
						)}
						{moreAvailable && <Button label="load more" fullwidth secondary action={onLoadMore} />}
					</form>
				</div>
			</div>
		</div>
	);
};

export default Workflow;
