import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { brokerDataProps, URLParams } from '../../../widgets/types';
import IsEqual from '../../../widgets/isEqual';
import { LoadBroker } from '../../../widgets/data';
import { Loader, Button } from '../../../edfm';

const BrokerReports = () => {
	const [brokerData, setBrokerData]: [brokerDataProps | undefined, any] = useState();
	const [loadingBroker, setLoadingBroker] = useState(true);

	const { id } = useParams<URLParams>();
	const history = useHistory();

	LoadBroker(Number(id));

	const dataStore = (input: any) => {
		if (input.brokerData && (!brokerData || !IsEqual(input.brokerData, brokerData))) {
			if (input.brokerData.tpiId === Number(id)) {
				setBrokerData(input.brokerData);
				setLoadingBroker(false);
			}
		}
	};

	useSelector(dataStore);

	const viewReport = (): void => {
		history.push(`../../../reports/${id}/status-report`);
	};
	const viewStatement = (): void => {
		history.push(`../../../reports/${id}/commission-statement`);
	};

	return (
		<>
			{loadingBroker ? (
				<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
					<Loader />
				</div>
			) : (
				<>
					{brokerData && <h4>{brokerData.description}</h4>}
					<p>View, search and download the current status report or latest commision statement.</p>
					<Button label="Status report" action={viewReport} />
					<Button label="Commission statement" action={viewStatement} />
				</>
			)}
		</>
	);
};

export default BrokerReports;
