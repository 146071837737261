import { API } from '@edfenergy/res-agentui-sso-auth';

const UnreturnEnergySale = (id: number, successFunc?: any, failFunc?: any) => {
	const errorHandler = (error: string) => {
		if (failFunc) {
			failFunc(error);
		} else {
			console.warn(error);
		}
	};

	API.put('api', `/energy-sale/${id}/revert`, {})
		.then((result) => {
			if (result.result && result.result.error) {
				errorHandler('Unable to unreturn sale - ' + result.result.error);
			} else if (result.result && result.result.message) {
				errorHandler('Unable to unreturn sale - ' + result.result.message);
			} else if (result && result.error) {
				errorHandler('Unable to unreturn sale - ' + result.error);
			} else if (result && result.message) {
				errorHandler('Unable to unreturn sale - ' + result.message);
			} else {
				if (successFunc) successFunc();
			}
		})
		.catch((error) => {
			if (error.response) {
				//handle error
				errorHandler('Unable to unreturn sale - ' + error.response.data.error);
			} else {
				errorHandler('Unable to unreturn sale');
			}
		});
};

export default UnreturnEnergySale;
