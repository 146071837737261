import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from '@aws-amplify/auth';

export const getCurrentSesssion = (isVerbose?: boolean): Promise<CognitoUserSession> => {
	return Auth.currentSession()
		.then((ses) => {
			if (isVerbose) {
				console.debug('[SSOAuth][CurrentSesssion]', ses);
			}
			return ses;
		})
		.catch((err) => {
			console.debug('[SSOAuth][CurrentSesssion]', err);
			throw new Error(err);
		});
};
