import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from '../../../edfm';

import EditBrokerDetails from './editBrokerDetails';
import ViewBrokerDetails from './viewBrokerDetails';

import IsEqual from '../../../widgets/isEqual';

import { useSelector } from 'react-redux';

import { brokerDataProps, URLParams } from '../../../widgets/types';
import { LoadBrokers, LoadBroker } from '../../../widgets/data';

const EditBroker = () => {
	const [loadingBroker, setLoadingBroker] = useState(true);
	const [brokerData, setBrokerData]: [brokerDataProps | undefined, any] = useState();
	const [brokersData, setBrokersData]: [[brokerDataProps] | undefined, any] = useState();
	const [brokersDataMap, setBrokersDataMap]: [[{ label: string; id: any }] | undefined, any] = useState();

	const [contractsData, setContractsData]: [[any] | undefined, any] = useState();
	const [contractData, setContractData] = useState({ description: '' });
	const [loadingContract, setLoadingContract] = useState(true);

	const [agents, setAgents] = useState([{ id: 0, label: '' }]);
	const [error, setError]: [boolean | string, any] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [relationshipManager, setRelationshipManager] = useState('');
	const [loadingManager, setLoadingManager] = useState(true);
	const [refData, setRefData]: [[any] | undefined, any] = useState();

	const { id } = useParams<URLParams>();

	function displayError(error: any) {
		setError(error);
		setLoadingBroker(false);
		setLoadingManager(false);
	}

	LoadBrokers();
	LoadBroker(Number(id), false, displayError);

	const dataStore = (input: any) => {
		if (input.agentsData) {
			const agentData = input.agentsData.map((elem: any, i: number) => {
				return { id: elem.relationshipManagerId, label: elem.description };
			});

			if (!IsEqual({ ...agentData }, { ...agents })) {
				setAgents(agentData);
			}
		}

		if (input.brokerData && (!brokerData || !IsEqual(input.brokerData, brokerData))) {
			if (input.brokerData.tpiId === Number(id)) {
				setBrokerData(input.brokerData);
				setLoadingBroker(false);
			}
		}

		if (input.contractsData && (!contractsData || !IsEqual(input.contractsData, contractsData))) {
			setContractsData(input.contractsData);
			setLoadingContract(false);
		}

		if (input.brokerData && input.brokersData && (!brokersData || !IsEqual(input.brokersData, brokersData))) {
			setBrokersData(input.brokersData);

			// eslint-disable-next-line eqeqeq
			const filtered = input.brokersData.filter(
				(elem: any, i: number) => elem.isLeadTpi && elem.tpiId !== brokerData?.tpiId
			);

			let mapped = filtered.map((elem: any, i: number) => {
				return { id: elem.tpiId, label: elem.description };
			});

			mapped.unshift({ label: 'None', id: null });
			setBrokersDataMap(mapped);
		}

		if (input.refData && (!refData || !IsEqual(input.refData, refData))) {
			setRefData(input.refData);
		}
	};

	useEffect(() => {
		if (contractsData && brokerData) {
			const currentContract = contractsData.find((elem: any) => {
				return elem.contractVersionId === brokerData.contractVersionId;
			});

			if (currentContract) {
				setContractData(currentContract);
			}
		}
	}, [contractsData, brokerData]);

	useEffect(() => {
		if (brokerData && brokerData.relationshipManagerId !== undefined) {
			setRelationshipManager(() => {
				let returnVal = '';

				if (brokerData.relationshipManagerId !== undefined && agents.length > 0) {
					for (let i = 0; i < agents.length; i++) {
						if (agents[i].id === brokerData.relationshipManagerId) {
							setRelationshipManager(agents[i].label);
							break;
						}
					}
				}

				setLoadingManager(false);

				return returnVal;
			});
		}
	}, [agents, brokerData]);

	useSelector(dataStore);

	return (
		<>
			{loadingBroker || loadingContract || loadingManager ? (
				<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
					<Loader />
				</div>
			) : error === false ? (
				<>
					{brokerData && <h4>{brokerData.description}</h4>}

					{editMode && brokerData && brokersData && brokersDataMap && contractsData && refData ? (
						<EditBrokerDetails
							initialBrokerData={brokerData}
							refData={refData}
							contractsData={contractsData}
							agents={agents}
							id={Number(id)}
							cancel={() => setEditMode(false)}
						/>
					) : (
						brokerData &&
						brokersData &&
						brokersDataMap && (
							<ViewBrokerDetails
								brokerData={brokerData}
								edit={() => setEditMode(true)}
								relationshipManager={relationshipManager}
								contractDescription={contractData.description}
							/>
						)
					)}
				</>
			) : (
				<p>{error}</p>
			)}
		</>
	);
};

export default EditBroker;
