import { useEffect, useState } from 'react';
import loadSales from '../../widgets/data/loadSales';
import { EnergySale } from '../../widgets/types';
import { DisplayStatus, displayStatusToBackedStatus, statusToDisplayStatus } from './Status';

const DEFAULT_PAGE_SIZE = 25;
const EXPORT_PAGE_SIZE = 1000;

export interface useSalesProps {
	sort?: string | null;
	statusFilter?: DisplayStatus[] | null;
	brokerFilter?: number | null;
	signDateEnd?: number | null;
	mpxnFilter?: string | null;
	pageSize?: number;
}

/**
 * A hook that fetches energy sales according to the provided parameters
 * @param sort a sort order
 * @param statusFilter an array of DisplayStatus
 * @param brokerFilter a broker ID
 * @param signDateEnd the number of days ago the sale was signed
 * @param mpxnFilter an mpxn to search for
 * @param pageSize the amount of data added with each page
 * @returns sales: an array of energy sales
 */
const useSales = ({
	sort,
	statusFilter,
	brokerFilter,
	signDateEnd,
	mpxnFilter,
	pageSize = DEFAULT_PAGE_SIZE,
}: useSalesProps = {}) => {
	const [sales, setSales] = useState<EnergySale[]>([]);
	const [apiPagesAvailable, setAPIPagesAvailable] = useState<number>(1);
	const [error, setError] = useState<any>(null);
	const [apiPage, setAPIPage] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [uiPage, setUIPage] = useState<number>(1);
	const statusFilterEffect = (statusFilter ?? []).join();
	// If a filter or sort changes, reset to first page.
	useEffect(() => {
		setAPIPage(0);
		setAPIPagesAvailable(1);
		setUIPage(1);
		setSales([]);
	}, [sort, statusFilterEffect, brokerFilter, signDateEnd, mpxnFilter, pageSize]);

	const onLoadMore = () => {
		setUIPage(uiPage + 1);
	};

	const needFetch = sales.length < uiPage * pageSize;

	// If we don't have enough data, and more is avaialable, load more.
	useEffect(() => {
		if (!needFetch) {
			return;
		}
		if (apiPage >= apiPagesAvailable) {
			return;
		}
		setLoading(true);
		loadSales({
			sort: sort ?? null,
			brokerFilter: brokerFilter ?? null,
			signDateEnd: signDateEnd ?? null,
			statusFilter: statusFilter ? statusFilter.flatMap(displayStatusToBackedStatus) : null,
			mpxnFilter: mpxnFilter ?? null,
			page: apiPage,
			pageSize,
		}).then(({ sales: newSales, pages: pagesAvailable, error }) => {
			if (error) {
				setError(error);
				return;
			} else {
				setError(null);
			}
			// Additional filtering to account for difference between client and server statuses.
			if (statusFilter && statusFilter.length) {
				newSales = newSales.filter((sale) => statusFilter.includes(statusToDisplayStatus(sale)));
			}
			setSales([...sales, ...newSales]);
			setAPIPagesAvailable(pagesAvailable!);
			setAPIPage(apiPage + 1);
			setLoading(false);
		});
		// adding the required deps here causes tests to hang forever.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [needFetch, apiPage]);

	const exportSales = async ({
		sort,
		brokerFilter,
		signDateEnd,
		mpxnFilter,
		statusFilter,
	}: {
		sort: string;
		brokerFilter: number | null;
		signDateEnd: number | null;
		mpxnFilter: string | null;
		statusFilter: DisplayStatus[];
	}) => {
		setLoading(true);
		return loadSales({
			sort: sort ?? null,
			brokerFilter: brokerFilter ?? null,
			signDateEnd: signDateEnd ?? null,
			statusFilter: statusFilter ? statusFilter.flatMap(displayStatusToBackedStatus) : null,
			mpxnFilter: mpxnFilter ?? null,
			page: 0,
			pageSize: EXPORT_PAGE_SIZE,
		})
			.then(({ sales: newSales, pages: pagesAvailable, error }) => {
				if (error) {
					setError(error);
					setLoading(false);
					return [];
				} else {
					setError(null);
					setLoading(false);
				}
				// Additional filtering to account for difference between client and server statuses.
				if (statusFilter && statusFilter.length) {
					newSales = newSales.filter((sale) => statusFilter.includes(statusToDisplayStatus(sale)));
				}
				setLoading(false);
				return newSales;
			})
			.catch(() => {
				setLoading(false);
				return [];
			});
	};
	return {
		sales: sales.slice(0, uiPage * pageSize),
		loading,
		error,
		moreAvailable: !loading && (sales.length > uiPage * pageSize || apiPage < apiPagesAvailable),
		onLoadMore,
		exportSales,
	};
};

export default useSales;
