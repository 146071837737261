import React from 'react';
import { EnergySale } from '../../../widgets/types';
import SalesListItem from './SalesListItem';

import './SalesList.scss';

const SalesList = (props: { sales: EnergySale[]; viewAction: (id: number) => void }) => {
	function viewAction(event: any, energySaleId: number) {
		props.viewAction(energySaleId);
	}

	return (
		<ul className="sales-list">
			{props.sales.map((item) => (
				<SalesListItem sale={item} key={item.energySaleId} viewAction={viewAction} />
			))}
		</ul>
	);
};

export default SalesList;
