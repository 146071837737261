import React, { useState, useEffect } from 'react';

import '../../styling.scss';

export interface InputProps {
	value: string;
	label: string;
	placeholder?: string;
	id?: string;
	onChange?: any;
	fullWidth?: boolean;
}

const TextArea = (props: InputProps) => {
	const { value, label, placeholder, id, onChange, fullWidth = false } = props;
	const [displayValue, setDisplayValue] = useState(value);
	const [cursor, setCursor] = useState({ target: { selectionStart: {}, selectionEnd: {} }, value: 0 });

	const displayPlaceholder = placeholder ? placeholder : label;

	useEffect((): void => {
		setDisplayValue(value);
	}, [value]);

	useEffect((): void => {
		cursor.target.selectionStart = cursor.value;
		cursor.target.selectionEnd = cursor.value;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayValue]);

	//add a new type to force "type" to be one of a limited set of strings, text, password, checkbox? - TODO

	const onChangeHandler = (e: any): void => {
		//handle cursor position resetting on change when element re-rendered
		setCursor({ target: e.target, value: e.target.selectionStart });

		if (onChange) {
			onChange(e);
		} else {
			setDisplayValue(e.target.value); //allows component to work as expected if onChange not declared and act as an uncrolled component
		}
	};

	return (
		<div className={`${fullWidth && 'u-full-width'} edfmat_textarea--container edfmat_input--container`}>
			<label className={`${fullWidth && 'u-full-width'} edfmat_input--label`}>
				<textarea
					className="u-full-width edfmat_textarea"
					value={displayValue}
					placeholder={displayPlaceholder}
					onChange={(e) => {
						onChangeHandler(e);
					}}
					id={id}
				/>
				<span className="input-label">{label}</span>
			</label>
		</div>
	);
};

export default TextArea;
