import { API } from '@edfenergy/res-agentui-sso-auth';
import { useDispatch, useSelector } from 'react-redux';
import { ApiPath } from '.';

const LoadBroker = (id: number, forceLoad: boolean = false, errorCallback: any = undefined) => {
	const dispatch = useDispatch();

	const checkStore = (input: any) => {
		const brokerData = input.brokerData;
		return brokerData;
	};

	const brokerResult: any = useSelector(checkStore);

	//refactor check for Api data path into new function and cal asynetrically
	const apiLanded = (apiData: any): void => {
		if (forceLoad) {
			fetchBroker(apiData);
		} else if (!brokerResult || Number(brokerResult.tpiId) !== Number(id)) {
			//check if currently loaded broker is the requested one
			//or if greater than time threshold for stale data before loading - TODO
			fetchBroker(apiData);
		}
	};

	ApiPath(apiLanded);

	const fetchBroker = (apiData: any) => {
		//when getting fresh data, remove old data from store to prevent flash of obselete data
		dispatch({ type: 'brokerData', data: undefined });

		API.get('api', `/tpi/${id}`, {})
			.then((result) => {
				dispatch({ type: 'brokerData', data: result.result });
			})
			.catch((error) => {
				//handle error
				console.warn(error);
				if (errorCallback && error.response) errorCallback(error.response.data.error);
			});
	};
};

export default LoadBroker;
