import React, { useState, useEffect } from 'react';

import '../../styling.scss';

export interface InputProps {
	value: string;
	label: string;
	placeholder?: string;
	id?: string;
	type?: string;
	onChange?: ((e: any) => boolean) | ((e: any) => void);
	fullWidth?: boolean;
	disabled?: boolean;
	datatestid?: string;
}

const Input = (props: InputProps) => {
	const { value, label, placeholder, id, onChange, type = 'text', fullWidth = false, datatestid } = props;
	const [displayValue, setDisplayValue] = useState(value);
	const [cursor, setCursor] = useState({ target: { selectionStart: {}, selectionEnd: {} }, value: 0 });

	const displayPlaceholder = placeholder ? placeholder : label;
	const displayDatatestid = datatestid;

	useEffect((): void => {
		setDisplayValue(value);
	}, [value]);

	useEffect((): void => {
		if (!displayValue) {
			cursor.target.selectionStart = cursor.value;
			cursor.target.selectionEnd = cursor.value;
			return;
		}

		if (isPercentageType(displayValue, cursor)) {
			cursor.target.selectionStart = cursor.value - 1;
			cursor.target.selectionEnd = cursor.value - 1;
		} else if (isCurrencyType(displayValue, cursor)) {
			cursor.target.selectionStart = cursor.value + 1;
			cursor.target.selectionEnd = cursor.value + 1;
		} else {
			cursor.target.selectionStart = cursor.value;
			cursor.target.selectionEnd = cursor.value;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayValue, cursor]);

	//add a new type to force "type" to be one of a limited set of strings, text, password, checkbox? - TODO

	const onChangeHandler = (e: any): void => {
		if (!onChange) {
			setDisplayValue(e.target.value); //allows component to work as expected if onChange not declared and act as an uncrolled component
			setCursor({ target: e.target, value: e.target.selectionStart });
			return;
		}

		if (shouldMoveCursor(onChange, e)) {
			setCursor({ target: e.target, value: e.target.selectionStart });
		} else {
			// this case is only here to stop the cursor moving if the change is unvalidated by the onChange method
			setCursor({ target: e.target, value: e.target.selectionStart - 1 });
		}
	};

	return (
		<div className={`${fullWidth && 'u-full-width'} edfmat_input--container`}>
			<label className="edfmat_input--label">
				<input
					className={`${fullWidth && 'u-full-width'} edfmat_input`}
					type={type}
					value={displayValue}
					placeholder={displayPlaceholder}
					onChange={(e) => {
						onChangeHandler(e);
					}}
					id={id}
					disabled={props.disabled}
					data-testid={displayDatatestid}
				/>
				<span className="input-label">{label}</span>
			</label>
		</div>
	);
};

const shouldMoveCursor = (onChange: Function, e: any) => {
	const valueWillChange = onChange(e);
	const hasBooleanReturnValue = typeof valueWillChange !== 'undefined';
	return (hasBooleanReturnValue && valueWillChange) || !hasBooleanReturnValue;
};

const isPercentageType = (displayValue: string, cursor: any) => {
	return displayValue.slice(-1) === '%' && cursor.value === displayValue.length;
};

const isCurrencyType = (displayValue: string, cursor: any) => {
	return displayValue.substring(0, 1) === '£' && cursor.value === 1;
};

export default Input;
