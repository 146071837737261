import React from 'react';
import { Button, Checkbox, PStyle } from '../../edfm';

export interface filterOption {
	label: string;
	current?: boolean;
	action: Function;
}
export interface columnOption {
	label: string;
	value: boolean;
	action: any;
	hidden?: boolean;
}
export interface PanelProps {
	filterOptions: Array<filterOption>;
	columnOptions: Array<columnOption>;
	allColumns: any; //function to select all columns
}

const FilterBody = (props: PanelProps) => {
	const { columnOptions, allColumns, filterOptions } = props;

	//non standard datatable header property passed through to allow a column to be in the data and exported to the csv, but not show onscreen or in the column select options
	const notHidden = columnOptions.filter((item: columnOption, i: number) => !item.hidden);

	const column1 = notHidden
		.filter((item: columnOption, i: number) => i <= notHidden.length / 2)
		.map((innerItem: columnOption, i: number) => {
			return (
				<span key={i}>
					<Checkbox value={innerItem.value} label={innerItem.label} onChange={innerItem.action} />
					<br />
				</span>
			);
		});

	const column2 = notHidden
		.filter((item: columnOption, i: number) => i > notHidden.length / 2)
		.map((innerItem: columnOption, i: number) => {
			return (
				<span key={i}>
					<Checkbox value={innerItem.value} label={innerItem.label} onChange={innerItem.action} />
					<br />
				</span>
			);
		});

	return (
		<>
			{filterOptions.map((item: filterOption, i: number) => {
				return (
					<PStyle key={i}>
						<Button label={item.label} size="md" disabled={item.current} action={item.action} />
					</PStyle>
				);
			})}

			{filterOptions.length > 0 && columnOptions.length > 0 && <hr></hr>}

			{/* select columns - all columns therefore can be reused*/}
			{allColumns && (
				<PStyle>
					<strong>Select columns:</strong> <Button label="All columns" size="sm" action={allColumns} />
				</PStyle>
			)}

			<table>
				<tbody>
					<tr>
						<td style={{ verticalAlign: 'top' }}>{column1}</td>
						<td style={{ verticalAlign: 'top' }}>{column2}</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

export default FilterBody;
