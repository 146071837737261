import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Row, Column, Loader } from '../../../edfm';

import { brokerDataProps } from '../../../widgets/types';
import DateFormat from '../../../widgets/dateFormat';
import IsEqual from '../../../widgets/isEqual';
import useACL, { Feature } from '../../../widgets/acl';

export interface BrokerProps {
	brokerData: brokerDataProps;
	relationshipManager: string;
	contractDescription: string;
	edit: any;
}

const ViewBrokerDetails = (props: BrokerProps) => {
	const { edit, brokerData, relationshipManager, contractDescription } = props;
	const [refData, setRefData]: [Array<any> | undefined, any] = useState();
	const [brokerDataMapped, setBrokerDataMapped]: [brokerDataProps | undefined, any] = useState();

	const startEdit = () => {
		edit();
	};

	const dataStore = (input: any) => {
		if (input.refData && (!refData || !IsEqual(input.refData, refData))) {
			setRefData(input.refData);
		}
	};

	useEffect(() => {
		if (refData) {
			let repFreq: Array<{ id: string; label: string }> = [];
			let stateFreq: Array<{ id: string; label: string }> = [];
			let statuses: Array<{ id: string; label: string }> = [];
			let channels: Array<{ id: string; label: string }> = [];
			let dataSources: Array<{ id: string; label: string }> = [];

			for (let i = 0; i < refData.length; i++) {
				const dataMap = refData[i].map((item: any) => {
					const { code, description } = item;
					return { id: code, label: description };
				});

				if (refData[i][0].refDataCategory === 'CommissionStmtFreq') {
					stateFreq = dataMap;
				} else if (refData[i][0].refDataCategory === 'StatusRepFreq') {
					repFreq = dataMap;
				} else if (refData[i][0].refDataCategory === 'TPIStatus') {
					statuses = dataMap;
				} else if (refData[i][0].refDataCategory === 'TPIChannel') {
					channels = dataMap;
				} else if (refData[i][0].refDataCategory === 'DataSource') {
					dataSources = dataMap;
				}
			}

			const status = statuses.find((elem: { id: string; label: string }) => elem.id === brokerData.status)?.label;
			const statusRepFreq = repFreq.find(
				(elem: { id: string; label: string }) => elem.id === brokerData.statusRepFreq
			)?.label;
			const commissionStmtFreq = stateFreq.find(
				(elem: { id: string; label: string }) => elem.id === brokerData.commissionStmtFreq
			)?.label;
			const tpiChannel = channels.find(
				(elem: { id: string; label: string }) => elem.id === brokerData.tpiChannel
			)?.label;
			const dataSource = dataSources.find(
				(elem: { id: string; label: string }) => elem.id === brokerData.dataSource
			)?.label;

			setBrokerDataMapped({ ...brokerData, status, statusRepFreq, commissionStmtFreq, tpiChannel, dataSource });
		}
	}, [refData, brokerData]);

	useSelector(dataStore);

	const acl = useACL();

	return (
		<>
			{!brokerDataMapped ? (
				<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
					<Loader />
				</div>
			) : (
				<>
					<Row>
						<Column columns={6}>
							<p>
								<strong>Broker name:</strong> {brokerDataMapped.description}
							</p>
							<p>
								<strong>Relationship manager:</strong> {relationshipManager}
							</p>
							<p>
								<strong>Channel:</strong> {brokerDataMapped.tpiChannel}
							</p>
							<p>
								<strong>Status:</strong> {brokerDataMapped.status}
							</p>
							<p>
								<strong>Status report frequency:</strong> {brokerDataMapped.statusRepFreq}
							</p>
							<p>
								<strong>Commission statement frequency:</strong> {brokerDataMapped.commissionStmtFreq}
							</p>
						</Column>

						<Column columns={6}>
							<p>
								<strong>Broker code:</strong> {brokerDataMapped.tpiBusinessKey}
								<br />
								<strong>Data source:</strong> {brokerDataMapped.dataSource}
								<br />
								{brokerDataMapped.PurchaseOrder && brokerDataMapped.PurchaseOrder.purchaseOrderReference && (
									<>
										<strong>Purchase order:</strong> {brokerDataMapped.PurchaseOrder.purchaseOrderReference}
										<br />
									</>
								)}
								{brokerDataMapped.vendorId && (
									<>
										<strong>Vendor Id:</strong> {brokerDataMapped.vendorId}
										<br />
									</>
								)}
								<strong>Contract version:</strong> {contractDescription}
								<br />
								{brokerDataMapped.isSelfBilling && (
									<>
										<strong>Self billing</strong> <br />
									</>
								)}
							</p>

							{brokerDataMapped.nextStatementDueDate && (
								<p>
									<strong>Next statement due:</strong> <DateFormat date={brokerDataMapped.nextStatementDueDate} />
								</p>
							)}

							{brokerDataMapped.notes && brokerDataMapped.notes !== '' && (
								<p>
									<strong>Notes:</strong> {brokerDataMapped.notes}
								</p>
							)}
						</Column>
					</Row>

					<Row>
						{acl(Feature.EditBroker) && (
							<span className="u-pull-right">
								<input
									className="edfmat_button edfmat_button--primary"
									onClick={startEdit}
									type="button"
									value="Edit broker details"
								/>
							</span>
						)}

						<p className="additional-details">
							{brokerDataMapped.updatedTs !== brokerDataMapped.createdTs && (
								<>
									<strong>Last updated:</strong> <DateFormat date={brokerDataMapped.updatedTs} /> by{' '}
									{brokerDataMapped.updatedId}
									<br />
								</>
							)}
							<strong>Created:</strong> <DateFormat date={brokerDataMapped.createdTs} /> by {brokerDataMapped.createdId}
							<br />
						</p>
					</Row>
				</>
			)}
		</>
	);
};

export default ViewBrokerDetails;
