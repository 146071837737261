const convertToCSV = (rows: Array<Array<string | number>>) => {
	const columns = rows[0].length;

	let str = '';

	for (let i = 0; i < rows.length; i++) {
		let line = '';
		for (var ii = 0; ii < columns; ii++) {
			let cellContent = rows[i][ii];

			//handle quotes and commas for strings, but leave numbers as is
			cellContent = typeof cellContent === 'number' ? cellContent : `"${cellContent.toString().replace(/"/g, '""')}"`;

			line = (line === '' ? line : line + ',') + cellContent;
		}

		str += line + '\r\n';
	}
	return str;
};

const exportCSVFile = (items: Array<Array<number | string>>, fileTitle?: string, headers?: Array<number | string>) => {
	if (headers) {
		items.unshift(headers);
	}

	const csv = convertToCSV(items);

	const exportedFilename = fileTitle ? fileTitle + '.csv' : 'download.csv';

	const BOM = new Uint8Array([0xef, 0xbb, 0xbf]); //generate a Blob representing that text encoded as UTF-8 to properly handle chars like "£"
	const blob = new Blob([BOM, csv], { type: 'text/csv;charset=utf-8;' });
	// Ignored due to typescript compile error. Documentation says we shouldn't be using this anyway.
	// A ticket has been created to look into a fix https://digitalcentralprojects.edfenergy.com/browse/SMEC-824
	// @ts-ignore
	if (navigator.msSaveBlob) {
		// IE 10+
		// @ts-ignore
		navigator.msSaveBlob(blob, exportedFilename);
	} else {
		const link = document.createElement('a');
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', exportedFilename);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};

export default exportCSVFile;
