import React, { useState, useEffect } from 'react';
import { Loader, DataTable } from '../../../edfm';
import { useHistory, useParams } from 'react-router-dom';

import IsEqual from '../../../widgets/isEqual';

import { useSelector } from 'react-redux';
import { LoadBroker } from '../../../widgets/data';
import { contactProps, brokerDataProps, URLParams } from '../../../widgets/types';
import useACL, { Feature } from '../../../widgets/acl';

const BrokerContacts = () => {
	const history = useHistory();

	const [loadingContacts, setLoadingContacts] = useState(true);
	const [brokerData, setBrokerData]: [brokerDataProps | undefined, any] = useState();
	const [contacts, setContacts]: any = useState();

	const { id } = useParams<URLParams>();

	LoadBroker(Number(id)); //to bust cache and reload data (only) if cleared during an update / deletion

	const clickHandler = (contactId: number): void => {
		history.push(`/edit-broker/${id}/contacts/edit-contact/${contactId}`);
	};

	const addNew = (): void => {
		history.push(`/edit-broker/${id}/contacts/add-contact`);
	};

	useEffect(() => {
		if (brokerData) {
			if (brokerData.Contacts) {
				const filtered = brokerData.Contacts.filter((item: any, i: number) => {
					return item.isActive;
				});
				const mapped = filtered.map((item: contactProps, i: number) => {
					const { contactName, email, contactType, contactId } = item;

					return {
						clickAction: (): void => {
							contactId !== undefined ? clickHandler(contactId) : console.warn('undefined contactId');
						},
						columns: [contactName, email.replace(',', ', '), contactType],
					};
				});

				setContacts(mapped);
			} else {
				setContacts([]);
			}
			setLoadingContacts(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brokerData]);

	const dataStore = (input: any): void => {
		if (input.brokerData) {
			if (!IsEqual({ ...input.brokerData }, { ...brokerData })) {
				setBrokerData(input.brokerData);
			}
		}
	};

	useSelector(dataStore);

	const acl = useACL();

	return (
		<div className="container">
			<div className="row">
				<div className="twelve column">
					{loadingContacts ? (
						<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
							<Loader />
						</div>
					) : (
						<>
							{brokerData && <h4>{brokerData.description}</h4>}
							<DataTable
								headings={[{ label: 'Name' }, { label: 'Email' }, { label: 'Type' }]}
								body={contacts}
								clickable={true}
								pagination={false}
							/>
							{acl(Feature.EditBroker) && (
								<input
									className={`edfmat_button edfmat_button--sm list-broker--addnew`}
									type="button"
									onClick={addNew}
									value="+ new contact"
								/>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default BrokerContacts;
