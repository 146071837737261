import React from 'react';
import { DateTime, Interval } from 'luxon';

import { EnergySale } from '../../../widgets/types';

import './SalesListItem.scss';
import { Button } from '../../../edfm';
import StatusBadge from './StatusBadge';

export function daysAgo(date: DateTime): string {
	const today = DateTime.local();
	let interval: Interval = Interval.fromDateTimes(date, today);

	if (date < today) {
		// past
		interval = Interval.fromDateTimes(date, today);
		if (interval.length('days') >= 1 && interval.length('days') < 2) {
			return 'Yesterday';
		} else if (interval.length('days') < 1) {
			return 'Today';
		} else {
			return `${Math.floor(Number(interval.length('days')))} days ago`;
		}
	} else {
		//  future
		interval = Interval.fromDateTimes(today, date);
		if (interval.length('days') < 1) {
			return 'Tomorrow';
		} else {
			return `In ${Math.floor(Number(interval.length('days'))) + 1} days`;
		}
	}
}

const SalesListItem = (props: { sale: EnergySale; viewAction: (event: any, energySaleId: number) => void }) => {
	const viewAction = (event: any) => props.viewAction(event, props.sale.energySaleId);

	return (
		<li className="sales-list-item">
			<div className="col-medium">
				<strong>{props.sale.organizationName1}</strong>
			</div>
			<div className="col-medium">
				<StatusBadge sale={props.sale} />
			</div>
			<div className="col-short">{daysAgo(DateTime.fromISO(props.sale.signDate))}</div>
			<div className="col-short">{DateTime.fromISO(props.sale.createdTs).toFormat('dd/MM/yyyy HH:mm:ss')}</div>
			<div className="flex-column col-long">
				<div>{props.sale.product}</div>
				<div>{props.sale.mpxn}</div>
			</div>
			<div className="flex-column col-short">
				<div>{props.sale.salesRepresentative}</div>
				<div>{props.sale.supplyAddressPostcode ? props.sale.supplyAddressPostcode : 'POSTCODE'}</div>
			</div>
			<div className="buttons col-medium">
				<div>
					<Button label={'View'} action={viewAction} />
				</div>
			</div>
		</li>
	);
};

export default SalesListItem;
