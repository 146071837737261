import { API } from '@edfenergy/res-agentui-sso-auth';
import { useDispatch, useSelector } from 'react-redux';
import { ApiPath } from '.';

const LoadCommissionStatement = (id: number, statement: number) => {
	//statement etc to be defined and specified - TODO

	const dispatch = useDispatch();

	const checkStore = (input: any) => {
		const statementData = input.statementData;
		return statementData;
	};

	const statementResult: any = useSelector(checkStore);

	//ensure spi path has been loaded from json file before fetching data
	const apiLanded = (apiData: any): void => {
		if (!statementResult || Number(statementResult.tpiId) !== Number(id)) {
			//check if currently loaded data is for the requested broker
			//or if greater than time threshold for stale data before loading - TODO
			fetchStatus(apiData);
		}
	};

	ApiPath(apiLanded);

	const fetchStatus = (apiData: any) => {
		//when getting fresh data, remove old data from store to prevent flash of obselete data
		dispatch({ type: 'statementData', data: undefined });

		API.get('api', `/tpi/${id}/commission-statement/${statement}`, {}).then(
			(result) => {
				dispatch({ type: 'statementData', data: result.result });
			},
			(error) => {
				//handle error
				console.warn(error);
			}
		);
	};
};

export default LoadCommissionStatement;
