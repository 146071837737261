import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import IsEqual from '../isEqual';
import { API } from '@edfenergy/res-agentui-sso-auth';

const LoadAgents = (forceLoad: boolean = false) => {
	const dispatch = useDispatch();

	const checkStore = (input: any) => {
		const agentsData = input.agentsData;
		return agentsData;
	};

	const agentResult: any = useSelector(checkStore);

	const [apiData, setApiData]: [any, any] = useState();

	const dataStore = (input: any) => {
		if ((input.apiData && !apiData) || (input.apiData && !IsEqual(input.apiData, apiData))) {
			setApiData(input.apiData);
		}
	};

	useSelector(dataStore);

	useEffect(() => {
		if (apiData) {
			if (forceLoad) {
				fetchAgents();
			} else if (!agentResult) {
				//check if currently loaded broker is the requested one
				//or if greater than time threshold for stale data before loading - TODO
				fetchAgents();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiData]);

	const fetchAgents = () => {
		API.get('api', '/relationship-manager', {}).then(
			(result) => {
				dispatch({ type: 'agentsData', data: result.result });
			},
			(error) => {
				//handle error
				console.warn(error);
			}
		);
	};
};

export default LoadAgents;
