import { API } from '@edfenergy/res-agentui-sso-auth';

const SaveCommissionTerms = (path: string, body: any, successFunc: any, failFunc?: any) => {
	const errorHandler = (error: string) => {
		if (failFunc) {
			failFunc(error);
		} else {
			console.warn(error);
		}
	};

	// fetch(`${path}`, {
	// 	method: 'POST',
	// 	cache: 'no-cache',
	// 	body: body,
	// })
	// 	.then((res) => res.json())
	// 	.then(

	API.post('api', path, { body })
		.then((result) => {
			//should receive commissionTermId and updatedTs
			//use these to verify update / save occurred and to update screen so second update can occur with updated timestamp

			if (result.result && result.result.error) {
				errorHandler('Unable to update commission terms: ' + result.result.error);
			} else if (result.result && result.result.message) {
				errorHandler('Unable to update commission terms: ' + result.result.message);
			} else if (result && result.error) {
				errorHandler('Unable to update commission terms: ' + result.error);
			} else if (result && result.message) {
				errorHandler('Unable to update commission terms: ' + result.message);
			} else if (result.result && result.result.commissionTermId && result.result.updatedTs) {
				successFunc(result.result.commissionTermId, result.result.updatedTs);
			} else {
				errorHandler('Unable to update commission terms.');
			}
		})
		.catch((error) => {
			if (error.response) {
				console.log(error.response.data.error);
				//handle error
				errorHandler('Unable to update commission terms: ' + error.response.data.error);
			} else {
				errorHandler('Unable to update commission terms');
			}
		});
};

export default SaveCommissionTerms;
