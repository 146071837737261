import { DateTime } from 'luxon';
import moment from 'moment';
import React from 'react';
import { Button } from '../../../edfm';
import { EnergySale } from '../../../widgets/types';
import { DisplayStatus, statusToDisplayStatus } from '../Status';
import useACL, { Feature } from '../../../widgets/acl';

const isGSPUpdate = (sale: EnergySale) => {
	if (sale.EnergySaleActionLog) {
		const logs = sale.EnergySaleActionLog[sale.EnergySaleActionLog.length - 1].logText;
		const activityLogs = logs.split(';');
		return (
			activityLogs[activityLogs.length - 1].includes('GSP Code Not Found for postal code') ||
			activityLogs[activityLogs.length - 1].includes('No LIVE PriceLaunchProduct record found')
		);
	}
	return false;
};
export const canUpdateGSP = (sale: EnergySale) => {
	const displayStatus = statusToDisplayStatus(sale);
	if (displayStatus === DisplayStatus.Suspended && isGSPUpdate(sale)) {
		return true;
	}
	return false;
};

export const canReturn = (sale: EnergySale) => {
	const displayStatus = statusToDisplayStatus(sale);
	if (
		displayStatus === DisplayStatus.Suspended ||
		displayStatus === DisplayStatus.Unprocessed ||
		displayStatus === DisplayStatus.SAPProcessing ||
		displayStatus === DisplayStatus.Processing
	) {
		return true;
	}
	if (
		displayStatus === DisplayStatus.ContractEnded &&
		sale.EnergySaleDetail &&
		DateTime.fromISO(sale.EnergySaleDetail.contractStartDate) > DateTime.local()
	) {
		return true;
	}
	return false;
};

const canUnreturn = (sale: EnergySale) => {
	const displayStatus = statusToDisplayStatus(sale);
	if (displayStatus === DisplayStatus.Returned) {
		return true;
	}
	return false;
};

const LogTab = (props: {
	sale: EnergySale;
	returnSale: (id: number, previousStatus: string, updatedTs: string) => void;
	unreturnSale: (energySaleId: number) => void;
	gsp: (id: number, sale: EnergySale) => void;
}) => {
	const returnAction = () =>
		props.returnSale(
			props.sale.energySaleId,
			props.sale.EnergySaleDetail?.energySaleStatus ?? '',
			props.sale.EnergySaleDetail?.updatedTs ?? ''
		);

	const acl = useACL();
	const gspAction = () => props.gsp(props.sale.energySaleId, props.sale);

	const unreturnAction = () => props.unreturnSale(props.sale.energySaleId);

	return (
		<div>
			<div className="flex row">
				<div className="flex-one">
					<h5>Activity Log</h5>
					{props.sale.EnergySaleActionLog &&
						props.sale.EnergySaleActionLog.map((activityLog) => (
							<div key={activityLog.energySaleActionLogId}>
								Last update: {moment(activityLog.updatedTs).format('DD/MM/YYYYTHH:mm:ss.SSS')} <br />
								Log:{' '}
								{activityLog.logText.split(';').map((log) => (
									// RegEx used to find all instances of YYYY/MM/DD pattern and replace with DD/MM/YYYY, capture groups are: YYYY = $1, MM= $2, DD = $3.
									<div> - {log.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$3/$2/$1')}</div>
								))}
								<hr />
							</div>
						))}
				</div>
			</div>
			<div className="flex row">
				<div className="flex-one">
					<div className="logtab_buttons">
						{!acl(Feature.ReadOnly) && canUpdateGSP(props.sale) && (
							<Button label={'Update GSP'} action={gspAction} primary />
						)}
						{!acl(Feature.ReadOnly) && canReturn(props.sale) && (
							<Button label={'Return'} action={returnAction} primary />
						)}
						{acl(Feature.Unreturn) && canUnreturn(props.sale) && (
							<Button label={'Unreturn'} action={unreturnAction} primary />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default LogTab;
