import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DailyReport from './dailyStatusReport';
import CommissionStatementHolder from './commissionStatementHolder';
import { TabBar, Row } from '../../edfm';
import { LoadBroker } from '../../widgets/data';
import IsEqual from '../../widgets/isEqual';
import { URLParams } from '../../widgets/types';

const Report = () => {
	const history = useHistory();
	const { id, report, statementId } = useParams<URLParams>();

	const [brokerName, setBrokerName]: [string | undefined, any] = useState();
	const [error, setError]: [boolean | string, any] = useState(false);

	function displayError(error: any) {
		setError(error);
	}

	LoadBroker(Number(id), false, displayError);

	const dataStore = (input: any): void => {
		if (input.brokerData) {
			if (!brokerName || !IsEqual(input.brokerData.description, brokerName)) {
				setBrokerName(input.brokerData.description);
			}
		}
	};

	useSelector(dataStore);

	const updateSection = (newSection: string): void => {
		history.push(newSection);
	};

	return (
		<>
			{error === false ? (
				<>
					<TabBar
						options={[
							{
								label: 'Status report',
								action: () => {
									updateSection(`/reports/${id}/status-report`);
								},
								active: !report || report === 'status-report',
							},
							{
								label: 'Commission statement',
								action: () => {
									updateSection(`/reports/${id}/commission-statement`);
								},
								active: report === 'commission-statement',
							},
						]}
					/>

					<h4>{brokerName}</h4>

					<Row>
						<p>
							<a
								href="./"
								onClick={(e) => {
									e.preventDefault();
									updateSection(`/edit-broker/${id}`);
								}}
							>
								Broker details
							</a>
						</p>
					</Row>

					{!report || report === 'status-report' ? (
						<DailyReport id={Number(id)} />
					) : (
						<CommissionStatementHolder id={Number(id)} statementId={Number(statementId)} />
					)}
				</>
			) : (
				<p>{error}</p>
			)}
		</>
	);
};

export default Report;
