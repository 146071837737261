import { getCurrentSesssion } from './getCurrentSession';

export const isMemberOf = async (groups: string | string[]): Promise<boolean> =>
	new Promise((resolve) => {
		if (!groups) {
			resolve(false);
			console.error('[isMemberOf] Groups are not defined', groups);
		}

		let _groups: string[] = typeof groups === 'string' ? [groups] : groups;

		getCurrentSesssion().then((session) => {
			const userGroups: string[] = session.getAccessToken().payload['cognito:groups'];

			resolve(_groups.some((gr) => userGroups.some((ugr) => ugr.toLowerCase().indexOf(gr.toLowerCase()) !== -1)));
		});
	});
