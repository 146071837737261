import React, { useState, useEffect } from 'react';
import { Loader, DataTable, Button, NoAccess } from '../../edfm';
import { useHistory } from 'react-router-dom';
import { LoadBrokers } from '../../widgets/data';
import { useDispatch, useSelector } from 'react-redux';
import IsEqual from '../../widgets/isEqual';
import useACL, { Feature } from '../../widgets/acl';

const Reports = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [loadingBrokers, setLoadingBrokers] = useState(true);
	const [brokersData, setBrokersData]: any = useState();
	const [tableData, setTableData]: any = useState();

	const reportHandler = (id: string) => {
		history.push(`/reports/${id}/status-report`);
	};

	const statementHandler = (id: string) => {
		history.push(`/reports/${id}/commission-statement`);
	};

	const dataStore = (input: any) => {
		if (input.brokersData) {
			if (!IsEqual({ ...brokersData }, { ...input.brokersData })) {
				setBrokersData(input.brokersData);
			}
		}
	};

	useEffect(() => {
		if (brokersData) {
			const mapped = brokersData.map((item: any, i: number) => {
				const { description, tpiBusinessKey, tpiId } = item;

				const entry = [
					<>
						<strong>{description}</strong> ({tpiBusinessKey}){' '}
					</>,
					<div style={{ textAlign: 'right' }}>
						<Button label="Status report" size="sm" action={() => reportHandler(tpiId)} />
						<Button label="Commission statement" size="sm" action={() => statementHandler(tpiId)} />
					</div>,
				];

				return {
					searchableText: `${description} ${tpiBusinessKey}`,
					columns: entry,
				};
			});

			setTableData(mapped);
			setLoadingBrokers(false);

			// clear status data cache
			dispatch({ type: 'statusData', data: [] });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brokersData]);

	useSelector(dataStore);

	LoadBrokers();

	const acl = useACL();
	if (!acl(Feature.ViewBroker)) return <NoAccess />;

	return (
		<div className="container">
			<div className="row">
				<div className="twelve column">
					{loadingBrokers ? (
						<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
							<Loader />
						</div>
					) : (
						<DataTable body={tableData} search={true} pagination={true} />
					)}
				</div>
			</div>
		</div>
	);
};

export default Reports;
