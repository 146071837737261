import moment from 'moment';
import React from 'react';
import { Input } from '../../../edfm';
import { useRefDataTranslate } from '../../../widgets/refDataTranslate';
import { EnergySale } from '../../../widgets/types';

function showBlankIfEmpty(aString: string) {
	return aString ? aString : ' ';
}

const BillingTab = (props: { sale: EnergySale }) => {
	const refDataTranslate = useRefDataTranslate();

	return (
		<div className="flex row">
			<div className="flex-one">
				<h5>Billing Address</h5>
				<ul>
					<li>
						<Input
							label="Building Number"
							fullWidth
							value={showBlankIfEmpty(props.sale.addressBuildingNumber)}
							disabled
						/>
					</li>
					<li>
						<Input label="Building Name" fullWidth value={showBlankIfEmpty(props.sale.addressBuildingName)} disabled />
					</li>
					<li>
						<Input label="Street" fullWidth value={showBlankIfEmpty(props.sale.addressStreet)} disabled />
					</li>
					<li>
						<Input label="Town" fullWidth value={showBlankIfEmpty(props.sale.addressTown)} disabled />
					</li>
					<li>
						<Input label="County" fullWidth value={showBlankIfEmpty(props.sale.addressCounty)} disabled />
					</li>
					<li>
						<Input label="Post Code" fullWidth value={showBlankIfEmpty(props.sale.addressPostcode)} disabled />
					</li>
				</ul>
			</div>
			<div className="flex-one">
				<h5>Billing Details</h5>
				<ul>
					<li>
						<Input
							label="Payment Type"
							fullWidth
							value={showBlankIfEmpty(refDataTranslate('Payment Type', props.sale.paymentType))}
							disabled
						/>
					</li>
					<li>
						<Input
							label="Payment Date"
							fullWidth
							value={moment(showBlankIfEmpty(props.sale.preferredPaymentDate)).format('DD/MM/YYYY')}
							disabled
						/>
					</li>
					<li>
						<Input label="SPA" fullWidth value={showBlankIfEmpty(props.sale.paymentAmount.toString())} disabled />
					</li>
					<li>
						<Input
							label="Bill Notification"
							fullWidth
							value={showBlankIfEmpty(refDataTranslate('Bill notification', props.sale.billNotification))}
							disabled
						/>
					</li>
					<li>
						<Input
							label="Read Reminder"
							fullWidth
							value={showBlankIfEmpty(refDataTranslate('Read reminder', props.sale.readReminder))}
							disabled
						/>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default BillingTab;
