import React, { useState, useEffect } from 'react';

import '../../styling.scss';

export interface InputProps {
	value?: boolean;
	label?: string;
	id?: string;
	onChange?: any;
	fullWidth?: boolean;
}

const Checkbox = (props: InputProps) => {
	const { value = false, label = '', id, onChange, fullWidth = false } = props;
	const [checked, setChecked] = useState(value);

	useEffect((): void => {
		setChecked(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const onChangeHandler = (e: any): void => {
		//handle cursor position resetting on change when element re-rendered
		if (onChange) {
			onChange(e);
		} else {
			//allows component to work as expected if onChange not declared and act as an uncrolled component
			setChecked((oldValue) => !oldValue);
		}
	};

	return (
		<div className={`${fullWidth && 'u-full-width'} edfmat_input--container`}>
			<label className="edfmat_input--label">
				<input
					className={`${fullWidth && 'u-full-width'} `}
					type="checkbox"
					checked={checked}
					onChange={(e) => {
						onChangeHandler(e);
					}}
					id={id}
				/>
				&nbsp;
				<span>{label}</span>
			</label>
		</div>
	);
};

export default Checkbox;
