import React from 'react';

export interface ChipProps {
	label: string;
	action?: any;
	showClose?: boolean;
}

const Chip = (props: ChipProps) => {
	//label, icon - todo, close, action
	const { label, action, showClose = false } = props;

	const fireAction = () => {
		if (action) {
			action();
		}
	};

	return (
		<span
			className={`edfmat_chip${action ? ' edfmat_chip--button' : ''} ${showClose ? 'edfmat_chip--close' : ''}`}
			onClick={() => fireAction()}
		>
			{label}
			{showClose && <span className="edfmat_chip_close-btn">x</span>}
		</span>
	);
};

export default Chip;
