import { API } from '@edfenergy/res-agentui-sso-auth';
import { useDispatch, useSelector } from 'react-redux';
import { ApiPath } from '../index';

export const LoadTransactions = (energySaleId: number, forceLoad: boolean = false) => {
	const dispatch = useDispatch();

	const checkStore = (input: any) => {
		return input.transactionData;
	};

	const existingTransactions: any = useSelector(checkStore);

	const apiLanded = (): void => {
		if (forceLoad) {
			fetchTransactions();
		} else if (!existingTransactions || Number(existingTransactions.energySaleId) !== Number(energySaleId)) {
			fetchTransactions();
		}
	};

	ApiPath(apiLanded);

	const fetchTransactions = () => {
		dispatch({ type: 'transactionData', data: undefined });

		API.get('api', `/v1/energy-sale/${energySaleId}/transactions`, {}).then(
			(result) => {
				dispatch({ type: 'transactionData', data: { ...result, energySaleId } });
			},
			(error) => {
				dispatch({ type: 'transactionData', data: { error, transactions: [], total: 0, energySaleId } });
			}
		);
	};
};
