import { API } from '@edfenergy/res-agentui-sso-auth';

const SaveContact = (path: string, method: string, body: any, successFunc: any, failFunc?: any) => {
	const errorHandler = (error: string) => {
		if (failFunc) {
			failFunc(error);
		} else {
			console.warn(error);
		}
	};

	let APICallerFunction: (apiName: any, path: any, init: any) => Promise<any>;

	if (method === 'POST') {
		APICallerFunction = API.post.bind(API);
	} else if (method === 'PUT') {
		APICallerFunction = API.put.bind(API);
	} else {
		throw new Error('Method must be POST or PUT');
	}

	APICallerFunction('api', path, {
		body,
	})
		.then((result) => {
			//should receive contactId (for create) and updatedTs
			//use these to verify update / save occurred and to update screen so second update can occur with updated timestamp

			if (result.result && result.result.error) {
				errorHandler('Unable to update broker details: ' + result.result.error);
			} else if (result.result && result.result.message) {
				errorHandler('Unable to update broker details: ' + result.result.message);
			} else if (result && result.error) {
				errorHandler('Unable to update broker details: ' + result.error);
			} else if (result && result.message) {
				errorHandler('Unable to update broker details: ' + result.message);
			} else if (result.result && result.result.updatedTs) {
				successFunc(result.result.updatedTs, result.result.contactId);
			} else {
				errorHandler('Unable to update broker details.');
			}
		})
		.catch((error) => {
			if (error.response) {
				console.log(error.response.data.error);
				//handle error
				errorHandler('Unable to update broker details: ' + error.response.data.error);
			} else {
				errorHandler('Unable to update broker details');
			}
		});
};

export default SaveContact;
