import Button from '../../edfm/button/button';
import React, { SyntheticEvent, useState } from 'react';
import ApiPath from '../../widgets/data/apiPath';
import { API } from '@edfenergy/res-agentui-sso-auth';
import { Loader, NoAccess } from '../../edfm';
import useACL, { Feature } from '../../widgets/acl';

interface DataItem {
	title: string;
	key: string;
	value?: string;
}

interface SaleItem {
	[key: string]: string;
}

const headers: DataItem[] = [
	{
		title: 'Multi site ref',
		key: 'multiSiteRef',
	},
	{
		title: 'Business Partner ID',
		key: 'businessPartnerId',
	},
	{
		title: 'Organization: Name 1',
		key: 'organizationName1',
	},
	{
		title: 'Organization: Name 2',
		key: 'organizationName2',
	},
	{
		title: 'Standard Address 14 Free Text',
		key: 'addressFreeText',
	},
	{
		title: 'Standard Address 14 Building Number',
		key: 'addressBuildingNumber',
	},
	{
		title: 'Standard Address 14 DPS',
		key: 'addressDPS',
	},
	{
		title: 'Standard Address 14 Sub Building',
		key: 'addressSubBuilding',
	},
	{
		title: 'Standard Address 14 Building Name',
		key: 'addressBuildingName',
	},
	{
		title: 'Standard Address 14 Street',
		key: 'addressStreet',
	},
	{
		title: 'Standard Address 14 Thoroughfare',
		key: 'addressThoroughfare',
	},
	{
		title: 'Standard Address 14 Locality',
		key: 'addressLocality',
	},
	{
		title: 'Standard Address 14 District',
		key: 'addressDistrict',
	},
	{
		title: 'Standard Address 14 Town',
		key: 'addressTown',
	},
	{
		title: 'Standard Address 14 County',
		key: 'addressCounty',
	},
	{
		title: 'Standard Address 14 Postcode',
		key: 'addressPostcode',
	},
	{
		title: 'Standard Address 14 PO Box',
		key: 'addressPOBox',
	},
	{
		title: 'Standard Address 14 Country',
		key: 'addressCountry',
	},
	{
		title: 'BP Type',
		key: 'bpType',
	},
	{
		title: 'DPA: Internal Comms',
		key: 'dpaInternalComms',
	},
	{
		title: 'DPA: External Comms',
		key: 'dpaExternalComms',
	},
	{
		title: 'DPA: Mail',
		key: 'dpaMail',
	},
	{
		title: 'DPA: Telephone',
		key: 'dpaTelephone',
	},
	{
		title: 'DPA: Email/SMS',
		key: 'dpaEmailSMS',
	},
	{
		title: 'DPA: Face to Face',
		key: 'dpaFaceToFace',
	},
	{
		title: 'Preferred Call Time From',
		key: 'preferredCallTimeFrom',
	},
	{
		title: 'Preferred Call Time To',
		key: 'preferredCallTimeTo',
	},
	{
		title: 'Bill Type',
		key: 'billType',
	},
	{
		title: 'Bank Account Number',
		key: 'bankAccountNumber',
	},
	{
		title: 'Bank Account Holder Name',
		key: 'bankAccountHolderName',
	},
	{
		title: 'Bank Sort Code',
		key: 'bankSortCode',
	},
	{
		title: 'Telephone number',
		key: 'telephoneNumber',
	},
	{
		title: 'Fax number',
		key: 'faxNumber',
	},
	{
		title: 'Mobile number',
		key: 'mobileNumber',
	},
	{
		title: 'E-Mail',
		key: 'email',
	},
	{
		title: 'Sole Trader',
		key: 'soleTrader',
	},
	{
		title: 'Partnership',
		key: 'partnership',
	},
	{
		title: 'Plc',
		key: 'plc',
	},
	{
		title: 'Ltd',
		key: 'ltd',
	},
	{
		title: 'Other',
		key: 'other',
	},
	{
		title: 'Business Reg Number',
		key: 'businessRegNumber',
	},
	{
		title: 'Trading as',
		key: 'tradingAs',
	},
	{
		title: 'VAT Number',
		key: 'vatNumber',
	},
	{
		title: 'SIC code',
		key: 'sicCode',
	},
	{
		title: 'Contact Person: Title',
		key: 'contactTitle',
	},
	{
		title: 'Contact Person: First Name',
		key: 'contactFirstName',
	},
	{
		title: 'Contact Person: Last Name',
		key: 'contactLastName',
	},
	{
		title: 'Contact Person: Department',
		key: 'contactDepartment',
	},
	{
		title: 'Contact Person: Function',
		key: 'contactFunction',
	},
	{
		title: 'Contact Person: Telephone number',
		key: 'contactTelephone',
	},
	{
		title: 'Contact Person: Mobile number',
		key: 'contactMobile',
	},
	{
		title: 'Contact Person: Fax',
		key: 'contactFax',
	},
	{
		title: 'Contact Person: E-Mail',
		key: 'contactEmail',
	},
	{
		title: 'Brand',
		key: 'brand',
	},
	{
		title: 'Payment Type',
		key: 'paymentType',
	},
	{
		title: 'Bill notification',
		key: 'billNotification',
	},
	{
		title: 'Read reminder',
		key: 'readReminder',
	},
	{
		title: 'Supply Address 14 Free Text',
		key: 'supplyAddressFreeText',
	},
	{
		title: 'Supply Address 14 PO Box',
		key: 'supplyAddressPOBox',
	},
	{
		title: 'Supply Address 14 Building Number',
		key: 'supplyAddressBuildingNumber',
	},
	{
		title: 'Supply Address 14 Sub Building',
		key: 'supplyAddressSubBuilding',
	},
	{
		title: 'Supply Address 14 Building Name',
		key: 'supplyAddressBuildingName',
	},
	{
		title: 'Supply Address 14 Street',
		key: 'supplyAddressStreet',
	},
	{
		title: 'Supply Address 14 Thoroughfare',
		key: 'supplyAddressThoroughfare',
	},
	{
		title: 'Supply Address 14 Locality',
		key: 'supplyAddressLocality',
	},
	{
		title: 'Supply Address 14 District',
		key: 'supplyAddressDistrict',
	},
	{
		title: 'Supply Address 14 Town',
		key: 'supplyAddressTown',
	},
	{
		title: 'Supply Address 14 County',
		key: 'supplyAddressCounty',
	},
	{
		title: 'Supply Address 14 Postcode',
		key: 'supplyAddressPostcode',
	},
	{
		title: 'Supply Address 14 DPS (House Number Supplement)',
		key: 'supplyAddressDPS',
	},
	{
		title: 'Supply Address 14 Country',
		key: 'supplyAddressCountry',
	},
	{
		title: 'MPXN',
		key: 'mpxn',
	},
	{
		title: 'Product',
		key: 'product',
	},
	{
		title: 'Configurable attribute - Tariff',
		key: 'tariff',
	},
	{
		title: 'Contract Reason',
		key: 'contractReason',
	},
	{
		title: 'Sign Date',
		key: 'signDate',
	},
	{
		title: 'Channel',
		key: 'channel',
	},
	{
		title: 'Payment Amount',
		key: 'paymentAmount',
	},
	{
		title: 'External Reference Number',
		key: 'externalReferenceNumber',
	},
	{
		title: 'Sales Representative ',
		key: 'salesRepresentative',
	},
	{
		title: 'Campaign ID (Sales reference)',
		key: 'campaignId',
	},
	{
		title: 'Prefered payment date',
		key: 'preferredPaymentDate',
	},
	{
		title: 'PED',
		key: 'ped',
	},
	{
		title: 'Configurable attribute - Promotional Code 1',
		key: 'promotionalCode1',
	},
	{
		title: 'Configurable attribute - Promotional Code 2',
		key: 'promotionalCode2',
	},
	{
		title: 'Configurable attribute - Promotional Code 3',
		key: 'promotionalCode3',
	},
	{
		title: 'Configurable attribute - Promotional Code 4',
		key: 'promotionalCode4',
	},
	{
		title: 'Configurable attribute - Promotional Code 5',
		key: 'promotionalCode5',
	},
	{
		title: 'Credit Score',
		key: 'creditScore',
	},
	{
		title: 'SVL no.',
		key: 'svlNumber',
	},
	{
		title: 'URN number',
		key: 'urn',
	},
	{
		title: 'In Contract Flag',
		key: 'inContract',
	},
	{
		title: 'Old Contract End Date',
		key: 'oldContractEndDate',
	},
	{
		title: 'Existing Supplier ID',
		key: 'existingSuplierId',
	},
	{
		title: 'Annual Consumption',
		key: 'annualConsumption',
	},
	{
		title: 'Profile Class',
		key: 'profileClass',
	},
	{
		title: 'Meter Time Switch Code',
		key: 'meterTimeSwitchCode',
	},
	{
		title: 'Line Loss Factor',
		key: 'lineLossFactor',
	},
	{
		title: 'Letter Of Authority',
		key: 'letterOfAuthority',
	},
	{
		title: 'Commission Cost',
		key: 'commissionCost',
	},
	{
		title: 'Sales Channel Contract Type',
		key: 'salesChannelContractType',
	},
	{
		title: 'ADR Number',
		key: 'adrNumber',
	},
];

/**
 * Split the CSV string into CSV lines.
 * @param csvLines a CSV multiline string (the content of the textarea)
 * @return an array of a CSV single line string
 */
function getRows(csvLines: string) {
	return csvLines.split(/\r?\n/);
}

/**
 * Split a CSV line into array
 * @param csvRow a single line of CSV data
 * @return a array of data
 */
function getColumns(csvRow: string) {
	return csvRow.split(',');
}

/**
 * Creates Sale items and prepare the API calls
 * @param dataRows an array of array of DataItem (splitted lines and columns from the inital CSV multiline string)
 * @return the responses of all API calls
 */
function createSales(dataRows: DataItem[][]): Promise<string[]> {
	const dataObjects: SaleItem[] = [];
	for (let index = 0; index < dataRows.length; index++) {
		const dataColumns = dataRows[index];

		const dataObject: SaleItem = {};
		for (let j = 0; j < dataColumns.length; j++) {
			const element = dataColumns[j];
			dataObject[element.key] = element.value ?? '';
		}
		dataObjects.push(dataObject);
	}

	const promises = [];
	for (let index = 0; index < dataObjects.length; index++) {
		const dataItem = dataObjects[index];
		promises.push(createSale(dataItem));
	}
	return Promise.all(
		promises.map((promise) =>
			promise.catch((error) => {
				return error;
			})
		)
	);
}

/**
 * Create an Energy Sale by calling the API
 * @param data a SaleItem object, will be sent as application/json in the body of the API POST call (should contain the 98 keys requirement for the Create Energy Sale API)
 * @return the response of the API as a promise
 */
export async function createSale(data: SaleItem): Promise<string> {
	let result: string;
	try {
		result = await API.post('api', '/energy-sale', { body: data });
	} catch (error: any) {
		if (error.response && error.response.data) {
			result = error.response.data.error ?? error.response.data.message ?? 'Something went wrong.';
		} else {
			result = 'Something went wrong.';
		}
	}
	return JSON.stringify(result);
}

/**
 * Capture Energy Sale
 *  react functional component
 *  /admin/capture-energy-sale
 *
 *  no props, no children, get API URL from the redux store
 */
const CaptureEnergySale = () => {
	// hooks
	const [result, setResult] = useState('');
	const [salesData, setSalesData] = useState('');
	const [apiLoading, setApiLoading] = useState(true);

	// custom hooks
	const apiLanded = (apiData: any): void => {
		if (apiData.apiurl) {
			setApiLoading(false);
		}
	};

	ApiPath(apiLanded);

	const acl = useACL();
	if (!acl(Feature.Admin)) return <NoAccess />;

	/**
	 * Handles Textarea value change
	 * @param event the change event
	 *
	 */
	function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
		setSalesData(event.target.value);
	}

	/**
	 * Handles click events from the submit button
	 *  - creates the sales data and call the API
	 *
	 * @param event the click event
	 */
	function handleClick(event: SyntheticEvent) {
		event.preventDefault();
		const csvRows = getRows(salesData);

		const data = [];

		for (let index = 0; index < csvRows.length; index++) {
			const csvRow = csvRows[index];
			if (csvRow === '') continue;
			const csvColumns = getColumns(csvRow);
			const headedData = assignToHeaders(csvColumns);
			if (!headedData) return;
			data.push(headedData);
		}

		createSales(data).then((result) => {
			setResult(result.join(','));
		});
	}

	/**
	 * Assign headers (key) to the values sent in parameters.
	 * @param columns an array of value (string), should be the same length as the BCUP Headers (98), should be in the same order
	 * @return an array of DataItem ({title, key, value})
	 */
	function assignToHeaders(columns: string[]): DataItem[] | undefined {
		if (columns.length !== headers.length) {
			setResult('Headers and Columns are not the same length (should be 102)');
			return;
		}

		const data = headers.map((header) => ({ ...header })); // deep copy
		for (let index = 0; index < columns.length; index++) {
			const column = columns[index];
			data[index].value = column;
		}
		return data;
	}

	// render
	return (
		<div className="container">
			<h2>Sale Capture</h2>
			{apiLoading ? (
				<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
					<Loader />
				</div>
			) : (
				<form>
					<label htmlFor="salesData">Sales Data (must be CSV, no headers)</label>
					<textarea
						id="salesData"
						className="u-full-width edfm_textarea_big"
						placeholder="Paste the CSV content"
						value={salesData}
						onChange={handleChange}
					></textarea>
					<Button label="Submit" action={handleClick} />
				</form>
			)}
			{result !== '' && <span data-testid="result-span">{result}</span>}
		</div>
	);
};

export default CaptureEnergySale;
