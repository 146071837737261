import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IsEqual from './isEqual';
import { refDataProps, EnergySale } from './types';

/**
 * Reference Data Back Translator - custom Hook
 * @returns a function which takes a key and a description, and returns a code.
 * Follows the format of refDataTranslate but does the opposite.
 */
export const useRefDataBackTranslate = () => {
	const [refData, setRefData]: [Array<Array<refDataProps>>, any] = useState([]);
	const [refDataMap, setRefDataMap]: [{ [key: string]: refDataProps[] }, any] = useState({});

	useSelector((state: { saleData: EnergySale; refData: refDataProps[][] }) => {
		if (state.refData && (!refData || !IsEqual(state.refData, refData))) {
			setRefData(state.refData);
		}
	});

	useEffect(() => {
		const newRefDateMap: { [key: string]: refDataProps[] } = {};
		for (let index = 0; index < refData.length; index++) {
			const refDataTypes = refData[index];
			const refDataTypeName = refDataTypes[0].refDataCategory;
			newRefDateMap[refDataTypeName] = refDataTypes;
		}
		setRefDataMap(newRefDateMap);
	}, [refData]);

	return (key: string, description: string): string => {
		if (!refDataMap[key]) return description;
		return refDataMap[key].find((refDataItem) => refDataItem.description === description)?.code ?? description;
	};
};
