import React, { useState, useEffect } from 'react';

import '../styling.scss';
import { Button, Card, Loader } from '..';

export interface ModalProps {
	hide?: boolean;
	content: any;
	size?: string;
	title?: string;
	close?: boolean;
	closeCopy?: string;
	customClose?: any;
	buttons?: Array<{ primary?: boolean; secondary?: boolean; label: string; action: any }>;
	level?: number; //allow modals to stack ontop of each other
	loading?: boolean;
}

const Modal = (props: ModalProps) => {
	const {
		hide = false,
		title,
		content,
		buttons,
		close,
		customClose,
		closeCopy = 'X',
		level,
		size = 'md',
		loading = false,
	} = props;
	const [hidden, setHidden] = useState(hide);

	const buttonList = buttons
		? buttons.map((item: { primary?: boolean; secondary?: boolean; label: string; action: any }, key: number) => {
				const { primary, secondary, label, action } = item;

				return <Button key={key} label={label} action={action} primary={primary} secondary={secondary} />;
		  })
		: undefined;

	const closeMe = (): void => {
		if (customClose) {
			customClose();
		} else {
			setHidden((oldVal) => !oldVal);
		}
	};

	useEffect(() => {
		setHidden(hide);
	}, [hide]);

	return (
		<div className={`edfmat_modal ${hidden ? 'hidden' : ''} ${level ? 'edfmat_modal--level' + level : ''}`}>
			<div className="edfmat_modal_background"></div>
			<div className={`edfmat_modal_content  edfmat_modal_content--${size}`}>
				<Card>
					{loading && (
						<div className="edfmat_modal_content_loading">
							<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
								<Loader />
							</div>
						</div>
					)}
					{/* close - optional */}
					{close && (
						<div className="close" onClick={closeMe}>
							{closeCopy}
						</div>
					)}

					{/* title */}
					{title && <h4>{title}</h4>}
					{/* body */}
					<div className="edfmat_modal_content_body">{content}</div>

					{/* actions - primary / secondary */}
					{buttonList && <div style={{ textAlign: 'right' }}>{buttonList}</div>}
				</Card>
			</div>
		</div>
	);
};

export default Modal;
