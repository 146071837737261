import Swal from 'sweetalert2';
import { EnergySale, EnergySaleGSPCodeUpdate } from '../../widgets/types';
import { updateGSPCode } from './updateGSPCode';
import { SuccessFunc, FailFunc } from '../../widgets/data/saleCallBackFunctions';

/**
 * Start the process to return an energy sale
 * @param id the id of the sale to return
 * @param derivedGSP the GSP
 * @param rawSaleData the previous EnergySaleDetail.updatedTs of the sale to return (used for locking)
 */
const showGSPModal =
	(onReturn: (params: { id: number; derivedGSP: string; rawSaleData: EnergySale | undefined }) => void) =>
	(id: number, rawSaleData: EnergySale) => {
		const Toast = Swal.mixin({
			toast: true,
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: true,
			customClass: {
				title: 'edfm_popup_font_content',
			},
		});
		//popup
		Swal.fire({
			title: 'Enter a new GSP code for this energy sale',
			text: 'When you click save button we will reprocess the energy sale',
			html: '<select id="swal-input3"><option value="A">A</option><option value="B">B</option><option value="C">C</option><option value="D">D</option><option value="E">E</option><option value="F">F</option><option value="G">G</option><option value="H">H</option><option value="J">J</option><option value="K">K</option><option value="L">L</option><option value="M">M</option><option value="N">N</option><option value="P">P</option></select>',
			showCancelButton: true,
			confirmButtonText: `Save`,
			denyButtonText: `Cancel`,
			icon: 'warning',
			width: '50em',
			customClass: {
				title: 'edfm_popup_font_content',
				actions: 'edfm_popup_font_actions',
			},
			preConfirm: () => {
				return [(document.getElementById('swal-input3') as HTMLInputElement).value];
			},
		}).then((formValue) => {
			if (formValue.value) {
				const successFunc: SuccessFunc = () => {
					Toast.fire({
						icon: 'success',
						title: 'The GSP Code was sucessfully updated.',
					});
					window.location.reload();
				};
				const failFunc: FailFunc = (error: string) => {
					console.log('GSP update failed due to: ', error);
					Toast.fire({
						icon: 'error',
						title: 'The GSP Code was not updated.',
					});
				};
				const energySaleGSPCodeUpdate: EnergySaleGSPCodeUpdate = {
					derivedGSP: formValue.value[0],
					energySale: rawSaleData,
				};
				updateGSPCode(energySaleGSPCodeUpdate, id, successFunc, failFunc);
				onReturn({
					id,
					derivedGSP: formValue.value[0],
					rawSaleData,
				});
			}
		});
	};

export default showGSPModal;
