import React from 'react';

export interface PanelProps {
	panelOn: boolean;
	hide: any;
	body: any;
}

const SidePanel = (props: PanelProps) => {
	const { body, hide, panelOn } = props;

	return (
		<div className={`report-details-panel ${!panelOn ? 'report-details-panel--hidden' : ''}`}>
			<h5 style={{ cursor: 'pointer' }} onClick={hide}>
				X
			</h5>
			{body}
		</div>
	);
};

export default SidePanel;
