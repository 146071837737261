import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 *  LocationSubscriber is a singleton class which allows React components to listen to location changes
 *  This is useful in case you need a React component to run some code on change of location but not on rerender.
 *  (see example on workflow.txs)
 */
class LocationSubscriber {
	private static instance: LocationSubscriber;
	private pathName: string;
	public listeners: { [key: string]: boolean } = {};

	private constructor() {
		this.pathName = '';
	}

	static getInstance(): LocationSubscriber {
		if (!this.instance) this.instance = new this();
		return this.instance;
	}

	resetPathState(pathName: string): void {
		if (this.pathName !== pathName) {
			this.pathName = pathName;
			if (this.listeners[pathName]) {
				this.listeners[pathName] = false;
			}
		}
	}

	getPathName(): string {
		return this.pathName;
	}
}

/**
 * a custom React Hook to be able to use the LocationSubscriber instance inside react components
 * @returns the LocationSubscriber singleton instance
 */
export const useLocationTracker = () => {
	const location = useLocation();
	const [pathName, setPathName]: [string, any] = useState(LocationSubscriber.getInstance().getPathName());

	useEffect(() => {
		if (location.pathname !== pathName) {
			setPathName(location.pathname);
			LocationSubscriber.getInstance().resetPathState(location.pathname);
		}
	}, [location, pathName]);

	return LocationSubscriber.getInstance();
};
