import { RestAPI, RestAPIClass } from '@aws-amplify/api-rest';
import { Hub as _Hub } from '@aws-amplify/core';
import { Auth as _Auth } from '@aws-amplify/auth';

import * as AuthFunctions from './AuthFunctions';
import * as AuthComponents from './AuthComponents';

namespace SSOAuth {
	export const Hub = _Hub;

	export const Auth = _Auth;

	export const API: RestAPIClass = RestAPI;

	export const Functions = AuthFunctions;

	export const Components = AuthComponents;

	export const { AuthContainer } = AuthComponents;
}

export = SSOAuth;
