import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

//import {TabBar} from '../../edfm'

import { LoadBrokers } from '../../widgets/data/';
import { ListPrices, IngestPrices } from '.';
import { NoAccess, TabBar } from '../../edfm';
import { URLParams } from '../../widgets/types';
import useACL, { Feature } from '../../widgets/acl';

const Prices = () => {
	const { section, id } = useParams<URLParams>();

	const history = useHistory();

	const updateSection = (newSection: string) => {
		history.push(newSection);
	};

	const acl = useACL();

	const filterTabs = (feature: Feature, tabs: any) => {
		if (acl(feature)) {
			tabs = tabs.filter((tab: { features: Feature[] }) => !tab.features.includes(feature));
		}

		return tabs;
	};

	LoadBrokers();

	if (!acl(Feature.PriceLaunch)) return <NoAccess />;

	const tabs = [
		{
			label: 'View price launches',
			action: () => {
				updateSection(`/price-launches`);
			},
			active: !section,
			features: [Feature.PriceLaunch],
		},
		{
			label: 'Create price launch',
			action: () => {
				updateSection(`/price-launches/price-file`);
			},
			active: section === 'price-file',
			features: [Feature.PriceLaunch, Feature.ReadOnly],
		},
	];

	return (
		<>
			<TabBar options={filterTabs(Feature.ReadOnly, tabs)} />

			{!section ? (
				<ListPrices />
			) : section === 'price-file' && !id ? (
				// create price launch
				<IngestPrices />
			) : section === 'price-file' && id ? (
				//edit price launch
				<IngestPrices id={Number(id)} />
			) : (
				<p>not found</p>
			)}
		</>
	);
};

export default Prices;
