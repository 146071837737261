import React from 'react';
import { NoAccess } from '../../../edfm';
import useACL, { Feature } from '../../../widgets/acl';
import EditBrokerContact from './editBrokerContact';

const BrokerContact = () => {
	const acl = useACL();
	if (!acl(Feature.EditBroker)) return <NoAccess />;
	//stub holder to forward EditBrokerContact to ensure re-render if changing mode from add to edit (after successful creation of a contact)
	return <EditBrokerContact />;
};

export default BrokerContact;
