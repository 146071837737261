import { ChangeEvent, useState } from 'react';
import { debounce } from 'ts-debounce';

export const InputNumberDebounce = (props: {
	value: number | undefined;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	className?: string;
	id?: string;
	disabled?: boolean;
}) => {
	const [localValue, setLocalValue]: [number | undefined, any] = useState(props.value);

	const debouncedFunction = debounce(props.onChange, 1000, {});

	function wrappingFunction(event: ChangeEvent<HTMLInputElement>) {
		setLocalValue(event.target.valueAsNumber);
		debouncedFunction(event);
	}

	return (
		<input
			id={props.id}
			onChange={wrappingFunction}
			type="number"
			value={localValue}
			min="0"
			className={props.className}
			disabled={props.disabled || false}
		/>
	);
};
