import React, { useState, useEffect } from 'react';
import { Row, Column, Loader, DataTable, Modal, NoAccess } from '../../edfm';

import { LoadPrices, UpdatePrice, ApiPath } from '../../widgets/data';
import { useSelector } from 'react-redux';
import IsEqual from '../../widgets/isEqual';
import QueryParams from '../../widgets/queryParams';

import { pricesDataProps } from '../../widgets/types';
import { useHistory } from 'react-router-dom';
import { ModalBody } from '.';
import DateFormat from '../../widgets/dateFormat';
import useACL, { Feature } from '../../widgets/acl';

const ListPrices = () => {
	const history = useHistory();

	const updateSection = (newSection: string) => {
		history.push(newSection);
	};

	const [pricesData, setPricesData]: [[pricesDataProps] | undefined, any] = useState();
	const [pricesList, setPricesList]: [[] | undefined, any] = useState();
	const [loadingPrices, setLoadingPrices] = useState(true);

	const [path, setPath]: [string | undefined, any] = useState();

	const [hideModal, setHideModal] = useState(true);
	const [hideModal2, setHideModal2] = useState(true);
	const [loadingModal2, setLoadingModal2] = useState(false);
	const [modalBody, setModalBody] = useState(<></>);
	const [modalBody2, setModalBody2] = useState(<></>);
	const [modalTitle, setModalTitle] = useState('');
	const [modalTitle2, setModalTitle2] = useState('');
	const [modalButtons, setModalButtons]: [
		[{ primary?: boolean; secondary?: boolean; label: string; action: any }] | undefined,
		any
	] = useState();
	const [modalButtons2, setModalButtons2]: [
		[{ primary?: boolean; secondary?: boolean; label: string; action: any }] | undefined,
		any
	] = useState();

	//if returning from an edit / create reload prices list to get changes (check for '?reload=true' in query string)

	ApiPath((apiPath: { apiurl: string }) => {
		setPath(apiPath.apiurl);
	});

	const reload = QueryParams('reload') === 'true';

	const launch = QueryParams('launch');

	LoadPrices(reload);

	const dataStore = (input: any) => {
		if (input.pricesData) {
			if (!pricesData || !IsEqual(input.pricesData, pricesData)) {
				setPricesData(input.pricesData);
				setLoadingPrices(false);
			}
		}
	};

	const confirmLaunch = (priceLaunch: pricesDataProps) => {
		setModalTitle2('Confirm price launch');
		setModalBody2(
			<p>
				Once launched, <strong>{priceLaunch.description}</strong> will be published and will no longer be editable.
			</p>
		);
		setModalButtons2([
			{ label: 'Cancel', action: () => setHideModal2(true) },
			{
				primary: true,
				label: 'Confirm launch',
				action: () => {
					launchPrice(priceLaunch);
				},
			},
		]);
		setHideModal2(false);
	};

	const confirmClose = (priceLaunch: pricesDataProps) => {
		setModalTitle2('Set price launch to void');
		setModalBody2(
			<p>
				Once void, sales can no longer be made to the <strong>{priceLaunch.description}</strong> price book.
			</p>
		);
		setModalButtons2([
			{ label: 'Cancel', action: () => setHideModal2(true) },
			{
				primary: true,
				label: 'Confirm void',
				action: () => {
					closePrice(priceLaunch);
				},
			},
		]);
		setHideModal2(false);
	};

	const selectPrice = (priceLaunch: pricesDataProps) => {
		//use pricelaunchId (or actual pricelaunch object??) to populate modal
		const { status, description, priceLaunchId } = priceLaunch;

		//if draft / ingested - launch / edit
		//if published / live - edit / void (edit only allows changing of end date)
		if (status === 'LIVE') {
			const plEndDate = new Date(priceLaunch.priceLaunchEndDate);
			const nowDate = new Date();
			if (!acl(Feature.ReadOnly) && nowDate.getTime() > plEndDate.getTime()) {
				setModalButtons([
					{ primary: true, label: 'Set price launch to void', action: () => confirmClose(priceLaunch) },
				]);
			} else if (!acl(Feature.ReadOnly)) {
				setModalButtons([
					{ label: 'Edit end date', action: () => updateSection('/price-launches/price-file/' + priceLaunchId) },
					{ primary: true, label: 'Set price launch to void', action: () => confirmClose(priceLaunch) },
				]);
			}
		} else if (!acl(Feature.ReadOnly) && status === 'INGESTED') {
			setModalButtons([
				{ label: 'Edit price launch', action: () => updateSection('/price-launches/price-file/' + priceLaunchId) },
				{ primary: true, label: 'Launch price', action: () => confirmLaunch(priceLaunch) },
			]);
		} else if (!acl(Feature.ReadOnly) && status === 'DRAFT') {
			setModalButtons([
				{ label: 'Edit price launch', action: () => updateSection('/price-launches/price-file/' + priceLaunchId) },
			]);
		} else if (status === 'VOID' || status === 'ERROR') {
			setModalButtons();
		} else {
			//fallback to remove options for anything other than the defined states above
			setModalButtons();
		}

		setModalTitle(description);
		setModalBody(<ModalBody priceLaunch={priceLaunch} />);
		setHideModal(false);
	};

	useEffect(() => {
		if (hideModal2) {
			setLoadingModal2(false);
		}
	}, [hideModal2]);

	useEffect(() => {
		if (pricesData) {
			//sort by date order - TODO
			setPricesList(() =>
				pricesData.map((elem: pricesDataProps, i: number) => {
					const {
						status,
						description,
						priceLaunchStartDate,
						priceLaunchEndDate,
						sellingWindowStartDays,
						sellingWindowEndDays,
					} = elem;

					return {
						clickAction: (): void => {
							updateSection(`/price-launches?launch=${elem.priceLaunchId}`);
						},
						columns: [
							description,
							<DateFormat date={priceLaunchStartDate} />,
							<DateFormat date={priceLaunchEndDate} />,
							`${sellingWindowStartDays}-${sellingWindowEndDays} days`,
							status,
						],
					};
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pricesData]);

	useEffect(() => {
		if (launch && pricesData && path) {
			const selectedLaunch = pricesData.find((elem: pricesDataProps) => {
				return elem.priceLaunchId === Number(launch);
			});

			if (selectedLaunch) {
				selectPrice(selectedLaunch);
			} else {
				setHideModal(true);
			}
		} else {
			setHideModal(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [launch, pricesData, path]);

	useSelector(dataStore);

	const addNew = () => {
		updateSection('/price-launches/price-file');
	};

	const launchPrice = (priceLaunch: pricesDataProps) => {
		const priceLaunchUpdate = { ...priceLaunch };

		priceLaunchUpdate.status = 'LIVE';

		setLoadingModal2(true);

		const successFunc = (): void => {
			setHideModal2(true);
			updateSection(`/price-launches?reload=true&launch=${priceLaunch.priceLaunchId}`);
			setHideModal(true);
			window.location.reload();
		};

		const failFunc = (error: string): void => {
			setModalTitle2('Error launching price');
			setModalBody2(<p>{error}</p>);
			setModalButtons2([]);
			setHideModal2(false);

			setLoadingModal2(false);
		};

		if (path) {
			UpdatePrice(path, priceLaunchUpdate, priceLaunch.priceLaunchId, successFunc, failFunc);
		}
	};

	const closePrice = (priceLaunch: pricesDataProps) => {
		const priceLaunchUpdate = { ...priceLaunch };

		priceLaunchUpdate.status = 'VOID';

		setLoadingModal2(true);

		const successFunc = (): void => {
			setHideModal2(true);
			updateSection(`/price-launches?reload=true&launch=${priceLaunch.priceLaunchId}`);
			setHideModal(true);
			window.location.reload();
		};

		const failFunc = (error: string): void => {
			setModalTitle2('Error setting price launch to void');
			setModalBody2(<p>{error}</p>);
			setModalButtons2([]);
			setHideModal2(false);

			setLoadingModal2(false);
		};

		if (path) {
			UpdatePrice(path, priceLaunchUpdate, priceLaunch.priceLaunchId, successFunc, failFunc);
		}
	};

	const acl = useACL();
	if (!acl(Feature.PriceLaunch)) return <NoAccess />;

	return (
		<div className="list-broker--container">
			<Modal
				hide={hideModal}
				title={modalTitle}
				customClose={() => updateSection(`/price-launches`)}
				content={modalBody}
				buttons={modalButtons}
				close={true}
			/>

			<Modal
				hide={hideModal2}
				loading={loadingModal2}
				title={modalTitle2}
				size="sm"
				level={2}
				customClose={() => setHideModal2(true)}
				content={modalBody2}
				buttons={modalButtons2}
				close={true}
			/>

			<Row>
				<Column columns={12}>
					{loadingPrices ? (
						<div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
							<Loader />
						</div>
					) : (
						pricesList && (
							<>
								<DataTable
									headings={[
										{ label: 'Description' },
										{ label: 'Start date' },
										{ label: 'End date' },
										{ label: 'Selling window' },
										{ label: 'Status' },
									]}
									body={pricesList}
									clickable={true}
									slim
									pagination={true}
								/>
								{!acl(Feature.ReadOnly) && (
									<input
										className={`edfmat_button edfmat_button--sm list-broker--addnew`}
										type="button"
										onClick={addNew}
										value="+ new price launch"
									/>
								)}
							</>
						)
					)}
				</Column>
			</Row>
		</div>
	);
};

export default ListPrices;
