/* eslint-disable no-unused-vars */
import { EnergySale } from '../../widgets/types';
import { DateTime } from 'luxon';

export enum DisplayStatus {
	Processing,
	SAPProcessing,
	Live,
	Finalised,
	Returned,
	ContractEnded,
	Error,
	Suspended,
	Unprocessed,
}

export function DisplayStatusToString(status: DisplayStatus) {
	if (status === DisplayStatus.Processing) return 'Processing';
	if (status === DisplayStatus.Suspended) return 'Suspended';
	if (status === DisplayStatus.Unprocessed) return 'Unprocessed';
	if (status === DisplayStatus.Returned) return 'Returned';
	if (status === DisplayStatus.SAPProcessing) return 'SAP Processing';
	if (status === DisplayStatus.Live) return 'Live';
	if (status === DisplayStatus.Finalised) return 'Settled';
	if (status === DisplayStatus.ContractEnded) return 'Contract Ended';
	if (status === DisplayStatus.Error) return 'Error';
}

/**
 * Map the EnergySaleDetail.energySaleStatus to fetch from the DB based on the display status
 * @param status a display status
 * @returns a comma separated list of energySaleStatus
 */
export function displayStatusToBackedStatus(status: DisplayStatus) {
	if (status === DisplayStatus.Processing) return ['Processing'];
	if (status === DisplayStatus.Suspended) return ['Suspended'];
	if (status === DisplayStatus.Unprocessed) return ['Unprocessed'];
	if (status === DisplayStatus.Returned) return ['Returned'];
	if (status === DisplayStatus.SAPProcessing) return ['SAPProcessing'];
	if (status === DisplayStatus.Live) return ['Live'];
	if (status === DisplayStatus.Finalised) return ['Finalised'];
	if (status === DisplayStatus.ContractEnded) return ['ContractEnded'];
	if (status === DisplayStatus.Error) return ['Error'];
	return [];
}

/**
 * Map the display status to display based on the EnergySaleDetail.energySaleStatus
 * @param status a energy sale status
 * @returns a Display Status (enum), use DisplayStatusToString to get a readable string
 */
export function statusToDisplayStatus(sale: EnergySale): DisplayStatus {
	const missingFromSAP =
		!sale.EnergySaleDetail?.contractStatus &&
		!['SUSPENDED', 'RETURNED', 'FORRETURN'].includes(sale.EnergySaleDetail?.energySaleStatus || '');

	// Not found - sap contract status is null, regardless of energysaledetail.energysalestatus, and energy sale.createdTs > 48 hours
	if (missingFromSAP && -DateTime.fromISO(sale.createdTs).diffNow('hours').hours > 48) {
		return DisplayStatus.Unprocessed;
	}

	switch (sale.EnergySaleDetail?.energySaleStatus) {
		case 'SUSPENDED':
			return DisplayStatus.Suspended;
		case 'RETURNED':
		case 'FORRETURN':
			return DisplayStatus.Returned;
		case 'EXCEPTION':
			return DisplayStatus.Error;
		case 'COMMIT':
			return DisplayStatus.SAPProcessing;
		case 'FINAL':
			return DisplayStatus.Finalised;
		case 'ENDED':
			return DisplayStatus.ContractEnded;
		case 'LIVE':
		case 'LIVED19':
			return DisplayStatus.Live;
		case 'SUBMITTED':
		case 'READY':
		case 'ACCEPT':
		case 'SENT':
			return DisplayStatus.Processing;
	}
	return DisplayStatus.Unprocessed;
}
