import React, { useState } from 'react';
import { Input, Row, Column, Button, InputGroup, Modal, Loader, PStyle } from '../../../edfm';
import { ApiPath, SaveCommissionTerms, LoadBroker } from '../../../widgets/data';

import DatePicker from 'react-datepicker';
import DateFormat from '../../../widgets/dateFormat';
import { useDispatch } from 'react-redux';
import { CommissionTerm } from '../../../widgets/data/commissionTerms/commissionTerm.type';

function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
	return o[propertyName]; // o[propertyName] is of type T[K]
}

function commissionDataFormatNumbers(preppedData: CommissionTerm): CommissionTerm {
	const notNumbers = ['saleType', 'effectiveDate', 'isLegacy', 'clawbackStructure', 'updatedTs'];
	const result: { [k: string]: any } = { ...preppedData };

	for (let [key, value] of Object.entries(preppedData)) {
		if (notNumbers.includes(key)) {
			result[key] = value;
		} else {
			result[key] = Number(value);
		}
	}

	return result as CommissionTerm;
}

const EditTerms = (props: {
	commissionData: CommissionTerm;
	id: number;
	isNew?: boolean;
	cancel?: any;
	finish?: () => void;
}) => {
	const dispatch = useDispatch();

	const { commissionData, id, isNew, cancel, finish } = props;
	const [submissionData, setSubmissionData] = useState({ ...commissionData });
	const [path, setPath]: [string | undefined, any] = useState();
	const [effectiveDate, setEffectiveDate]: [Date | null, any] = useState(
		commissionData.effectiveDate ? new Date(commissionData.effectiveDate) : null
	);

	const [updating, setUpdating] = useState(false);
	const [hideModal, setHideModal] = useState(true);
	const [modalBody, setModalBody] = useState('');
	const [modalTitle, setModalTitle] = useState('');

	ApiPath((apiPath: { apiurl: string }) => {
		setPath(apiPath.apiurl);
	});

	const minDate = new Date(); //set the minimum date tomorrow (time plus 24 hours) at start of day 0,0,0
	minDate.setHours(minDate.getHours() + 24);
	minDate.setHours(0, 0, 0, 0);

	//trigger load broker in case latest update has changed due to action in another component (if broker not cleared during update - no reload performed)
	LoadBroker(id);

	const EffectiveDateButton = ({ value, onClick }: any) => (
		<span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onClick}>
			{effectiveDate !== null ? 'change' : 'set'}
		</span>
	);

	const submitForm = (e: any) => {
		e.preventDefault();

		if (!validForm || !effectiveDate) {
			return;
		}

		setUpdating(true);

		const preppedData = { ...submissionData, effectiveDate: formatJsonDate(effectiveDate) };

		//check latest update - if commissionData is newer than submissionTerms, use instead
		if (
			commissionData.updatedTs &&
			submissionData.updatedTs &&
			new Date(commissionData.updatedTs).getTime() > new Date(submissionData.updatedTs).getTime()
		) {
			preppedData.updatedTs = commissionData.updatedTs;
		}

		preppedData.acceptPc = submissionData.acceptPc || 0;
		preppedData.livePc = submissionData.livePc || 0;
		preppedData.finalPc = submissionData.finalPc || 0;

		//incentive %
		if (!submissionData.incentiveGasFee) {
			//zero *or* undefined
			preppedData.incentiveGasFee = undefined;
		}
		if (!submissionData.incentiveElecFee) {
			//zero *or* undefined
			preppedData.incentiveElecFee = undefined;
		}

		if (!submissionData.incentiveGasFee && !submissionData.incentiveElecFee) {
			preppedData.acceptIncentPc = undefined;
			preppedData.liveIncentPc = undefined;
			preppedData.finalIncentPc = undefined;
		}

		//ensure a zero pounds payment cap isn't sent
		if (Number(submissionData.paymentCap) === 0) {
			preppedData.paymentCap = undefined;
		}

		//ensure a zero payable percent isn't sent
		if (Number(submissionData.payablePc) === 0) {
			preppedData.payablePc = undefined;
		}

		const formattedPreppedData = commissionDataFormatNumbers(preppedData);

		const body = formattedPreppedData;

		const successFunc = (commissionTermId: number, updatedTs: string) => {
			setUpdating(false);

			setModalTitle(`Terms ${isNew ? 'created' : 'updated'}`);
			setModalBody(`${submissionData.saleType} commission terms update successful`);
			setHideModal(false);

			dispatch({ type: 'brokerData', data: undefined });

			setSubmissionData((oldData: CommissionTerm) => {
				return { ...oldData, updatedTs: updatedTs };
			});
			if (finish) finish();
		};

		const errorFunc = (errorMessage: string) => {
			setUpdating(false);

			setModalTitle('Error');
			setModalBody(errorMessage);
			setHideModal(false);
		};

		path && SaveCommissionTerms(`/tpi/${id}/commission-term`, body, successFunc, errorFunc);
	};

	const numChange = (e: any): boolean => {
		//strip all non numerals from field (including pound and percentage signs)
		const value = e.target.value.replace(/[^\d.-]/g, '');
		const field = e.target.id;

		// only accept positive or negative numbers (decimal or not)
		const regexNumber = new RegExp(/^-?(\d+)?\.?(\d+)?$/);

		if (value === getProperty(submissionData, field) || !regexNumber.test(value)) {
			return false;
		}

		setSubmissionData((oldData: any) => {
			return {
				...oldData,
				[field]: value === '' ? undefined : value,
			};
		});

		return true;
	};

	const formatJsonDate = (date: Date): string => {
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);

		return `${date.getFullYear()}-${month}-${day}`;
	};

	const validPC = (
		a: number | null | undefined,
		b: number | null | undefined,
		c: number | null | undefined
	): boolean => {
		const acceptPC = isNaN(Number(a)) ? 0 : Number(a);
		const livePC = isNaN(Number(b)) ? 0 : Number(b);
		const finalPC = isNaN(Number(c)) ? 0 : Number(c);

		if (acceptPC + livePC + finalPC !== 100) {
			return false;
		}
		return true;
	};

	const validForm = (): boolean => {
		if (effectiveDate && effectiveDate.getTime() < minDate.getTime()) {
			return false;
		}

		if (!validPC(submissionData.acceptPc, submissionData.livePc, submissionData.finalPc)) {
			return false;
		}
		if (
			submissionData.finalPc &&
			Number(submissionData.finalPc) > 0 &&
			submissionData.finalPcPeriodDays === undefined
		) {
			return false;
		}
		if (
			(Number(submissionData.incentiveGasFee) > 0 || Number(submissionData.incentiveElecFee) > 0) &&
			!validPC(submissionData.acceptIncentPc, submissionData.liveIncentPc, submissionData.finalIncentPc)
		) {
			return false;
		}
		if (!effectiveDate) {
			return false;
		}
		if (!Number(submissionData.termDays)) {
			return false;
		}

		return true;
	};

	const clearGas = (): void => {
		if (submissionData.incentiveElecFee !== undefined) {
			setSubmissionData((old: any) => {
				return { ...old, incentiveGasFee: undefined };
			});
		} else {
			setSubmissionData((old: any) => {
				return {
					...old,
					incentiveGasFee: undefined,
					acceptIncentPc: undefined,
					liveIncentPc: undefined,
					finalIncentPc: undefined,
					finalIncentPeriodDays: undefined,
					payablePc: undefined,
				};
			});
		}
	};

	const clearElectricity = (): void => {
		if (submissionData.incentiveGasFee !== undefined) {
			setSubmissionData((old: any) => {
				return { ...old, incentiveElecFee: undefined };
			});
		} else {
			setSubmissionData((old: any) => {
				return {
					...old,
					incentiveElecFee: undefined,
					acceptIncentPc: undefined,
					liveIncentPc: undefined,
					finalIncentPc: undefined,
					finalIncentPeriodDays: undefined,
					payablePc: undefined,
				};
			});
		}
	};

	return (
		<>
			<Modal
				hide={hideModal}
				title={modalTitle}
				size="sm"
				customClose={() => setHideModal(true)}
				content={modalBody}
				close={true}
			/>

			{updating && (
				<div className="list-broker--loader">
					<Loader />
				</div>
			)}

			<form onSubmit={submitForm}>
				<Row>
					<Column columns={6}>
						<PStyle>
							<strong>Effective date:</strong> {effectiveDate ? <DateFormat date={effectiveDate} /> : 'not set'}&nbsp;
							<DatePicker
								showPopperArrow={true}
								selected={effectiveDate}
								onChange={(date) => setEffectiveDate(date)}
								customInput={<EffectiveDateButton />}
								minDate={minDate}
							/>
						</PStyle>

						<InputGroup>
							<Input
								type="text"
								fullWidth
								value={
									submissionData.acceptPc !== undefined && submissionData.finalPcPeriodDays !== null
										? submissionData.acceptPc + '%'
										: ''
								}
								id="acceptPc"
								label="Percentage on acceptance %"
								onChange={numChange}
							/>
							<Input
								type="text"
								fullWidth
								value={
									submissionData.livePc !== undefined && submissionData.livePc !== null
										? submissionData.livePc + '%'
										: ''
								}
								id="livePc"
								label="Percentage on live %"
								onChange={numChange}
							/>
							<Input
								type="text"
								fullWidth
								value={
									submissionData.finalPc !== undefined && submissionData.finalPc !== null
										? submissionData.finalPc + '%'
										: ''
								}
								id="finalPc"
								label="Percentage on final %"
								onChange={numChange}
							/>
							<Input
								type="text"
								fullWidth
								value={
									submissionData.finalPcPeriodDays !== undefined && submissionData.finalPcPeriodDays !== null
										? submissionData.finalPcPeriodDays.toString()
										: ''
								}
								id="finalPcPeriodDays"
								label="Final percentage period (days)"
								onChange={numChange}
							/>

							<span className="additional-details">
								Final percentage payments paid at contract start plus final percentage period (or contract end if
								sooner)
							</span>
						</InputGroup>

						<InputGroup>
							<span className="floating-clear-holder">
								<Input
									type="text"
									fullWidth
									value={
										submissionData.incentiveGasFee !== undefined && submissionData.incentiveGasFee !== null
											? '£' + submissionData.incentiveGasFee
											: ''
									}
									id="incentiveGasFee"
									label="Incentive gas fee"
									onChange={numChange}
								/>{' '}
								<span className="floating-clear">
									<Button size="sm" action={clearGas} label="clear" />
								</span>
							</span>
							<span className="floating-clear-holder">
								<Input
									type="text"
									fullWidth
									value={
										submissionData.incentiveElecFee !== undefined && submissionData.incentiveElecFee !== null
											? '£' + submissionData.incentiveElecFee
											: ''
									}
									id="incentiveElecFee"
									label="Incentive electricity fee"
									onChange={numChange}
								/>{' '}
								<span className="floating-clear">
									<Button size="sm" action={clearElectricity} label="clear" />
								</span>
							</span>
							{(Number(submissionData.incentiveGasFee) > 0 || Number(submissionData.incentiveElecFee) > 0) && (
								<>
									<Input
										type="text"
										fullWidth
										value={
											submissionData.acceptIncentPc !== undefined && submissionData.acceptIncentPc !== null
												? submissionData.acceptIncentPc + '%'
												: ''
										}
										id="acceptIncentPc"
										label="Incentive percentage on acceptance %"
										onChange={numChange}
									/>
									<Input
										type="text"
										fullWidth
										value={
											submissionData.liveIncentPc !== undefined && submissionData.liveIncentPc !== null
												? submissionData.liveIncentPc + '%'
												: ''
										}
										id="liveIncentPc"
										label="Incentive percentage on live %"
										onChange={numChange}
									/>
									<Input
										type="text"
										fullWidth
										value={
											submissionData.finalIncentPc !== undefined && submissionData.finalIncentPc !== null
												? submissionData.finalIncentPc + '%'
												: ''
										}
										id="finalIncentPc"
										label="Incentive percentage on final %"
										onChange={numChange}
									/>
								</>
							)}

							<span className="additional-details">
								Final percentage payments paid at contract start plus final percentage period (or contract end if
								sooner)
							</span>
						</InputGroup>
					</Column>

					<Column columns={6}>
						<Input
							type="text"
							fullWidth
							value={
								submissionData.paymentCap !== undefined && submissionData.paymentCap !== null
									? '£' + submissionData.paymentCap
									: ''
							}
							id="paymentCap"
							label="Payable cap"
							onChange={numChange}
						/>
						<Input
							type="text"
							fullWidth
							value={
								submissionData.payablePc !== undefined && submissionData.payablePc !== null
									? submissionData.payablePc + '%'
									: ''
							}
							id="payablePc"
							label="Payable percentage %"
							onChange={numChange}
						/>
						<Input
							type="text"
							fullWidth
							value={
								submissionData.termDays !== undefined && submissionData.termDays !== null
									? submissionData.termDays.toString()
									: ''
							}
							id="termDays"
							label="Payment term (days)"
							onChange={numChange}
						/>
					</Column>
				</Row>
				<Row>
					<PStyle className="u-pull-right">
						{cancel && <Button action={cancel} label="Cancel" />}
						<Button
							disabled={!validForm()}
							primary
							action={submitForm}
							label={isNew ? 'Add commission term' : 'Update commission term'}
						/>
					</PStyle>
				</Row>

				{!validForm() && (
					<Row>
						<span className="u-pull-right additional-details">
							*{!effectiveDate && 'Effective date is a required field. '}
							{effectiveDate && effectiveDate.getTime() < minDate.getTime() && 'Effective date must be in the future.'}
							{!validPC(submissionData.acceptPc, submissionData.livePc, submissionData.finalPc) &&
								'Payment percentage must equal 100% across acceptance, live and final. '}
							{submissionData.finalPc &&
								Number(submissionData.finalPc) > 0 &&
								submissionData.finalPcPeriodDays === undefined &&
								'Final percentage period days not set. '}
							{(Number(submissionData.incentiveGasFee) > 0 || Number(submissionData.incentiveElecFee) > 0) &&
								!validPC(submissionData.acceptIncentPc, submissionData.liveIncentPc, submissionData.finalIncentPc) &&
								'Incentive payment percentage must equal 100% across acceptance, live and final where set. '}
							{!Number(submissionData.termDays) && 'Payment term must be set. '}
						</span>
					</Row>
				)}
			</form>
		</>
	);
};

export default EditTerms;
