const reducer = (state: any = {}, action: any) => {
	switch (action.type) {
		case 'refData':
			return { ...state, refData: action.data };
		case 'brokerData':
			return { ...state, brokerData: action.data };
		case 'brokersData':
			return { ...state, brokersData: action.data };
		case 'agentsData':
			return { ...state, agentsData: action.data };
		case 'apiData':
			return { ...state, apiData: action.data };
		case 'pricesData':
			return { ...state, pricesData: action.data };
		case 'contractsData':
			return { ...state, contractsData: action.data };
		case 'statusData':
			return { ...state, statusData: action.data };
		case 'statementData':
			return { ...state, statementData: action.data };
		case 'statementsData':
			return { ...state, statementsData: action.data };
		case 'userProfile':
			return { ...state, userProfile: action.data };
		case 'saleData':
			return { ...state, saleData: action.data };
		case 'transactionData':
			return { ...state, transactionData: action.data };
		case 'commissionTermsData':
			return { ...state, commissionTermsData: action.data };
		default:
			return state;
	}
};

export default reducer;
