import { API } from '@edfenergy/res-agentui-sso-auth';

const ReturnEnergySale = (
	body: {
		previousStatus: string;
		updatedTs: string;
		toStatus: string;
		returnReason: string;
		returnReasonDetail: string;
	},
	id: number,
	successFunc?: any,
	failFunc?: any
) => {
	const errorHandler = (error: string) => {
		if (failFunc) {
			failFunc(error);
		} else {
			console.warn(error);
		}
	};

	// fetch(`${path}/price-launch/${id}`, {
	// 	method: 'PUT',
	// 	body: body,
	// })
	// 	.then((res) => res.json())
	// 	.then(

	API.put('api', `/energy-sale/${id}/return`, { body })
		.then((result) => {
			if (result.result && result.result.error) {
				errorHandler('Unable to return sale in database: ' + result.result.error);
			} else if (result.result && result.result.message) {
				errorHandler('Unable to return sale in database: ' + result.result.message);
			} else if (result && result.error) {
				errorHandler('Unable to return sale in database: ' + result.error);
			} else if (result && result.message) {
				errorHandler('Unable to return sale in database: ' + result.message);
			} else {
				if (successFunc) successFunc();
			}
		})
		.catch((error) => {
			if (error.response) {
				//handle error
				errorHandler('Unable to return sale in database: ' + error.response.data.error);
			} else {
				errorHandler('Unable to return sale in database');
			}
		});
};

export default ReturnEnergySale;
