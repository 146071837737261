import React from 'react';
import { Input } from '../../../edfm';
import { useRefDataTranslate } from '../../../widgets/refDataTranslate';
import { EnergySale } from '../../../widgets/types';

function showBlankIfEmpty(aString: string) {
	return aString ? aString : ' ';
}

const SupplyTab = (props: { sale: EnergySale }) => {
	const refDataTranslate = useRefDataTranslate();
	return (
		<div className="flex row">
			<div className="flex-one">
				<h5>Supply Address</h5>
				<ul>
					<li>
						<Input
							label="Building Number"
							fullWidth
							value={showBlankIfEmpty(props.sale.supplyAddressBuildingNumber)}
							disabled
						/>
					</li>
					<li>
						<Input
							label="Building Name"
							fullWidth
							value={showBlankIfEmpty(props.sale.supplyAddressBuildingName)}
							disabled
						/>
					</li>
					<li>
						<Input label="Street" fullWidth value={showBlankIfEmpty(props.sale.supplyAddressStreet)} disabled />
					</li>
					<li>
						<Input label="Town" fullWidth value={showBlankIfEmpty(props.sale.supplyAddressTown)} disabled />
					</li>
					<li>
						<Input label="County" fullWidth value={showBlankIfEmpty(props.sale.supplyAddressCounty)} disabled />
					</li>
					<li>
						<Input label="Post Code" fullWidth value={showBlankIfEmpty(props.sale.supplyAddressPostcode)} disabled />
					</li>
				</ul>
			</div>
			<div className="flex-one">
				<h5>Supply Point Details</h5>
				<ul>
					<li>
						<Input label="MPXN" fullWidth value={showBlankIfEmpty(props.sale.mpxn)} disabled />
					</li>
					<li>
						<Input label="EAC" fullWidth value={showBlankIfEmpty(props.sale.annualConsumption)} disabled />
					</li>
					<li>
						<Input
							label="Fuel Type"
							fullWidth
							value={showBlankIfEmpty(
								props.sale.product[0] === 'G' ? 'Gas' : props.sale.product[0] === 'E' ? 'Elec' : props.sale.product[0]
							)}
							disabled
						/>
					</li>
					<li>
						<Input label="Meter Type" fullWidth value={showBlankIfEmpty(props.sale.tariff)} disabled />
					</li>
					<li>
						<Input label="Profile Class" fullWidth value={showBlankIfEmpty(props.sale.profileClass)} disabled />
					</li>
					<li>
						<Input label="MTSC" fullWidth value={showBlankIfEmpty(props.sale.meterTimeSwitchCode)} disabled />
					</li>
					<li>
						<Input label="LLF" fullWidth value={showBlankIfEmpty(props.sale.lineLossFactor)} disabled />
					</li>
					<li>
						<Input
							label="Previous Supplier"
							fullWidth
							value={showBlankIfEmpty(refDataTranslate('Existing Supplier ID', props.sale.existingSuplierId))}
							disabled
						/>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default SupplyTab;
