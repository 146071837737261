const IsEqual = (a: any, b: any) => {
	const isEquivalent = (a: any, b: any): any => {
		if ((a === null && b !== null) || (a !== null && b === null)) {
			return false;
		}

		if (a !== null && b !== null) {
			// Create arrays of property names
			var aProps = Object.getOwnPropertyNames(a);
			var bProps = Object.getOwnPropertyNames(b);

			// If number of properties is different,
			// objects are not equivalent
			if (aProps.length !== bProps.length) {
				return false;
			}

			for (var i = 0; i < aProps.length; i++) {
				var propName = aProps[i];

				// If values of same property are not equal,

				if (typeof a[propName] === 'function') {
					//functions not currently evaluated - TODO
				} else if (typeof a[propName] === 'object') {
					// objects are not equivalent
					if (!isEquivalent(a[propName], b[propName])) {
						return false;
					}
				} else if (!(Number.isNaN(a[propName]) && Number.isNaN(b[propName]))) {
					if (a[propName] !== b[propName]) {
						return false;
					}
				}
			}
		}

		// If we made it this far, objects
		// are considered equivalent
		return true;
	};

	return isEquivalent(a, b);
};

export default IsEqual;
