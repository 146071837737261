import React from 'react';

import '../styling.scss';

export interface ColumnProps {
	styles?: string;
	children: any;
}

const Card = (props: ColumnProps) => {
	const { styles, children } = props;

	return <div className={`edfmat_card ${styles}`}>{children}</div>;
};

export default Card;
