import React from 'react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import {
	authFederatedSignIn,
	configure,
	getSTSCurrentUser,
	ISTSCredentials,
	// ISTSCredentialsProps,
} from '../AuthFunctions';
import { AuthButton } from './AuthButton';
import { EDFLogoAnimated } from './EDFLogoAnimated';
import { Hub } from '@aws-amplify/core';
import { IAuthSession } from '../IAuthSession';

export interface IAuthContainerRendererProps {
	isLoading: boolean;
	authFederatedSignIn: (options?: any) => void;
	defaultRenderer: () => JSX.Element;
	errorMessage?: string;
}

export interface IAuthContainerProps {
	/**
	 * Should include Auth and API config.
	 */
	amplifyConfig: any;
	//	stsConfig?: ISTSCredentialsProps;
	onSignIn?: (SSOAuthSession: IAuthSession) => void;
	buttonText?: string;
	renderer?: (props: IAuthContainerRendererProps) => JSX.Element;
	className?: string;
	autoLogin?: boolean;
	/**
	 * Logs the Hub auth events to console in debug mode.
	 * prefix: [auth][Hub.listen]
	 */
	isVerbose?: boolean;
}

export interface IAuthContainerState {
	isLoading: boolean;
	hasAuthCode: boolean;

	session?: CognitoUserSession;
	stsCredentials?: ISTSCredentials;
	errorMessage?: string;

	isConfiguredCalled: boolean;
}

export class AuthContainer extends React.Component<IAuthContainerProps, IAuthContainerState> {
	constructor(props: IAuthContainerProps) {
		super(props);

		this.state = {
			isLoading: true,
			hasAuthCode: false,
			isConfiguredCalled: false,
		};
	}

	public configureAmplify() {
		this.setState({ isConfiguredCalled: true }, () => {
			configure(this.props.amplifyConfig, this.props.isVerbose);
		});
	}

	public componentDidMount() {
		this.listenHub(this.props.isVerbose);
		if (this.props.amplifyConfig) {
			this.configureAmplify();
		}
	}

	public componentDidUpdate(prevProps: IAuthContainerProps) {
		if (this.props.amplifyConfig && !this.state.isConfiguredCalled) {
			this.configureAmplify();
		}
	}

	private timeout: any = null;
	public getCurrentUser() {
		getSTSCurrentUser(this.props.isVerbose /* this.props.stsConfig */)
			.then(({ session /* , stsCredentials */ }) => {
				this.setState({ session, /* stsCredentials, */ isLoading: false, hasAuthCode: false }, () => {
					if (typeof this.props.onSignIn === 'function') {
						this.props.onSignIn({ session /* stsCredentials */ });
					}
				});
			})
			.catch((err) => {
				this.setState({ isLoading: false });
			});
	}

	public listenHub(isVerbose?: boolean) {
		Hub.listen('auth', ({ payload }) => {
			if (isVerbose) {
				console.debug('[auth][Hub.listen]', payload);
			}

			if (payload.event === 'codeFlow') {
				this.setState({ hasAuthCode: true });
			} else if (payload.event === 'configured' || payload.event === 'cognitoHostedUI') {
				if (this.timeout) {
					clearTimeout(this.timeout);
				}
				this.timeout = setTimeout(this.getCurrentUser.bind(this), 200);
			} else if (payload.event === 'cognitoHostedUI_failure') {
				console.error(payload);
				this.setState({ isLoading: false, errorMessage: payload.message, hasAuthCode: false });
			}
		});
	}

	public _defaultRenderer(): JSX.Element {
		if (!(this.state.isLoading || this.state.hasAuthCode) && !this.state.errorMessage && this.props.autoLogin) {
			authFederatedSignIn(this.props.amplifyConfig.Auth);
		}

		return (
			<div className={this.props.className}>
				{this.state.isLoading || this.state.hasAuthCode ? (
					<EDFLogoAnimated />
				) : this.props.autoLogin && !this.state.errorMessage ? (
					<EDFLogoAnimated />
				) : (
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
						<AuthButton />
						<div
							style={{
								color: '#e32747',
								lineHeight: '1.2em',
								fontFamily: 'monospace',
								height: '2.4em',
								maxWidth: '300px',
								marginTop: '1em',
							}}
						>
							{this.state.errorMessage}
						</div>
					</div>
				)}
			</div>
		);
	}

	public _renderer(): JSX.Element {
		if (typeof this.props.renderer === 'function') {
			return this.props.renderer({
				isLoading: this.state.isLoading,
				authFederatedSignIn,
				errorMessage: this.state.errorMessage,
				defaultRenderer: this._defaultRenderer.bind(this),
			});
		}

		return this._defaultRenderer();
	}

	public render() {
		return this.state && this.state.session ? this.props.children : this._renderer();
	}
}
