import { API } from '@edfenergy/res-agentui-sso-auth';
import { useDispatch, useSelector } from 'react-redux';
import { ApiPath } from '.';

const LoadSale = (id: number, forceLoad: boolean = false) => {
	const dispatch = useDispatch();

	const checkStore = (input: any) => {
		const saleData = input.saleData;
		return saleData;
	};

	const saleResult: any = useSelector(checkStore);

	//refactor check for Api data path into new function and cal asynetrically
	const apiLanded = (apiData: any): void => {
		if (forceLoad) {
			fetchSale();
		} else if (!saleResult || Number(saleResult.tpiId) !== Number(id)) {
			//check if currently loaded sale is the requested one
			//or if greater than time threshold for stale data before loading - TODO
			fetchSale();
		}
	};

	ApiPath(apiLanded);

	const fetchSale = () => {
		//when getting fresh data, remove old data from store to prevent flash of obselete data
		dispatch({ type: 'saleData', data: undefined });

		API.get('api', `/energy-sale/${id}`, {}).then(
			(result) => {
				dispatch({ type: 'saleData', data: result.result });
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				//handle error
				console.warn(error);
			}
		);
	};
};

export default LoadSale;
